import React, { FunctionComponent } from 'react';
import { Field, FieldProps, FieldAttributes } from 'formik';
import { InputProps, Input } from 'modules/shared/components/Form/Input/Input';

export type InputFieldProps = FieldAttributes<{
  hasDescription?: boolean;
  underlineOnly?: boolean;
  description?: JSX.Element;
}> &
  Pick<
    InputProps,
    | 'placeholder'
    | 'type'
    | 'isDisabled'
    | 'icon'
    | 'onFocus'
    | 'iconPosition'
    | 'onIconClick'
    | 'addon'
    | 'sizeType'
    | 'min'
    | 'max'
    | 'showError'
  >;

const InputField: FunctionComponent<InputFieldProps> = ({
  placeholder,
  type,
  isDisabled,
  icon,
  iconPosition,
  onIconClick,
  onFocus,
  addon,
  sizeType,
  min,
  max,
  showError,
  hasDescription,
  underlineOnly,
  description,
  ...fieldProps
}) => (
  <Field {...fieldProps}>
    {({ field, meta }: FieldProps) => (
      <>
        <Input
          {...field}
          min={min}
          max={max}
          placeholder={placeholder}
          type={type}
          isDisabled={isDisabled}
          icon={icon}
          sizeType={sizeType}
          iconPosition={iconPosition}
          onIconClick={onIconClick}
          onFocus={onFocus}
          addon={addon}
          showError={showError}
          error={meta.touched && meta.error ? meta.error : undefined}
          hasDescription={hasDescription}
          underlineOnly={underlineOnly}
          description={description}
        />
      </>
    )}
  </Field>
);

export default InputField;

import { endOfDay, isFriday, differenceInCalendarWeeks, startOfDay, subDays, isBefore, isSameDay } from 'date-fns';

const FIRST_OFF_FRIDAY = startOfDay(new Date('2022-07-15'));
const options = { weekStartsOn: 1 as const };

const OFF_FRIDAYS = [new Date('2023-04-07')];
const WORKING_FRIDAYS = [new Date('2023-04-14')];

export const isOffFriday = (date: Date) => {
  if (!isFriday(date)) return false;

  // Wolne piątki zaczynają się od 15.07.2022.
  const justBeforeFirstFridayOff = endOfDay(subDays(FIRST_OFF_FRIDAY, 1));
  if (isBefore(date, justBeforeFirstFridayOff)) return false;

  // Na podstawie różnicy w liczbie tygodni pomiędzy pierwszym wolnym piątkiem a aktualną datą oznaczmay wolne piątki.
  const differenceInWeeks = differenceInCalendarWeeks(date, FIRST_OFF_FRIDAY, options);

  // W 2023 wolne piątki przeskakują o jeden tydzień.
  if (isBefore(date, new Date('2022-12-31'))) return differenceInWeeks % 2 === 0;

  // Dla niektórych dat wymuszamy wolne/pracujace piątki (np. wielki piątek w 2023 07.04 jest zamieniony z wolnym piątkiem 14.04).
  if (OFF_FRIDAYS.find(friday => isSameDay(friday, date))) return true;
  if (WORKING_FRIDAYS.find(friday => isSameDay(friday, date))) return false;

  // Od 2023 różnica tygodni musi być nieparzysta.
  return differenceInWeeks % 2 === 1;
};

import React, { FunctionComponent, useState } from 'react';
import { auth } from 'config/firebase';
import Summary from 'modules/Users/Summary/Summary';
import { UsersContainer } from 'modules/Users/Users.components';
import UsersList from 'modules/Users/UsersList/UsersList';

const Users: FunctionComponent = () => {
  const [selectedUserId, setSelectedUserId] = useState<string>(auth.currentUser?.uid!);

  return (
    <UsersContainer>
      <UsersList selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} />
      <Summary selectedUserId={selectedUserId} />
    </UsersContainer>
  );
};

export default Users;

import colors from 'modules/shared/styles/colors';
import { styledScrollbar } from 'modules/shared/styles/mixins';
import styled from 'styled-components';

export const SingleProjectPhasesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SingleProjectPhasesHeader = styled.div`
  display: flex;
  height: 56px;
  background: ${colors.gray20};
  width: 100%;
`;

export const ProjectPhasesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 56px 54px 60px 54px;
  overflow-y: scroll;
  height: calc(100vh - 170px);
  ${styledScrollbar()}
  @media all and (max-width: 850px) {
    padding: 26px 24px 30px 24px;
    height: unset;
  }
`;

export const ProjectPhasesHeaderWrapper = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${colors.white};

  > svg {
    margin-left: 8px;
    cursor: pointer;
    transition: transform 0.5s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const stringValuesToMinutes = (values: { [key: string]: string }, selectedDate: Date) => {
  return Object.keys(values).map(phase => {
    const value = values[phase];
    const hasSeparator = value.includes(':');
    let summaryTime = 0;
    if (hasSeparator) {
      // Indicates that input format was HH:MM
      const splittedValue = value.split(':');
      const hours = Number(splittedValue[0]);
      const minutes = Number(splittedValue[1]);
      if (hours === 0) {
        summaryTime = minutes;
      }
      summaryTime = hours * 60 + minutes;
    } else {
      // Indicates that input format was HH
      summaryTime = Number(value) * 60;
    }

    return {
      day: selectedDate.getDate(),
      phaseId: phase,
      value: summaryTime,
      note: '',
      projectId: phase.split('-')[0],
    };
  });
};

export default stringValuesToMinutes;

import colors from 'modules/shared/styles/colors';
import { font14 } from 'modules/shared/styles/mixins';
import styled from 'styled-components';

export const ProjectsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 56px;
`;

export const ProjectsTableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 0.6fr;
  width: 100%;
  justify-content: space-between;
  padding: 4px 0;
  border-bottom: 1px solid ${colors.gray20};
  background: ${colors.gray20};
  border-radius: 10px;
  position: sticky;
  box-shadow: 0px -10px 0px 20px #1c1c1e;
  top: 0;
  z-index: 9999;
  :last-child {
    justify-self: flex-end;
  }
`;

export const TableHeaderItem = styled.span`
  display: flex;
  font-weight: normal;
  ${font14()}
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  justify-content: center;
  opacity: 0.5;
  :first-child {
    margin-left: 64px;
    justify-content: flex-start;
  }
  :last-child {
    margin-right: 30px;
    justify-content: flex-end;
  }
`;

import React from 'react';
import { ToastContainer as BaseToastContainer, ToastContainerProps } from 'react-toastify';
import styled from 'styled-components';

import errorIcon from 'modules/shared/components/Toast/icons/errorIcon.svg';
import successIcon from 'modules/shared/components/Toast/icons/successIcon.svg';
import colors from 'modules/shared/styles/colors';
import { font16 } from 'modules/shared/styles/mixins';

const TOASTIFY_PREFIX = 'Toastify';
const TOAST = `${TOASTIFY_PREFIX}__toast`;
const TOAST_CONTAINER = `${TOAST}-container`;
const TOAST_BODY = `${TOAST}-body`;
const TOAST_SUCCESS = `${TOAST}--success`;
const TOAST_ERROR = `${TOAST}--error`;
const CLOSE_BUTTON = `${TOASTIFY_PREFIX}__close-button`;

const ICON_WIDTH = 24;
const ICON_HEIGHT = 24;
const ICON_MARGIN_RIGHT = 24;

const ToastContainer = ({ className, ...props }: ToastContainerProps & { className?: string }) => (
  <div className={className}>
    <BaseToastContainer {...props} />
  </div>
);

export default styled(ToastContainer).attrs({
  position: 'top-right',
  hideProgressBar: false,
  autoClose: 2500,
})`
  .${TOAST_CONTAINER} {
    padding: 0px;
  }

  .${TOAST} {
    display: flex;
    right: 20px;
    width: 343px;
    background: ${colors.gray30};
    border: 1px solid ${colors.gray30};
    box-sizing: border-box;
    border-radius: 5px 5px 0px 0px;
    ${font16()}
    color: rgba(255, 255, 255, 0.95);
    height: 60px;
  }

  .${TOAST_BODY} {
    display: flex;
    align-items: center;
    opacity: 0.9;
    padding: 0;
    margin: 0;
    &::before {
      width: ${ICON_WIDTH}px;
      height: ${ICON_HEIGHT}px;
      margin-right: ${ICON_MARGIN_RIGHT}px;
      margin-left: 10px;
    }
  }

  .${TOAST_SUCCESS} {
    .${TOAST_BODY} {
      &::before {
        content: url(${successIcon});
      }
    }
  }

  .${TOAST_ERROR} {
    .${TOAST_BODY} {
      &::before {
        content: url(${errorIcon});
      }
    }
  }

  .${CLOSE_BUTTON} {
    margin-right: 6px;
    margin-top: 6px;
    opacity: 1;
  }

  .Toastify__progress-bar--error {
    background: ${colors.red};
    height: 4px;
  }
  .Toastify__progress-bar--success {
    background: ${colors.mint};
    height: 4px;
  }
`;

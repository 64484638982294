import React, { FunctionComponent, useState, useCallback, Dispatch, SetStateAction } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { addProjectModalValidationSchema } from 'modules/shared/components/Modals/AddProjectModal/form';
import { EditSingleProjectFormValues } from 'modules/SingleProject/EditSingleProject/form';
import EditSingleProjectFormContent from 'modules/SingleProject/EditSingleProject/EditSingleProjectFormContent';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { IProject } from 'firebase/types/Project';
import firebase from 'firebase/app';
import { useParams } from 'react-router-dom';
import editProject from 'firebase/projects/editProject';
import { toast } from 'react-toastify';

type Props = {
  formDisabled: boolean;
  setFormDisabled: Dispatch<SetStateAction<boolean>>;
};

const EditSingleProjectForm: FunctionComponent<Props> = ({ formDisabled, setFormDisabled }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const [value] = useDocumentData<IProject>(firebase.firestore().collection('projects').doc(projectId));
  const [message, setMessage] = useState<string>();

  const handleSubmit = useCallback(
    async (values: EditSingleProjectFormValues, { setFieldError }: FormikHelpers<EditSingleProjectFormValues>) => {
      try {
        const { name, client, icon } = values;
        await editProject(projectId, name, client, icon);
        setFormDisabled(true);
        toast.success('Jeszcze coś?');
      } catch (e) {
        console.log(e);
        toast.error('Wystąpił błąd :(');
        setMessage('There was a problem. Please try again.');
      }
    },
    [projectId, setFormDisabled]
  );

  return (
    <Formik
      initialValues={{
        name: value?.name || '',
        client: value?.client || '',
        icon: value?.icon || '',
      }}
      validationSchema={addProjectModalValidationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <EditSingleProjectFormContent
          {...formProps}
          formDisabled={formDisabled}
          setFormDisabled={setFormDisabled}
          message={message}
        />
      )}
    </Formik>
  );
};

export default EditSingleProjectForm;

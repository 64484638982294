import colors from 'modules/shared/styles/colors';
import styled, { css } from 'styled-components';

export const HolidaysCalendarContent = styled.div`
  position: relative;
  border-radius: 15px;
  min-width: fit-content;
  padding: 24px 30px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  background-color: ${colors.gray20};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 24px;

  @media screen and (max-width: 1142px) {
    justify-content: center;
  }
`;

export const HolidayCalendarOverlay = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 4;
`;

export const HolidaysCalendarMonthContainer = styled.div`
  flex-basis: 23%;
  min-width: 220px;
  min-height: 224px;
`;

export const HolidaysCalendarMonth = styled.div`
  width: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HolidayDay = styled.div<{ isToday: boolean; isSelected: boolean; isFreeDay: boolean; isInactive: boolean }>`
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 99999px;
  display: flex;
  z-index: 3;
  ${p =>
    p.isSelected &&
    !p.isInactive &&
    css`
      background-color: ${p.isFreeDay ? '#443850' : '#65477e'};
      filter: drop-shadow(0px 4px 8px rgba(50, 50, 71, 0.5)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
    `}

  ${p =>
    p.isToday &&
    !p.isInactive &&
    css`
      border: 1px solid rgba(255, 255, 255, 0.95);
    `}

  ${p =>
    (p.isSelected || p.isToday) &&
    !p.isInactive &&
    css`
      transform: scale(${p.isToday ? 1.05 : 1.1});
    `}
`;

export const HolidaysCalendarMonthDaysContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.95);
`;

type HolidayDayProps = {
  isInactive: boolean;
  isHoliday: boolean | 'start' | 'end';
  isSingleHoliday: boolean;
  isFreeDay: boolean;
  hoveredHoliday: boolean;
  interactive: boolean;
  showBottomShadow: boolean;
};

export const HolidayDayContainer = styled.div`
  max-width: 30px;
  max-height: 28px;
  padding-top: 1px;
  padding-bottom: 1px;
  box-sizing: border-box;
`;

export const HolidaysCalendarMonthDay = styled.span<HolidayDayProps>`
  width: 30px;
  height: 28px;
  box-sizing: border-box;
  padding-left: 1px;
  padding-right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${p => (p.isInactive || !p.interactive ? 'default' : 'pointer')};
  position: relative;
  margin-top: 1px;
  margin-bottom: 1px;

  ${p =>
    p.isHoliday &&
    !p.isInactive &&
    css`
      background-color: ${p.isFreeDay ? '#443850' : '#65477e'};
    `}

  ${p =>
    p.isHoliday === 'start' &&
    !p.isInactive &&
    css`
      border-radius: 9999px 0px 0px 9999px;
    `}

  ${p =>
    p.isHoliday === 'end' &&
    !p.isInactive &&
    css`
      border-radius: 0px 9999px 9999px 0px;
    `}

  ${p =>
    p.isSingleHoliday &&
    !p.isInactive &&
    css`
      border-radius: 9999px;
    `}

  ${p =>
    p.isInactive
      ? css`
          color: rgba(255, 255, 255, 0.3);
        `
      : !p.isHoliday &&
        p.interactive &&
        css`
          :hover {
            > ${HolidayDay} {
              background-color: ${colors.purple140};
            }
          }
        `}

  ${p =>
    p.showBottomShadow &&
    css`
      ::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        width: ${p.isHoliday === 'start' ? 0 : 8}px;
        z-index: 2;
        background-color: ${p.isFreeDay ? '#443850' : '#65477e'};
      }

      ::after {
        content: '';
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
        width: ${p.isHoliday === 'end' ? 0 : 8}px;
        z-index: 2;
        background-color: ${p.isFreeDay ? '#443850' : '#65477e'};
      }

      box-shadow: 0 5px 5px -0px rgba(0, 0, 0, 0.5);
    `}
`;

export const HolidayCalendarDayNumber = styled.span`
  margin: auto;
`;

export const HolidayCalendarDayName = styled.span`
  width: 30px;
  height: 30px;
  text-transform: uppercase;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 8px;
  margin-bottom: 2px;
`;

export const HolidayCalendarMonthName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: capitalize;
  margin-left: 8px;
`;

import { auth } from 'config/firebase';
import firebase from 'firebase/app';
import { ITimesheet } from 'firebase/types/Timesheets';

const setTimesheets = async (selectedDate: Date, selectedDayPhases: ITimesheet[], isRemoveAction?: boolean) => {
  const userId = auth.currentUser?.uid;
  const timesheetSnapshot = await firebase
    .firestore()
    .collection(`/users/${userId}/timesheets`)
    .doc(`${selectedDate.getFullYear()}-${selectedDate.getMonth()}`)
    .get();

  const timesheetData = timesheetSnapshot.data();

  if (!timesheetData) {
    firebase.firestore().collection(`/users/${userId}/timesheets`).doc(`${selectedDate.getFullYear()}-${selectedDate.getMonth()}`).set({
      days: selectedDayPhases,
    });
  } else if (timesheetData && isRemoveAction) {
    const existingData = timesheetData.days.filter((d: ITimesheet) => d.day !== selectedDate.getDate());

    const selectedDays = selectedDayPhases.map(p => ({
      day: selectedDate.getDate(),
      phaseId: p.phaseId,
      value: p.value,
      note: p.note,
    }));

    firebase
      .firestore()
      .collection(`/users/${userId}/timesheets`)
      .doc(`${selectedDate.getFullYear()}-${selectedDate.getMonth()}`)
      .set({
        days: [...existingData, ...selectedDays],
      });
  } else {
    const existingData = timesheetData.days.filter((d: ITimesheet) => d.day !== selectedDate.getDate());
    firebase
      .firestore()
      .collection(`/users/${userId}/timesheets`)
      .doc(`${selectedDate.getFullYear()}-${selectedDate.getMonth()}`)
      .set({
        days: [...existingData, ...selectedDayPhases],
      });
  }
};

export default setTimesheets;

export default {
  white: '#ffffff',
  grayLighter: '#39393d',
  gray10: '#1C1C1E',
  gray20: '#2C2C2E',
  gray30: '#39393C',
  gray40: '#4B4B4E',
  gray50: '#545457',
  gray60: '#646468',
  purple190: '#050308',
  purple160: '#351F47',
  purple140: '#4D286D',
  purple120: '#58327A',
  purple110: '#633A87',
  purple90: '#7D53A0',
  purple80: '#8B63AF',
  purple60: '#A081BB',
  purple40: '#CBB6DD',
  purple20: '#F3EBFA',
  purple10: '#FAF7FD',
  purple: '#9b74be',
  darkMain: '#100B14',
  mint: '#95d0ce',
  red: '#e54d69',
} as const;

import { isAfter, isBefore, format } from 'date-fns';
import { ITimesheet } from 'firebase/types/Timesheets';
import { isFreeDay } from 'helpers/isFreeDay';

const showNotification = (day: Date, skippedNotifications?: string[], timesheetsData?: ITimesheet[]) => {
  const startDate = new Date(2023, 3, 30);

  if (
    isAfter(day, startDate) &&
    //don't show notification for today if is before 17 o'clock
    isBefore(new Date(day).setHours(17), new Date()) &&
    !skippedNotifications?.includes(format(day, 'dd-MM-yyyy')) &&
    !isFreeDay(day)
  ) {
    const dayData = timesheetsData?.filter(timesheet => timesheet.day === day.getDate());
    if (!dayData || dayData.length === 0 || dayData.reduce((acc, val) => acc + val.value, 0) <= 0) return true;
  }

  return false;
};

export default showNotification;

import React, { useCallback } from 'react';
import {
  ProjectHeaderWrapper,
  ProjectsContainer,
  ProjectsHeader,
  ProjectsWrapper,
} from 'modules/Projects/Projects.components';
import ProjectsTable from 'modules/Projects/ProjectsTable/ProjectsTable';
import { ReactComponent as AddButton } from 'modules/Projects/icons/AddButton.svg';
import { useModal } from 'modules/shared/components/Modal';
import AddProjectModal from 'modules/shared/components/Modals/AddProjectModal/AddProjectModal';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';

const Projects = () => {
  const { openFixedModal } = useModal();
  const { isAdmin } = useUserContext();

  const openAddProjectModal = useCallback(() => {
    openFixedModal(<AddProjectModal />);
  }, [openFixedModal]);

  return (
    <ProjectsContainer>
      <ProjectsWrapper>
        <ProjectHeaderWrapper>
          <ProjectsHeader>Wszystkie projekty</ProjectsHeader>
          {isAdmin && <AddButton onClick={openAddProjectModal} />}
        </ProjectHeaderWrapper>
        <ProjectsTable />
      </ProjectsWrapper>
    </ProjectsContainer>
  );
};

export default Projects;

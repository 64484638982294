import { addDays, isAfter } from 'date-fns';
import { isFreeDay } from './isFreeDay';

export const getWorkingDaysBetween = (start: Date, end: Date) => {
  let count = 0;
  let date = start;
  while (!isAfter(date, end)) {
    if (!isFreeDay(date)) {
      count += 1;
    }
    date = addDays(date, 1);
  }
  return count;
};

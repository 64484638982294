import colors from 'modules/shared/styles/colors';
import styled from 'styled-components';

export const CheckboxLabel = styled.span`
  position: absolute;
  max-width: 85%;
  > span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.002em;
    color: rgba(255, 255, 255, 0.8);
    margin-left: 29px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FakeInput = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 18px;
  height: 18px;
  background: ${colors.gray10};
  border: 2px solid ${colors.gray30};
  box-sizing: border-box;
  border-radius: 5px;

  > svg {
    display: none;
  }
`;

export const CheckboxContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
  margin: 0 16px;
  padding-left: 15px;
  &:hover {
    background: ${colors.gray20};
    border-radius: 10px;
  }
`;

export const InvisibleCheckbox = styled.input`
  z-index: 100;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  &[disabled] {
    cursor: auto;
  }
  &:hover + {
    ${FakeInput} {
      border: 2px solid ${colors.purple};
    }
  }

  &:checked + {
    ${FakeInput} {
      background: ${colors.purple};
      border: none;
      > svg {
        display: flex;
      }
    }
  }
`;

import React, { FunctionComponent } from 'react';
import { BaseModalWrapperProps } from 'modules/shared/components/Modal/BaseModalWrapper';
import { BaseModalContainer, BaseModalHeader } from 'modules/shared/components/Modal/DefaultModalWrapper';
import { AddProjectModalWrapper } from 'modules/shared/components/Modals/AddProjectModal/AddProjectModal.components';
import AddProjectModalForm from 'modules/shared/components/Modals/AddProjectModal/AddProjectModalForm';

type Props = BaseModalWrapperProps;

const AddProjectModal: FunctionComponent<Props> = ({ status }) => {
  return (
    <BaseModalContainer status={status}>
      <BaseModalHeader>Dodaj projekt</BaseModalHeader>
      <AddProjectModalWrapper>
        <AddProjectModalForm />
      </AddProjectModalWrapper>
    </BaseModalContainer>
  );
};

export default AddProjectModal;

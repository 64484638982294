import colors from 'modules/shared/styles/colors';
import { font12, font14 } from 'modules/shared/styles/mixins';
import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

type TransitionProps = {
  status: TransitionStatus;
};

export const ProjectBarContainer = styled.div<TransitionProps>`
  display: flex;
  align-items: center;
  margin-left: -10px;
  > svg {
    margin-right: 10px;
    margin-top: -5px;
    cursor: pointer;
  }

  transition: transform 300ms ease, opacity 200ms ease;
  opacity: ${(props) => (props.status === 'entered' ? '1' : '0')};
  transform: scale(${(props) => (props.status === 'entered' ? '1' : '0')});
`;

export const ProjectBarWrapper = styled.div`
  display: flex;
  width: 380px;
  height: 56px;
  background: ${colors.gray20};
  border-radius: 15px;
  align-items: center;
  margin-bottom: 8px;

  @media all and (max-width: 800px) {
    width: 280px;
  }

  span {
    margin-left: 8px;
    font-weight: 500;
    ${font14()}
    color: rgba(255, 255, 255, 0.8);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    width: 90%;
  }
`;

export const ProjectBarTitleContainer = styled.div`
  width: 60%;
`;

export const ProjectBarImage = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 12px;
  border-radius: 10px;
`;

type Props = {
  validationError: boolean;
};

export const TimeInputWrapper = styled.div<Props>`
  position: relative;
  display: flex;
  width: 80px;
  height: 40px;
  background: ${colors.gray10};
  border: 1px solid ${(p) => (p.validationError ? colors.red : colors.gray40)};
  border-radius: 15px;
  margin-left: auto;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  color: ${colors.white};

  > :first-child {
    text-align: right;
  }

  > span {
    margin-bottom: 5px;
  }
  > :last-child {
    text-align: left;
  }
`;

type TimeInputProps = {
  hightlight?: boolean;
};

export const TimeInput = styled.input<TimeInputProps>`
  display: flex;
  width: 80%;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.gray60};

  //autocomplete
  -webkit-text-fill-color: ${(p) => (p.hightlight ? 'rgba(255, 255, 255, 0.8)' : colors.gray60)};
  -webkit-box-shadow: 0 0 0px 1000px ${colors.gray10} inset;

  //Safari fix
  &[draggable='true'] {
    user-select: text;
  }

  ${(p) =>
    p.hightlight &&
    css`
      color: rgba(255, 255, 255, 0.8);
    `}

  & :active,
  :focus {
    outline: none;
    border: none;
    color: ${colors.white};
  }
`;

export const WarningBox = styled.span<TransitionProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 100px;
  background: linear-gradient(90.08deg, #f46457 0.03%, #e64d69 100%);
  border-radius: 15px 15px 15px 0px;
  padding: 8px 12px;
  transition: transform 300ms ease, opacity 200ms ease;
  opacity: ${(props) => (props.status === 'entered' ? '1' : '0')};
  transform: scale(${(props) => (props.status === 'entered' ? '1' : '0')});
  white-space: nowrap;
  font-weight: 500;
  ${font12()}
  color: rgba(255, 255, 255, 0.95);
`;

import colors from 'modules/shared/styles/colors';
import { font12 } from 'modules/shared/styles/mixins';
import styled from 'styled-components';

export const CalendarNavbarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  border-bottom: 2px solid ${colors.gray10};
  align-items: center;
`;

export const CalendarNavbarItem = styled.button<{ active: boolean }>`
  margin-left: 42px;
  padding: 0;
  border: none;
  background: none;
  font-weight: ${p => (p.active ? 600 : 400)};
  ${font12()}
  color: ${colors.white};
  cursor: pointer;
`;

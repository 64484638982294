import React, { FunctionComponent, useRef } from 'react';
import {
  AddHolidayButton,
  AddHolidayCloseContainer,
  AddHolidayCloseContent,
  AddHolidayDateContainer,
  AddHolidayDuration,
  AddHolidayPopupContainer,
  AddHolidayRange,
  AddHolidayTitle,
  AddHolidayUser,
} from './AddHolidayPopup.components';
import { useOutsideClick } from './useOutsideClick';
import { ReactComponent as IconClosePopup } from './icon_close_popup.svg';
import { format, isSameMonth } from 'date-fns';
import { pl } from 'date-fns/locale';

type AddHolidayPopupProps = {
  left: number;
  top: number;
  isDelete: boolean;
  onClose: () => void;
  userName: string;
  start: Date;
  end: Date;
  count: number;
  loading: boolean;
  handleSubmit: (start: Date, end: Date, isDelete: boolean) => Promise<void>;
};

const AddHolidayPopup: FunctionComponent<AddHolidayPopupProps> = ({
  start,
  end,
  count,
  isDelete,
  left,
  top,
  userName,
  loading,
  onClose,
  handleSubmit,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useOutsideClick(popupRef, () => {
    onClose();
  });

  const getHolidayRangeText = () => {
    if (!start || !end) return '';
    if (isSameMonth(start, end)) {
      return `${format(start, 'dd')}-${format(end, 'dd MMMM', { locale: pl })}`;
    }
    return `${format(start, 'dd MMMM', { locale: pl })} - ${format(end, 'dd MMMM', { locale: pl })}`;
  };

  return (
    <AddHolidayPopupContainer ref={popupRef} top={top} left={left}>
      <AddHolidayCloseContainer onClick={() => onClose()}>
        <AddHolidayCloseContent>
          <IconClosePopup />
        </AddHolidayCloseContent>
      </AddHolidayCloseContainer>
      <AddHolidayTitle>Urlop</AddHolidayTitle>
      <AddHolidayUser>{userName}</AddHolidayUser>
      <AddHolidayDateContainer>
        <AddHolidayRange>{getHolidayRangeText()}</AddHolidayRange>
        <AddHolidayDuration>{count} dni</AddHolidayDuration>
      </AddHolidayDateContainer>
      <AddHolidayButton isLoading={loading} onClick={() => !loading && handleSubmit(start, end, isDelete)} isDelete={isDelete}>
        {isDelete ? 'Usuń' : 'Dodaj'}
      </AddHolidayButton>
    </AddHolidayPopupContainer>
  );
};

export default AddHolidayPopup;

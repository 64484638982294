import React, { useState, FunctionComponent, useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/app';
import { IProject } from 'firebase/types/Project';
import ProjectContext from 'modules/App/AuthedLayout/ProjectContext/ProjectContext';
import { IProjectDetailsData, IProjectPhase } from 'firebase/types/ProjectPhase';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import { IUser } from 'firebase/types/User';

export const ProjectProvider: FunctionComponent = ({ children }) => {
  const [projects, setProjects] = useState<IProject[]>();
  const [allPhases, setAllPhases] = useState<IProjectPhase[]>();
  const { users } = useUserContext();
  const [values, loading] = useCollection<IProject>(firebase.firestore().collection('projects'));
  const [allPhasesValues, allPhasesLoading] = useCollection<IProjectPhase>(firebase.firestore().collection('stages'));

  useEffect(() => {
    if (!loading && values) setProjects(values.docs.map((v) => ({ projectId: v.id, ...v.data() })));
    if (!allPhasesLoading && allPhasesValues) {
      const phases = allPhasesValues.docs.map((v) => ({ phaseId: v.id, ...v.data() }));
      for (let phase of phases) {
        let members: IUser[] = [];
        let data: IProjectDetailsData = {};
        let usedMinutes = 0;

        if (phase.hours) {
          for (let entry of Object.keys(phase.hours)) {
            if (phase.hours[entry] > 0) {
              const [userId, year, month] = entry.split('-');
              const user = users?.find((u) => u.uid === userId);
              if (user) {
                if (!members.includes(user)) {
                  members.push(user);
                }
              }

              if (!data[year]) data[year] = {};
              if (!data[year][month]) data[year][month] = [];

              data[year][month].push({
                userId,
                hours: phase.hours[entry],
              });
              usedMinutes += phase.hours[entry];
            }
          }
          phase.details = {
            phaseMembers: members,
            data,
            usedMinutes,
          };
        }
      }
      setAllPhases(phases);
    }
  }, [loading, values, allPhasesLoading, users, allPhasesValues]);

  return <ProjectContext.Provider value={{ projects, allPhases }}>{children}</ProjectContext.Provider>;
};

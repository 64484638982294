import firebase from 'firebase/app';
import archiveProjectPhase from './archiveProjectPhase';

const archiveProject = async (projectId: string, archived: boolean) => {
  await firebase.firestore().collection('projects').doc(projectId).update({ isArchived: archived });
  //when project is archived, archive all phases
  if (archived) {
    const projectPhases = await firebase.firestore().collection('stages').where('projectId', '==', projectId).get();
    Promise.all(
      projectPhases.docs.map(async (phase) => {
        await archiveProjectPhase(phase.id, true);
      })
    );
  }
};

export default archiveProject;

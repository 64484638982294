import { auth } from 'config/firebase';
import firebase from 'firebase/app';
import { HolidayData } from 'firebase/types/HolidayData';
import { HolidayDays } from 'firebase/types/HolidayDays';
import { Holidays } from 'firebase/types/Holidays';

const getHolidaysDocRef = (userId: string, year: number) =>
  firebase.firestore().collection('users').doc(userId).collection('holidays').doc(year.toString());

export const getUserHolidays = async (year: number, userId?: string): Promise<Holidays | undefined> => {
  const userUid = userId ?? auth.currentUser!.uid;

  const holidaysSnap = await getHolidaysDocRef(userUid, year).get();
  const daysSnap = await getHolidaysDocRef(userUid, year).collection('days').get();

  if (!holidaysSnap.exists || daysSnap.empty) {
    return undefined;
  } else {
    const holidaysData = holidaysSnap.data()! as HolidayData;
    const daysData = daysSnap.docs.map(doc => doc.data() as HolidayDays);
    const total = holidaysData.total;
    const taken = holidaysData.taken;
    const days = daysData;
    return { total, taken, days };
  }
};

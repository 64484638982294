import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useState } from 'react';
import { FormikProps, Form } from 'formik';
import { HourListFormValues } from 'modules/Home/HoursSection/HoursList/form';
import { ActionButton, ButtonsWrapper, ProjectsWrapper } from 'modules/Home/HoursSection/HoursList/HoursList.components';
import { ReactComponent as SaveIcon } from 'modules/Home/HoursSection/HoursList/icons/saveIcon.svg';
import { ReactComponent as EditIcon } from 'modules/Home/HoursSection/HoursList/icons/editIcon.svg';
import SelectStagesModal from 'modules/shared/components/Modals/SelectPhasesModal/SelectPhasesModal';
import { useModal } from 'modules/shared/components/Modal';
import useProjectContext from 'modules/App/AuthedLayout/ProjectContext/useProjectContext';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import useTimesheetContext from 'modules/App/AuthedLayout/TimesheetContext/useTimesheetContext';
import { minutesToHourString } from 'common/helpers/minutesToHourString';
import stringValuesToMinutes from 'common/helpers/stringValuesToMinutes';
import useEventHandler from 'common/eventHandlers/useEventHandler';
import { appEventTypes } from 'common/events/AppEventTarget';
import ProjectBarsDragAndDrop from './ProjectBarDragAndDrop/ProjectBarDragAndDrop';
import { ITimesheet } from 'firebase/types/Timesheets';

type Props = FormikProps<HourListFormValues> & {
  localHours: string;
  setLocalHours: Dispatch<SetStateAction<string>>;
  changeValuesOrder: (newOrder: ITimesheet[], values: HourListFormValues) => any;
};

const HourListFormContent: FunctionComponent<Props> = ({
  isSubmitting,
  isValid,
  submitCount,
  submitForm,
  handleChange,
  setFieldValue,
  values,
  localHours, // Hours that are not saved to database yet
  setLocalHours,
  changeValuesOrder,
  ...props
}) => {
  const [initialHours, setInitialHours] = useState('');
  const { openFixedModal } = useModal();
  const { projects } = useProjectContext();
  const { selectedDate } = useUserContext();
  const { currentTimesheetData } = useTimesheetContext();

  const openSelectStagesModal = useCallback(() => {
    openFixedModal(<SelectStagesModal />, true);
  }, [openFixedModal]);

  useEffect(() => {
    const minuteValues = stringValuesToMinutes(values, selectedDate);
    setLocalHours(
      minutesToHourString(
        minuteValues.reduce((acc, val) => acc + val!.value, 0),
        true,
      ),
    );
    setInitialHours(
      minutesToHourString(
        currentTimesheetData?.reduce((acc, val) => acc + val!.value, 0),
        true,
      ),
    );
  }, [values, selectedDate, setLocalHours, currentTimesheetData]);

  useEventHandler(appEventTypes.CALENDAR_TAB_CHANGE, () => {
    if (localHours !== initialHours) submitForm();
  });

  const getProjectDetails = (phaseId: string) => {
    const projectId = phaseId.split('-')[0];
    return projects?.find(project => project.projectId === projectId);
  };

  return (
    <>
      <ButtonsWrapper>
        <ActionButton
          onClick={e => {
            e.preventDefault();
            if (localHours !== initialHours) submitForm();
            openSelectStagesModal();
          }}
        >
          <EditIcon />
          Edytuj listę
        </ActionButton>
        <ActionButton highlight={localHours !== initialHours} onClick={submitForm}>
          <SaveIcon />
          Zapisz
        </ActionButton>
      </ButtonsWrapper>
      <Form>
        <ProjectsWrapper
          onKeyDown={e => {
            if (e.key === 'Enter' && e.shiftKey === false) {
              e.preventDefault();
              submitForm();
            }
          }}
        >
          {currentTimesheetData && (
            <ProjectBarsDragAndDrop
              allTimesheets={currentTimesheetData}
              getProjectDetails={getProjectDetails}
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              save={(projectBars: ITimesheet[]) => {
                changeValuesOrder(projectBars, values);
              }}
            />
          )}
        </ProjectsWrapper>
      </Form>
    </>
  );
};
export default HourListFormContent;

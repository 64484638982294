import React, { useCallback } from 'react';
import {
  AddProjectsButton,
  NoProjectsContainer,
  NoProjectsDescription,
  TapIconWrapper,
} from 'modules/Home/HoursSection/HoursList/NoProjects/NoProjects.components';
import { ReactComponent as TapIcon } from 'modules/Home/HoursSection/HoursList/NoProjects/tapIcon.svg';
import { useModal } from 'modules/shared/components/Modal';
import SelectStagesModal from 'modules/shared/components/Modals/SelectPhasesModal/SelectPhasesModal';
import { Transition } from 'react-transition-group';

const NoProjects = () => {
  const { openFixedModal } = useModal();

  const openSelectStagesModal = useCallback(() => {
    openFixedModal(<SelectStagesModal />, true);
  }, [openFixedModal]);

  return (
    <Transition timeout={100} mountOnEnter={true} unmountOnExit={true} appear={true} in={true}>
      {(status) => (
        <NoProjectsContainer status={status}>
          <NoProjectsDescription>Żeby dodać godziny, musisz mieć najpierw jakieś projekty</NoProjectsDescription>
          <TapIconWrapper>
            <TapIcon />
          </TapIconWrapper>
          <AddProjectsButton onClick={openSelectStagesModal}>Dodaj projekty</AddProjectsButton>
        </NoProjectsContainer>
      )}
    </Transition>
  );
};

export default NoProjects;

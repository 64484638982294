import { EventTarget } from 'event-target-shim';

class AppEventTarget extends EventTarget {}

const appEventTarget = new AppEventTarget();

export default appEventTarget;

export const appEventTypes = {
  CALENDAR_TAB_CHANGE: 'CALENDAR_TAB_CHANGE',
};

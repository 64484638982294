// 10

import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useUserHolidays } from 'firebase/holidays/useUserHolidays';
import {
  HolidayDay,
  HolidaysCalendarContent,
  HolidaysCalendarMonth,
  HolidaysCalendarMonthContainer,
  HolidaysCalendarMonthDay,
  HolidaysCalendarMonthDaysContainer,
  HolidayCalendarDayNumber,
  HolidayCalendarDayName,
  HolidayCalendarMonthName,
  HolidayCalendarOverlay,
  HolidayDayContainer,
} from './HolidaysCalendar.components';
import {
  endOfMonth,
  format,
  getDayOfYear,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  setDayOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subWeeks,
  addDays,
  addMonths,
  isSameWeek,
} from 'date-fns';
import { pl } from 'date-fns/locale';
import AddHolidayPopup from '../AddHolidayPopup';
import { getWorkingDaysBetween } from 'helpers/getWorkingDaysBetween';
import { isFreeDay } from 'helpers/isFreeDay';
import { addHolidays } from 'firebase/holidays/addHolidays';
import { removeOverlappedHolidays } from 'firebase/holidays/removeOverlappedHolidays';
import { toast } from 'react-toastify';
import firebase from 'firebase/app';
import useOnClickOutside from 'common/helpers/useOnClickOutside';

function checkVisible(elm: HTMLElement | null) {
  if (!elm) return false;
  var rect = elm.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

export type HolidaysCalendarProps = {
  selectedUserId: string;
  selectedDate: Date;
  userName: string;
  isAdmin: boolean;
  defaultTotal?: number;
};

const HolidaysCalendar: FunctionComponent<HolidaysCalendarProps> = ({ defaultTotal, selectedUserId, selectedDate, isAdmin, userName }) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [hoveredHoliday, setHoveredHoliday] = useState('');
  const [selectedStart, setSelectedStart] = useState<Date>();
  const [hoveredDate, setHoveredDate] = useState<Date>();
  const [selectedEnd, setSelectedEnd] = useState<Date>();
  const [showPopup, setShowPopup] = useState<boolean | 'add' | 'delete'>(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [popupStartDate, setPopupStartDate] = useState<Date>();
  const [popupEndDate, setPopupEndDate] = useState<Date>();
  const [popupLoading, setPopupLoading] = useState(false);
  const { holidays, loading } = useUserHolidays(selectedDate.getFullYear(), selectedUserId);
  const popupContainerRef = useRef<HTMLDivElement>(null);

  // Clear all selections (eg. on year or user change).
  const clearSelections = () => {
    setSelectedStart(undefined);
    setSelectedEnd(undefined);
    setHoveredDate(undefined);
    setPopupStartDate(undefined);
    setPopupEndDate(undefined);
    setShowPopup(false);
  };

  const getDayId = (date: Date) => format(date, 'dd-MM-yyyy');

  // Get popup position to avoid displaying it out of the viewport.
  const getPopupPosition = (start: Date, end: Date) => {
    const MARGIN_Y = 6;
    const sameWeek = isSameWeek(start, end, { weekStartsOn: 1 });
    let element = document.getElementById(getDayId(sameWeek ? start : end))!;
    let y = element.offsetTop + element.clientHeight + MARGIN_Y;

    const popupContainer = popupContainerRef.current;
    const containerWidth = popupContainer?.clientWidth ?? 0;
    const containerHeight = popupContainer?.clientHeight ?? 0;
    const popupWidth = (popupContainer?.firstChild as HTMLElement)?.clientWidth ?? 0;
    const popupHeight = 164;

    if (y + popupHeight > containerHeight) {
      element = document.getElementById(getDayId(start))!;
      y = element.offsetTop - popupHeight - MARGIN_Y;
    }

    const left = element.offsetLeft;
    const x = Math.min(left, containerWidth - popupWidth);
    return { x, y };
  };

  // Get holiday day or undefined if given date is not in holidays range.
  const getHolidayDay = (date: Date) => {
    const day = getDayOfYear(date);
    return holidays?.days.find(holiday => day >= holiday.start && day <= holiday.end);
  };

  const isHolidayDay = (date: Date): boolean | 'start' | 'end' => {
    const day = getDayOfYear(date);
    const holidayDay = getHolidayDay(date);
    if (selectedStart && hoveredDate) {
      //To handle selection overlapping current holidays.
      if (isAfter(date, selectedStart) && isBefore(date, hoveredDate)) return true;
    }
    if (holidayDay?.start === day) return 'start';
    if (holidayDay?.end === day) return 'end';
    return holidayDay !== undefined;
  };

  const getHolidayClassName = (date: Date) => {
    const holidayDay = getHolidayDay(date);
    return holidayDay ? `holiday-${holidayDay.start.toString()}` : '';
  };

  const isSelectedHolidayDay = (date: Date) => {
    if (selectedStart && hoveredDate) {
      if (isSameDay(date, selectedStart)) return 'start';
      else if (isSameDay(date, hoveredDate) && isAfter(date, selectedStart)) return 'end';
      return isAfter(date, selectedStart) && isBefore(date, hoveredDate);
    }
    return false;
  };

  const onDayClick = (day: Date) => {
    if (!isAdmin) return;
    else if (isHolidayDay(day)) {
      setSelectedStart(undefined);
      const holidayDay = getHolidayDay(day)!;
      const holidayStart = setDayOfYear(selectedDate, holidayDay!.start);
      const holidayEnd = setDayOfYear(selectedDate, holidayDay!.end);
      setPopupPosition(getPopupPosition(holidayStart, holidayEnd));
      setPopupStartDate(holidayStart);
      setPopupEndDate(holidayEnd);
      setShowPopup('delete');
    } else if (!selectedStart) {
      setSelectedStart(day);
    } else if (!selectedEnd && isBefore(day, selectedStart)) {
      setSelectedStart(day);
    } else {
      setSelectedEnd(day);
    }
  };

  const getHolidayCount = () => {
    if (!popupStartDate || !popupEndDate) return 0;
    return getWorkingDaysBetween(popupStartDate, popupEndDate);
  };

  useEffect(() => {
    if (loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    clearSelections();
  }, [selectedDate, selectedUserId]);

  // Scroll to popup on open if it's out of the screen.
  useEffect(() => {
    if (showPopup && popupStartDate) {
      const element = document.getElementById(getDayId(popupStartDate));
      if (!checkVisible(element)) element?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup]);

  // Show popup as add if user selected start and end date.
  useEffect(() => {
    if (!selectedStart || !selectedEnd) return;
    setShowPopup('add');
    setPopupStartDate(selectedStart);
    setPopupEndDate(selectedEnd);
    setPopupPosition(getPopupPosition(selectedStart, selectedEnd));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStart, selectedEnd]);

  const handleSubmit = useCallback(
    async (start: Date, end: Date, isDelete: boolean) => {
      setPopupLoading(true);
      const year = getYear(selectedDate);
      try {
        const batch = firebase.firestore().batch();
        await removeOverlappedHolidays(selectedUserId, year, start, end, batch);
        if (!isDelete) {
          await addHolidays(selectedUserId, year, start, end, batch, defaultTotal);
        }

        await batch.commit();

        setShowPopup(false);
        clearSelections();
      } catch (error) {
        toast.error('Coś poszło nie tak 😥 Sprawdź konsolę i podeślij developerowi 🤓');
        console.error(error);
      } finally {
        setPopupLoading(false);
      }
    },
    [selectedDate, selectedUserId, defaultTotal],
  );

  // Check if given day is the only one day of selection/holiday.
  const isSingleHoliday = (date: Date) => {
    if (selectedStart && isSameDay(selectedStart, date)) {
      if (selectedEnd && isSameDay(selectedStart, selectedEnd)) return true;
      if (hoveredDate && isSameDay(selectedStart, hoveredDate)) return true;
      if (hoveredDate && isBefore(hoveredDate, date)) return true;
    }
    const holiday = getHolidayDay(date);
    if (holiday !== undefined) {
      return holiday.start === holiday.end;
    }
    return false;
  };

  const isDaySelected = (date: Date) => {
    if (showPopup === 'add' && popupStartDate && popupEndDate) {
      return isSameDay(date, popupStartDate) || isSameDay(date, popupEndDate);
    }
    if (selectedStart && isSameDay(date, selectedStart)) {
      return true;
    }
    if (hoveredDate !== undefined) {
      return isSameDay(date, hoveredDate);
    }
    return false;
  };

  useOnClickOutside(popupContainerRef, clearSelections);

  return initialLoading ? (
    <></>
  ) : (
    <HolidaysCalendarContent ref={popupContainerRef} onClick={event => event.target === event.currentTarget && clearSelections()}>
      {showPopup !== false && (
        <HolidayCalendarOverlay>
          <AddHolidayPopup
            loading={popupLoading}
            handleSubmit={handleSubmit}
            isDelete={showPopup === 'delete'}
            left={popupPosition.x}
            top={popupPosition.y}
            onClose={clearSelections}
            userName={userName}
            count={getHolidayCount()}
            start={popupStartDate!}
            end={popupEndDate!}
          />
        </HolidayCalendarOverlay>
      )}

      {Array.from(Array(12).keys()).map(index => {
        const firstDayOfYear = startOfYear(selectedDate);
        const month = addMonths(firstDayOfYear, index);
        const monthName = format(addMonths(firstDayOfYear, index), 'LLLL', { locale: pl });
        const firstDayOfMonth = startOfMonth(month);
        const firstDayOfWeek = startOfWeek(month, { weekStartsOn: 1 });
        const calendarStart = isSameDay(firstDayOfMonth, firstDayOfWeek) ? subWeeks(firstDayOfWeek, 1) : firstDayOfWeek;
        return (
          <HolidaysCalendarMonthContainer key={monthName} onClick={event => event.target === event.currentTarget && clearSelections()}>
            <HolidaysCalendarMonth>
              <HolidayCalendarMonthName>{monthName}</HolidayCalendarMonthName>
              <HolidaysCalendarMonthDaysContainer>
                {/* Map weekdays names: */}
                {Array.from(Array(7).keys()).map(index => {
                  const dayName = format(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), index), 'EEEE', { locale: pl });
                  return (
                    <HolidayCalendarDayName onClick={() => clearSelections()} key={dayName}>
                      {dayName[0]}
                    </HolidayCalendarDayName>
                  );
                })}

                {/* Always show full 6 weeks: */}
                {Array.from(Array(42).keys()).map(index => {
                  // Get displayed date.
                  const day = addDays(calendarStart, index);

                  // Day is inactive if it's not in current month (gray days before first and after last day).
                  const isInactive = isBefore(day, firstDayOfMonth) || isAfter(day, endOfMonth(firstDayOfMonth));

                  // Get class name to hover whole holiday range.
                  const className = getHolidayClassName(day);

                  const showBottomShadow = () => {
                    if (isInactive || isSingleHoliday(day)) return false;
                    if (showPopup === 'delete' && className === hoveredHoliday) {
                      return isHolidayDay(day) === true || isSelectedHolidayDay(day) === true;
                    } else if (showPopup) {
                      return (
                        (isAfter(day, popupStartDate!) && isBefore(day, popupEndDate!)) ||
                        isSameDay(day, popupStartDate!) ||
                        isSameDay(day, popupEndDate!)
                      );
                    }
                    return className !== ''
                      ? className === hoveredHoliday && !isInactive
                      : selectedStart && hoveredDate && !showPopup
                      ? isAfter(day, selectedStart) && isBefore(day, hoveredDate) && !isInactive
                      : false;
                  };

                  return (
                    <HolidayDayContainer key={getDayId(day)}>
                      <HolidaysCalendarMonthDay
                        id={isInactive ? '' : getDayId(day)} // Get day id to allow popup display in correct position.
                        // Show hover actions only if user is admin and there is no selected start/end (to avoid actions on opened popup).
                        interactive={isAdmin && !(selectedStart !== undefined && selectedEnd !== undefined)}
                        //
                        onClick={() => {
                          // Handle clicks only on active days.
                          if (!isInactive) onDayClick(day);
                          else clearSelections();
                        }}
                        // Handle mouse through event to allow dynamic selection of multiple elements (eg. whole holiday range).
                        onMouseEnter={() => {
                          // Set hovered date only if there is no selected start and end date and day is active.
                          if (!isInactive && selectedStart !== undefined && selectedEnd === undefined) {
                            setHoveredDate(day);
                          }

                          // Set hovered holiday (on base of className).
                          if (!isInactive && className) setHoveredHoliday(className);
                        }}
                        // Handle blur event manually.
                        onMouseLeave={() => setHoveredHoliday('')}
                        // Hovered holiday is when one of the holiday days is currently hovered and day is active.
                        hoveredHoliday={className !== '' && hoveredHoliday === className && !isInactive}
                        isFreeDay={isFreeDay(day)}
                        isInactive={isInactive}
                        isHoliday={isHolidayDay(day) || isSelectedHolidayDay(day)}
                        isSingleHoliday={isSingleHoliday(day)}
                        showBottomShadow={showBottomShadow()}
                      >
                        <HolidayDay
                          isInactive={isInactive}
                          isFreeDay={isFreeDay(day)}
                          isToday={!isInactive && isSameDay(new Date(), day)}
                          isSelected={isDaySelected(day)}
                        >
                          <HolidayCalendarDayNumber>{day.getDate()}</HolidayCalendarDayNumber>
                        </HolidayDay>
                      </HolidaysCalendarMonthDay>
                    </HolidayDayContainer>
                  );
                })}
              </HolidaysCalendarMonthDaysContainer>
            </HolidaysCalendarMonth>
          </HolidaysCalendarMonthContainer>
        );
      })}
    </HolidaysCalendarContent>
  );
};

export default HolidaysCalendar;

export default function normalizeTimeInput(e: React.ChangeEvent<HTMLInputElement>) {
  let cursorPosition = e.target.selectionStart;
  let cursorEndPosition = e.target.selectionEnd;
  const tooManyDigits = /^([0-9]{3,})$/.test(e.target.value);

  if (tooManyDigits) {
    const input = e.target.value;

    e.target.value = `${input.substring(0, cursorPosition!)}:${input.substring(
      cursorPosition! + 2,
      cursorEndPosition!
    )}`;
    return;
  }

  const isAllowed = /^([0-9]{0,2})(:([0-9]{0,2})){0,1}$/.test(e.target.value);
  if (!isAllowed) {
    e.target.value = e.target.value
      .split('')
      .filter((char, index) => index !== cursorPosition! - 1)
      .join('');
    e.target.selectionStart! = --cursorPosition!;
    e.target.selectionEnd! = --cursorEndPosition!;
  }
}

import React, { useState, useEffect, useMemo } from 'react';
import { HourIndicator, HourListHeader, HoursListContainer } from 'modules/Home/HoursSection/HoursList/HoursList.components';
import HourListForm from 'modules/Home/HoursSection/HoursList/HourListForm';
import WarningBadge from 'modules/shared/components/WarningBadge/WarningBadge';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import NoProjects from 'modules/Home/HoursSection/HoursList/NoProjects/NoProjects';
import { format, getDayOfYear } from 'date-fns';
import { isSameDay } from 'modules/Home/CalendarSection/Calendar/Calendar.helpers';
import useTimesheetContext from 'modules/App/AuthedLayout/TimesheetContext/useTimesheetContext';
import showNotification from 'common/helpers/showNotification';

export const HoursList = () => {
  const { selectedDate, skippedNotifications, holidaysData } = useUserContext();
  const { timesheetsData, currentTimesheetData } = useTimesheetContext();
  const [localHours, setLocalHours] = useState('');

  const isHolidayDay = useMemo(() => {
    const currentDay = getDayOfYear(selectedDate);
    return holidaysData?.days.find(day => currentDay >= day.start && currentDay <= day.end);
  }, [holidaysData, selectedDate]);

  useEffect(() => {
    if (!currentTimesheetData || !currentTimesheetData.length) setLocalHours('0');
  }, [currentTimesheetData, selectedDate]);

  return (
    <HoursListContainer>
      <WarningBadge
        showBadge={isHolidayDay ? false : showNotification(selectedDate, skippedNotifications, timesheetsData || [])}
        content={'Ej, dni mijają a Ty tu dalej nic nie wpisałeś. Zrób to od razu, bo znowu zapomnisz'}
      />
      <HourListHeader>
        {isSameDay(selectedDate, new Date()) ? 'Tyle dziś pracowałeś' : `Tyle pracowałeś ${format(selectedDate, 'dd.MM.yyyy')}`}:
      </HourListHeader>
      <HourIndicator>{localHours || 0}/8</HourIndicator>

      {currentTimesheetData && currentTimesheetData.length ? (
        <HourListForm localHours={localHours} setLocalHours={setLocalHours} />
      ) : (
        <NoProjects />
      )}
    </HoursListContainer>
  );
};

export default HoursList;

import styled, { css } from 'styled-components';
import BaseModalWrapper from 'modules/shared/components/Modal/BaseModalWrapper';
import colors from 'modules/shared/styles/colors';

const DefaultModalWrapper = styled(BaseModalWrapper)`
  display: flex;
  width: 100%;
  max-width: 552px;
  margin: auto;
`;

type BaseModalContainerProps = {
  wider?: boolean;
};

export const BaseModalContainer = styled(BaseModalWrapper)<BaseModalContainerProps>`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 400px;
  height: 100%;
  background: ${colors.gray20};
  color: ${colors.white};

  ${(p) =>
    p.wider &&
    css`
      max-width: 440px;
      width: 440px;
    `}
`;

export const BaseModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 57px;
  border-bottom: 2px solid ${colors.gray10};
`;

export default DefaultModalWrapper;

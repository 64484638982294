import { font12 } from 'modules/shared/styles/mixins';
import styled from 'styled-components';

export const ERROR_MARGIN = 10;
export const ERROR_HEIGHT = 15;

const ErrorMessage = styled.div`
  margin-top: ${ERROR_MARGIN}px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  ${font12()}
  color: #fb766a;

  > svg {
    margin-right: 8px;
  }
`;

export default ErrorMessage;

import { IProject } from 'firebase/types/Project';
import { IProjectPhase } from 'firebase/types/ProjectPhase';
import React, { createContext } from 'react';

export interface ProjectContextType {
  projects: IProject[] | null | undefined;
  allPhases: IProjectPhase[] | null | undefined;
}

const ProjectContext: React.Context<ProjectContextType> = createContext<ProjectContextType>({} as ProjectContextType);

export default ProjectContext;

import React, { FunctionComponent, useState } from 'react';
import HoursList from 'modules/Home/HoursSection/HoursList/HoursList';
import HoursNavbar from 'modules/Home/HoursSection/HoursNavbar/HoursNavbar';
import { HoursSectionContainer } from 'modules/Home/HoursSection/HoursSection.components';

export enum NavbarElement {
  HOURS = 'GODZINY',
  PERCENTS = 'PROCENTY',
}
const pages = [NavbarElement.HOURS, NavbarElement.PERCENTS];

const HoursSection: FunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState(NavbarElement.HOURS);

  const handleHourManagementStyle = () => {
    switch (currentPage) {
      case NavbarElement.HOURS:
        return <HoursList />;
      case NavbarElement.PERCENTS:
        return (
          <div
            style={{
              display: 'flex',
              height: '90%',
              fontSize: '24px',
              flexDirection: 'column',
              gap: '24px',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            W trakcie przygotowania, zajrzyj ponownie później 👷🏻
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/s8Px2LI2Frs"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        );
    }
  };

  return (
    <HoursSectionContainer>
      <HoursNavbar pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      {handleHourManagementStyle()}
    </HoursSectionContainer>
  );
};

export default HoursSection;

import colors from 'modules/shared/styles/colors';
import { font16 } from 'modules/shared/styles/mixins';
import styled, { css } from 'styled-components';

export const AddHolidayPopupContainer = styled.div<{ left: number; top: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: ${p => p.left}px;
  top: ${p => p.top}px;
  background: #39393c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 8px rgba(50, 50, 71, 0.05);
  border-radius: 15px;
  padding: 10px 20px 20px 20px;
  font-family: 'Inter';
  color: rgba(255, 255, 255, 0.95);

  button {
    font-family: 'Inter' !important;
    color: rgba(255, 255, 255, 0.95) !important;
  }
`;

export const AddHolidayButton = styled.button<{ isDelete: boolean; isLoading: boolean }>`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 220px;
  width: 100%;
  height: 32px;
  font-weight: 500;
  ${font16()}
  text-align: center;
  border-radius: 5px;
  border: none;
  outline: none;
  background: ${p => (p.isDelete ? colors.gray10 : colors.purple90)};
  cursor: pointer;
  transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;

  :hover {
    transform: scale(1.025);
    opacity: 0.8;
  }

  ${p =>
    p.isLoading &&
    css`
      opacity: 0.5;
    `}
`;

export const AddHolidayTitle = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const AddHolidayUser = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.95);
`;

export const AddHolidayDateContainer = styled.div`
  display: flex;
  margin-top: 14px;
  margin-bottom: 16px;
  gap: 16px;
`;

export const AddHolidayRange = styled.span`
  flex: 1;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.95);
`;

export const AddHolidayDuration = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const AddHolidayCloseContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 250ms ease-in-out;

  :hover {
    transform: scale(1.25);
  }
`;

export const AddHolidayCloseContent = styled.div`
  width: 16px;
  height: 16px;
`;

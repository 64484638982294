import { createContext } from 'react';

import { ModalContentType } from 'modules/shared/components/Modal/ModalProvider';

export interface ModalContextType {
  openModal(node: ModalContentType, onBeforeClose?: () => void): void;
  openFixedModal(node: ModalContentType, wider?: boolean, onBeforeClose?: () => void): void;
  closeModal(): void;
}

const ModalContext = createContext<ModalContextType>({
  openModal: () => {
    throw new Error('You probably forgot to put <ModalContext.Provider>!');
  },
  openFixedModal: () => {
    throw new Error('You probably forgot to put <ModalContext.Provider>!');
  },
  closeModal: () => {
    throw new Error('You probably forgot to put <ModalContext.Provider>!');
  },
});

export default ModalContext;

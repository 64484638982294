import React, { createContext, Dispatch, SetStateAction } from 'react';
import { IUser } from 'firebase/types/User';
import { Holidays } from 'firebase/types/Holidays';
import { HolidaysConfig } from 'firebase/types/Config';

type Config = {
  holidays?: HolidaysConfig;
};

export interface UserContextType {
  isAdmin: boolean;
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  skippedNotifications: string[];
  users: IUser[] | undefined;
  holidaysData?: Holidays;
  config: Config;
}

const UserContext: React.Context<UserContextType> = createContext<UserContextType>({} as UserContextType);

export default UserContext;

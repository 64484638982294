import colors from 'modules/shared/styles/colors';
import { font14 } from 'modules/shared/styles/mixins';
import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

type TransitionProps = {
  status: TransitionStatus;
};

export const WarningBadgeWrapper = styled.div<TransitionProps>`
  display: flex;
  min-height: 38px;
  width: calc(100% - 16px);
  margin-top: 2px;
  background: linear-gradient(90.08deg, #f46457 0.03%, #e64d69 100%);
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  transition: transform 300ms ease, opacity 200ms ease;
  opacity: ${props => (props.status === 'entered' ? '1' : '0')};
  transform: translateY(${props => (props.status === 'entered' ? '0px' : '-100px')});
`;

export const WariningText = styled.span`
  font-weight: 500;
  ${font14()}
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
`;

export const SkipButton = styled.button`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  border: none;
  font-weight: 600;
  ${font14()}
  text-align: center;
  color: ${colors.white};
  margin-left: 32px;
  padding: 2px 20px;
  cursor: pointer;
`;

import firebase from 'firebase/app';

const editProjectPhase = async (
  phaseId: string,
  name: string,
  startDate: Date,
  endDate: Date | null,
  originalEstimate: number
) => {
  await firebase.firestore().collection('stages').doc(phaseId).update({
    name,
    startDate,
    endDate,
    originalEstimate,
  });
};

export default editProjectPhase;

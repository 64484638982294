import React, { useState } from 'react';
import {
  ProjectPhasesContainer,
  SingleProjectPhasesContainer,
} from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhases.components';
import SingleProjectPhasesTable from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhasesTable/SingleProjectPhasesTable';
import SingleProjectNotes from 'modules/SingleProject/SingleProjectNotes/SingleProjectNotes';
import SingleProjectNavbar from 'modules/SingleProject/SingleProjectNavbar/SingleProjectNavbar';

export enum NavbarElement {
  HOURS = 'GODZINY',
  NOTES = 'ZGŁOSZENIA',
}
const pages = [NavbarElement.HOURS, NavbarElement.NOTES];

const SingleProjectPhases = () => {
  const [currentPage, setCurrentPage] = useState(NavbarElement.HOURS);

  const handleHourManagementStyle = () => {
    switch (currentPage) {
      case NavbarElement.HOURS:
        return (
          <>
            <SingleProjectPhasesTable />
            <SingleProjectPhasesTable archived />
          </>
        );
      case NavbarElement.NOTES:
        return <SingleProjectNotes />;
    }
  };

  return (
    <SingleProjectPhasesContainer>
      <SingleProjectNavbar pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <ProjectPhasesContainer>{handleHourManagementStyle()}</ProjectPhasesContainer>
    </SingleProjectPhasesContainer>
  );
};

export default SingleProjectPhases;

import colors from 'modules/shared/styles/colors';
import { font12, font14 } from 'modules/shared/styles/mixins';
import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

export const SingleProjectNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  > :last-child {
    align-self: center;
  }
`;

export const SingleProjectPhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

type FilterProps = {
  active: boolean;
};
export const Filter = styled.button<FilterProps>`
  padding: 4px 12px;
  background: ${colors.gray30};
  border: none;
  ${font12()}
  color: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  margin-right: 4px;
  cursor: pointer;
  transition: opacity 0.5s ease;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${p =>
    !p.active &&
    css`
      opacity: 0.6;
      color: rgba(255, 255, 255, 0.6);
    `};
`;

export const SingleProjectPhaseWrapper = styled.div<{ status?: TransitionStatus }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: ${props => (props.status === 'entered' ? '1' : '0')};
  transform: scale(${props => (props.status === 'entered' ? '1' : '0')});
`;

export const Separator = styled.div`
  width: 1.3px;
  height: 5px;
  background-color: #95d0ce;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 2px;
  margin-top: 10px;
`;

export const NoteWrapper = styled.div<{ status?: TransitionStatus; timeout: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${colors.gray20};
  border-radius: 10px;
  flex: 1;
  padding: 12px;
  padding-left: 28px;
  margin-bottom: 8px;
  transition: ${p => `transform ${p.timeout * 300}ms ease, opacity ${p.timeout * 300}ms ease`};
  opacity: ${props => (props.status === 'entered' ? '1' : '0')};
  transform: translateY(${props => (props.status === 'entered' ? '0px' : '1000px')});

  ::before {
    content: '';
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 75%;
    background: #8ccbd9;
    border-radius: 2px;
  }
`;

export const NoteDetailsWrapper = styled.div`
  display: flex;
  ${font12()}
  color: rgba(255, 255, 255, 0.6);
  > :last-child {
    text-transform: uppercase;
  }
`;

export const NoteContent = styled.span`
  ${font14()}
  color: rgba(255, 255, 255, 0.8);
`;

import { RouteConfig, RouteConfigWithComponent, NestedRouteConfig } from 'modules/Routing/routes';

export const getRouteByPath = (routes: RouteConfigWithComponent[], path: string): RouteConfig | undefined =>
  routes.find((route) => route.path === path);

export const flatNestedRoutes = (nestedArray: NestedRouteConfig[]) =>
  nestedArray.reduce<RouteConfigWithComponent[]>((prev, it) => {
    const { children, ...routeConfig } = it;
    if (children) {
      prev.push(...flatNestedRoutes(children));
    }
    if (routeConfig.importComponent) {
      prev.push(routeConfig as RouteConfigWithComponent);
    }
    return prev;
  }, []);

import React, { useState, FunctionComponent, useEffect } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/app';
import { auth } from 'config/firebase';
import TimesheetContext from 'modules/App/AuthedLayout/TimesheetContext/TimesheetContext';
import { ITimesheet, ITimesheets } from 'firebase/types/Timesheets';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import { differenceInMonths, subMonths } from 'date-fns';

const MAX_DAYS = 31;

export const TimesheetProvider: FunctionComponent = ({ children }) => {
  const { selectedDate } = useUserContext();
  const [timesheetsData, setTimesheetsData] = useState<ITimesheet[]>();
  const [currentTimesheetData, setCurrentTimesheetData] = useState<ITimesheet[]>();
  const [isDuringSave, setIsDuringSave] = useState(false);
  const [fetchDate, setFetchDate] = useState(selectedDate);
  const [timesheetDoc, timesheetLoading] = useDocument<ITimesheets>(
    firebase.firestore().collection(`users/${auth.currentUser?.uid}/timesheets`).doc(`${fetchDate.getFullYear()}-${fetchDate.getMonth()}`),
  );

  useEffect(() => {
    setFetchDate(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (!timesheetLoading && timesheetDoc) {
      const timesheets = timesheetDoc.data()?.days;
      setTimesheetsData(timesheets);
      const timesheetData = timesheets?.filter(d => d.day === selectedDate.getDate());
      let found = false;
      if (timesheetData?.length === 0) {
        for (let i = selectedDate.getDate(); i >= 0; i--) {
          const lastTimesheet = timesheets?.filter(t => t.day === i);
          if (lastTimesheet && lastTimesheet?.length > 0) {
            // Timesheets found in current month - set them and break
            setCurrentTimesheetData([]);
            setTimeout(() =>
              setCurrentTimesheetData(
                lastTimesheet.map(timesheet => ({
                  day: timesheet.day,
                  phaseId: timesheet.phaseId,
                  value: 0,
                  projectId: timesheet.projectId,
                })),
              ),
            );
            found = true;
            break;
          }
          found = false;
        }
      }

      let previousMonthFound = false;
      if ((!found && timesheetData?.length === 0) || !timesheetData) {
        // Timesheets not found - check previous month
        const previousMonthDocString =
          selectedDate.getMonth() !== 0
            ? `${selectedDate.getFullYear()}-${selectedDate.getMonth() - 1}`
            : `${selectedDate.getFullYear() - 1}-11`;

        firebase
          .firestore()
          .collection(`users/${auth.currentUser?.uid}/timesheets`)
          .doc(previousMonthDocString)
          .get()
          .then(previousMonthData => {
            const prvData = previousMonthData.data();
            if (prvData && prvData.days.length) {
              for (let i = MAX_DAYS; i > 0; i--) {
                const lastTimesheet = prvData?.days.filter((t: any) => t.day === i);
                if (lastTimesheet && lastTimesheet?.length > 0) {
                  // Timesheets found in previous month - set them and break
                  previousMonthFound = true;
                  setCurrentTimesheetData([]);
                  setTimeout(() =>
                    setCurrentTimesheetData(
                      lastTimesheet.map((timesheet: any) => ({
                        day: timesheet.day,
                        phaseId: timesheet.phaseId,
                        value: 0,
                      })),
                    ),
                  );
                  break;
                }
              }
            }
          });
      }

      if (!found && !previousMonthFound) {
        if (!isDuringSave) setCurrentTimesheetData([]);
        setTimeout(() => setCurrentTimesheetData(timesheetData));
      }
    } else if (differenceInMonths(fetchDate, selectedDate) === 0) {
      subMonths(fetchDate, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheetLoading, timesheetDoc, selectedDate]);

  useEffect(() => {
    if (currentTimesheetData && currentTimesheetData?.length > 0) {
      setTimeout(() => setIsDuringSave(false), 100);
    }
  }, [currentTimesheetData]);

  return (
    <TimesheetContext.Provider value={{ timesheetsData, currentTimesheetData, setIsDuringSave }}>{children}</TimesheetContext.Provider>
  );
};

import { useEffect } from 'react';
import AppEventTarget from 'common/events/AppEventTarget';

// TODO: update type of event handler
const useEventHandler = (eventType: any, eventHandler: any) => {
  useEffect(() => {
    AppEventTarget.addEventListener(eventType, eventHandler);
    return () => {
      AppEventTarget.removeEventListener(eventType, eventHandler);
    };
  }, [eventType, eventHandler]);
};

export default useEventHandler;

import React, { FunctionComponent, forwardRef, Ref, ComponentPropsWithoutRef } from 'react';
import {
  InputComponentWrapper,
  InputWrapper,
  StyledInput,
  IconPosition,
  Icon,
  InputSize,
} from 'modules/shared/components/Form/Input/Input.components';
import ErrorMessage from 'modules/shared/components/Form/shared/ErrorMessage';
import { ReactComponent as ReminderIcon } from 'modules/Home/CalendarSection/icons/reminderIcon.svg';

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
  error?: string;
  type?: string;
  isDisabled?: boolean;
  icon?: string;
  addon?: string;
  iconPosition?: IconPosition;
  onFocus?: () => void;
  onIconClick?: () => void;
  sizeType?: InputSize;
  showError?: boolean;
  hasDescription?: boolean;
  underlineOnly?: boolean;
  description?: JSX.Element;
}

export const Input: FunctionComponent<
  InputProps & {
    forwardedRef?: Ref<HTMLInputElement>;
  }
> = ({
  error,
  type = 'text',
  forwardedRef,
  isDisabled,
  icon,
  iconPosition,
  onIconClick,
  onFocus,
  addon,
  sizeType,
  showError = true,
  hasDescription,
  underlineOnly,
  description,
  ...inputProps
}) => (
  <InputComponentWrapper isError={!!error} sizeType={sizeType}>
    <InputWrapper>
      <StyledInput
        {...inputProps}
        id={inputProps.name}
        ref={forwardedRef}
        isError={!!error}
        isIcon={!!icon}
        iconPosition={iconPosition}
        type={type}
        disabled={isDisabled}
        onFocus={onFocus}
        underlineOnly={underlineOnly}
      />
      {icon && <Icon src={icon} position={iconPosition} clickable={!!onIconClick && !isDisabled} onClick={onIconClick} />}
    </InputWrapper>
    {hasDescription && description && description}
    {showError && error && (
      <ErrorMessage>
        <ReminderIcon />
        {error}
      </ErrorMessage>
    )}
  </InputComponentWrapper>
);

export default forwardRef<HTMLInputElement, InputProps>((props, ref) => <Input {...props} forwardedRef={ref} />);

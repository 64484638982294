import { auth } from 'config/firebase';
import firebase from 'firebase/app';
import { format } from 'date-fns';

const skipNotification = async (dateToSkip: Date) => {
  const skippedNotificationsDoc = await firebase
    .firestore()
    .collection(`users/${auth.currentUser?.uid}/notifications`)
    .doc('skipped');

  const skippedNotificationsData = (await skippedNotificationsDoc.get()).data();
  const formattedDate = format(dateToSkip, 'dd-MM-yyyy');

  if (!skippedNotificationsData?.skippedNotifications) {
    skippedNotificationsDoc.set({
      skippedNotifications: [formattedDate],
    });
  } else {
    skippedNotificationsDoc.update({
      skippedNotifications: [formattedDate, ...skippedNotificationsData?.skippedNotifications],
    });
  }
};

export default skipNotification;

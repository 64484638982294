import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  AccordionButton,
  EditStageButton,
  FirstColumnWrapper,
  OriginalEstimate,
  PhaseDetailsContainer,
  PhaseDetailsTable,
  PhaseDetailsTableElement,
  PhaseDetailsTableHeader,
  PhaseDetailsTableRow,
  SingleProjectPhaseContainer,
  HoursAvailable,
  HoursUsed,
  HoursAvailableContainer,
} from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhase/SingleProjectPhase.components';
import { Transition } from 'react-transition-group';
import { ReactComponent as Chevron } from 'modules/SingleProject/icons/chevron.svg';
import { ReactComponent as EditPenIcon } from 'modules/SingleProject/icons/edit-pen.svg';
import AddSingleProjectPhaseForm from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhase/AddSingleProjectPhaseForm';
import { IProjectPhase } from 'firebase/types/ProjectPhase';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import { minutesToHourString } from 'common/helpers/minutesToHourString';
import CustomTooltip from 'modules/shared/components/Tooltip/Tooltip';
import { isSameMonth } from 'date-fns';

const forceReflow = (node: HTMLElement) => node.offsetHeight;

type Props = {
  phase?: IProjectPhase;
  addSinglePhase?: boolean;
  setAddPhaseEnabled?: Dispatch<SetStateAction<boolean>>;
};

const SingleProjectPhase: FunctionComponent<Props> = ({ phase, addSinglePhase = false, setAddPhaseEnabled }) => {
  const { isAdmin } = useUserContext();

  const [phaseOpened, setPhaseOpened] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);

  useEffect(() => {
    console.log('d', phase?.startDate.toDate());
    if (phase?.startDate && !phase.isArchived) {
      setPhaseOpened(isSameMonth(new Date(), phase.startDate.toDate()));
    }
  }, [phase]);

  const availableHours = useMemo(
    () =>
      phase?.originalEstimate
        ? phase?.details?.usedMinutes
          ? minutesToHourString(phase?.originalEstimate * 60 - phase?.details?.usedMinutes || 0, true)
          : minutesToHourString(phase!.originalEstimate * 60, true)
        : '-',
    [phase],
  );

  const usedHours = useMemo(() => minutesToHourString(phase?.details?.usedMinutes, true) || '-', [phase?.details?.usedMinutes]);

  if (addSinglePhase)
    return (
      <SingleProjectPhaseContainer gridDisabled>
        <AddSingleProjectPhaseForm availableHours={availableHours} usedHours={usedHours} finishAddOrEdit={setAddPhaseEnabled!} />
      </SingleProjectPhaseContainer>
    );

  return (
    <>
      <SingleProjectPhaseContainer phaseOpened={phaseOpened} gridDisabled={editEnabled}>
        {!editEnabled ? (
          <>
            <FirstColumnWrapper>
              <AccordionButton
                hide={!phase?.details || (phase.details && phase.details.usedMinutes === 0)}
                phaseOpened={phaseOpened}
                onClick={() => setPhaseOpened(!phaseOpened)}
              >
                <Chevron />
              </AccordionButton>
              <CustomTooltip title={phase?.name || ''}></CustomTooltip>
            </FirstColumnWrapper>
            <HoursUsed>{usedHours}</HoursUsed>
            <HoursAvailableContainer>
              <HoursAvailable
                showDanger={
                  phase?.originalEstimate && phase?.details?.usedMinutes
                    ? phase?.originalEstimate * 60 - phase?.details?.usedMinutes <= 0
                    : false
                }
                hideAlerts={phase?.originalEstimate === 0}
              >
                {availableHours}
              </HoursAvailable>
            </HoursAvailableContainer>
            <OriginalEstimate>{phase?.originalEstimate !== 0 ? phase?.originalEstimate : '-'}</OriginalEstimate>

            {isAdmin ? (
              <EditStageButton onClick={() => setEditEnabled(true)}>
                <EditPenIcon />
              </EditStageButton>
            ) : (
              <div></div>
            )}
          </>
        ) : (
          <AddSingleProjectPhaseForm availableHours={availableHours} usedHours={usedHours} phase={phase} finishAddOrEdit={setEditEnabled} />
        )}
      </SingleProjectPhaseContainer>
      <Transition timeout={50} mountOnEnter={false} unmountOnExit={false} in={phaseOpened} onEnter={forceReflow}>
        {status => (
          <PhaseDetailsContainer status={status}>
            {phase?.hours && phase.details && (
              <PhaseDetailsTable>
                <tbody>
                  <PhaseDetailsTableRow>
                    {phase.details?.phaseMembers.map(u => {
                      const [name, surname] = u.displayName.split(' ');
                      return (
                        <PhaseDetailsTableHeader scope="col" key={u.uid}>{`${name} ${surname?.slice(0, 1)}.`}</PhaseDetailsTableHeader>
                      );
                    })}
                  </PhaseDetailsTableRow>
                  {Object.keys(phase.details!.data).map(year => {
                    return Object.keys(phase.details!.data[year]).map(month => {
                      return (
                        <PhaseDetailsTableRow key={`${year}-${month}`}>
                          {phase.details?.phaseMembers.map(u => {
                            const hourEntry = phase.details?.data[year][month].find(hourEntry => hourEntry.userId === u.uid);
                            return (
                              <PhaseDetailsTableElement key={`${year}-${month}-${u.uid}`}>
                                {minutesToHourString(hourEntry?.hours, true) || 0}
                              </PhaseDetailsTableElement>
                            );
                          })}
                        </PhaseDetailsTableRow>
                      );
                    });
                  })}
                </tbody>
              </PhaseDetailsTable>
            )}
          </PhaseDetailsContainer>
        )}
      </Transition>
    </>
  );
};

export default SingleProjectPhase;

import React, { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from 'modules/App/App';

const Root: FunctionComponent = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default Root;

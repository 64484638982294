import React, { useState, FunctionComponent, useEffect } from 'react';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/app';
import { auth } from 'config/firebase';
import UserContext from 'modules/App/AuthedLayout/UserContext/UserContext';
import { IUser, Role } from 'firebase/types/User';
import { useUserHolidays } from 'firebase/holidays/useUserHolidays';
import { HolidaysConfig } from 'firebase/types/Config';

export const UserProvider: FunctionComponent = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [users, setUsers] = useState<IUser[]>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [skippedNotifications, setSkippedNotifications] = useState<string[]>([]);
  const { holidays: holidaysData } = useUserHolidays(selectedDate.getFullYear());

  const [usersDoc, usersLoading] = useCollection<IUser>(firebase.firestore().collection(`users`));
  const [skippedNotificationsData, skippedNotificationsLoading] = useDocumentData<{ skippedNotifications: string[] }>(
    firebase.firestore().collection(`users/${auth.currentUser?.uid}/notifications`).doc('skipped'),
  );

  const [holidaysConfig] = useDocumentData<HolidaysConfig>(firebase.firestore().collection(`config`).doc('holidays'));

  useEffect(() => {
    if (!usersLoading && usersDoc) {
      const usersWithIds = usersDoc.docs.map(doc => {
        const userData = doc.data();
        return {
          uid: doc.id,
          ...userData,
        };
      });
      setUsers(usersWithIds);
      setIsAdmin(usersWithIds.find(u => u.uid === auth.currentUser?.uid)?.role === Role.ADMIN);
    }

    if (!skippedNotificationsLoading && skippedNotificationsData) {
      setSkippedNotifications(skippedNotificationsData.skippedNotifications);
    }
  }, [usersLoading, usersDoc, skippedNotificationsData, skippedNotificationsLoading]);

  return (
    <UserContext.Provider
      value={{ config: { holidays: holidaysConfig }, holidaysData, isAdmin, selectedDate, setSelectedDate, skippedNotifications, users }}
    >
      {children}
    </UserContext.Provider>
  );
};

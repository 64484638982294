import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState, useCallback } from 'react';
import { useModal } from 'modules/shared/components/Modal';
import {
  AddProjectModalFormValues,
  addProjectModalInitialValues,
  addProjectModalValidationSchema,
} from 'modules/shared/components/Modals/AddProjectModal/form';
import AddProjectModalFormContent from 'modules/shared/components/Modals/AddProjectModal/AddProjectModalFormContent';
import addProject from 'firebase/projects/addProject';
import { toast } from 'react-toastify';
import { toSvg } from 'jdenticon';

const AddProjectModalForm: FunctionComponent = () => {
  const [message, setMessage] = useState<string>();
  const { closeModal } = useModal();

  const handleSubmit = useCallback(
    async (values: AddProjectModalFormValues, { setFieldError }: FormikHelpers<AddProjectModalFormValues>) => {
      try {
        const { name, client, icon } = values;
        let base64img = '';
        if (!icon) {
          const svgString = toSvg(new Date().getMilliseconds().toString(), 100);
          base64img = `data:image/svg+xml;base64,${window.btoa(svgString)}`;
        }
        await addProject(name, client, icon || base64img);
        toast.success('Projekt dodany! 💼');
        closeModal();
      } catch (e) {
        toast.error('Wystąpił błąd :(');
        setMessage('There was a problem. Please try again.');
      }
    },
    [closeModal]
  );

  return (
    <Formik
      initialValues={addProjectModalInitialValues}
      validationSchema={addProjectModalValidationSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => <AddProjectModalFormContent {...formProps} message={message} />}
    </Formik>
  );
};

export default AddProjectModalForm;

import { createContext } from 'react';
import firebase from 'firebase/app';

export interface AppContextType {
  user: firebase.User | null | undefined;
}

const AppContext: React.Context<AppContextType> = createContext<AppContextType>({} as AppContextType);

export default AppContext;

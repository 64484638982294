import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useModal } from 'modules/shared/components/Modal';
import SelectPhasesModalFormContent from 'modules/shared/components/Modals/SelectPhasesModal/SelectPhasesModalFormContent';
import { SelectPhasesModalFormValues } from 'modules/shared/components/Modals/SelectPhasesModal/form';
import { IProjectPhase } from 'firebase/types/ProjectPhase';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import useTimesheetContext from 'modules/App/AuthedLayout/TimesheetContext/useTimesheetContext';
import { toast } from 'react-toastify';
import setTimesheets from 'firebase/timesheet/setTimesheets';
import { IProject } from 'firebase/types/Project';

type Props = {
  phases: IProjectPhase[];
  projects: IProject[];
};

const SelectPhasesModalForm: FunctionComponent<Props> = ({ phases, projects }) => {
  const { closeModal } = useModal();
  const { selectedDate } = useUserContext();
  const { currentTimesheetData } = useTimesheetContext();

  const initialValues = useMemo(() => {
    return currentTimesheetData && currentTimesheetData?.length > 0
      ? currentTimesheetData?.map(timesheet => timesheet.phaseId.split('-')[0])
      : [];
  }, [currentTimesheetData]);

  console.log(initialValues);

  const handleSubmit = useCallback(
    async (values: SelectPhasesModalFormValues, { setFieldError }: FormikHelpers<SelectPhasesModalFormValues>) => {
      try {
        await setTimesheets(
          selectedDate,
          values.projects.map(project => {
            const phaseId = `${project}-${selectedDate.getFullYear()}-${selectedDate.getMonth()}`;
            return {
              day: selectedDate.getDate(),
              phaseId: phaseId,
              projectId: project,
              value: currentTimesheetData?.find(timesheet => timesheet.phaseId === phaseId)?.value || 0,
              note: currentTimesheetData?.find(timesheet => timesheet.phaseId === phaseId)?.note || '',
            };
          }),
        );
        toast.success('Lista projektów zaktualizowna!');
        closeModal();
      } catch (e) {
        console.log(e);
        toast.error('Wystąpił błąd :(');
      }
    },
    [closeModal, currentTimesheetData, selectedDate],
  );

  return (
    <Formik initialValues={{ projects: initialValues || [] }} onSubmit={handleSubmit} enableReinitialize>
      {formProps => <SelectPhasesModalFormContent {...formProps} projects={projects} phases={phases} />}
    </Formik>
  );
};

export default SelectPhasesModalForm;

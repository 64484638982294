import { Formik, FormikHelpers } from 'formik';
import React, { Dispatch, FunctionComponent, useCallback, useState } from 'react';
import NoteFormContent from 'modules/Home/HoursSection/HoursList/ProjectBar/Note/NoteFormContent';
import { NoteFormValues } from 'modules/Home/HoursSection/HoursList/ProjectBar/Note/form';
import { TransitionStatus } from 'react-transition-group';
import { FormikWrapper } from 'modules/Home/HoursSection/HoursList/ProjectBar/Note/Note.components';
import { SetStateAction } from 'react';
import setNote from 'firebase/timesheet/setNote';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import useTimesheetContext from 'modules/App/AuthedLayout/TimesheetContext/useTimesheetContext';
import { toast } from 'react-toastify';
import AppEventTarget from 'common/events/AppEventTarget';
import CalendarTabChangeEvent from 'common/events/CalendarTabChangeEvent';

type Props = {
  status: TransitionStatus;
  setInputOpened: Dispatch<SetStateAction<boolean>>;
  phaseId: string;
  note: string;
};

const NoteForm: FunctionComponent<Props> = ({ status, setInputOpened, phaseId, note }) => {
  const [isSaveOnEnter, setIsSaveOnEnter] = useState(false);
  const { selectedDate } = useUserContext();
  const { currentTimesheetData, setIsDuringSave } = useTimesheetContext();

  const handleSubmit = useCallback(
    async (values: NoteFormValues, _: FormikHelpers<NoteFormValues>) => {
      try {
        // Save hours before saving notes
        if (!isSaveOnEnter) AppEventTarget.dispatchEvent(new CalendarTabChangeEvent());

        setTimeout(async () => {
          setIsDuringSave(true);
          await setNote(selectedDate, currentTimesheetData!, phaseId, values.note);
          setInputOpened(false);
          toast.success('Notatka dodana ✏️');
          setIsSaveOnEnter(false);
        }, 300);
      } catch (e) {
        toast.error('Wystąpił błąd');
      }
    },
    [selectedDate, currentTimesheetData, phaseId, setIsDuringSave, setInputOpened, isSaveOnEnter],
  );

  return (
    <FormikWrapper status={status}>
      <Formik initialValues={{ note }} onSubmit={handleSubmit} enableReinitialize={true}>
        {formProps => <NoteFormContent setInputOpened={setInputOpened} setIsSaveOnEnter={setIsSaveOnEnter} {...formProps} />}
      </Formik>
    </FormikWrapper>
  );
};

export default NoteForm;

import { appEventTypes } from 'common/events/AppEventTarget';
import { Event } from 'event-target-shim/es5';

class CalendarTabChangeEvent extends Event {
  constructor() {
    super(appEventTypes.CALENDAR_TAB_CHANGE);
  }
}

export default CalendarTabChangeEvent;

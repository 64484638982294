import colors from 'modules/shared/styles/colors';
import { css } from 'styled-components';

export const styledScrollbar = (bg?: string) => css`
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.grayLighter};
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #4e4e52;
  }
  ::-webkit-scrollbar-track {
    background: ${bg || colors.gray10};
    border-radius: 15px;
  }
`;

export const font12 = () => css`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const font14 = () => css`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const font16 = () => css`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

import colors from 'modules/shared/styles/colors';
import { styledScrollbar } from 'modules/shared/styles/mixins';
import styled from 'styled-components';

export const HolidaysContainer = styled.div`
  color: white;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  flex: 1;
`;

export const HolidaysCalendarContainer = styled.div`
  padding: 56px 98px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  min-width: 636px;
  ${styledScrollbar()}
`;

export const HolidaysCalendarHeader = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #ffffff;
  width: 100%;
`;

export const HolidaysCalendarPrevYearButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 6px;
  margin: 0px;
  cursor: pointer;
  transition: transform 250ms ease-in-out;

  :hover {
    transform: scale(1.25);
  }
`;

export const HolidaysCalendarCurrentYear = styled.span`
  //min-width to fully contain 0000 text to avoid jumping view on year change
  min-width: 165px;
  margin: 0px 20px 0px 10px;
`;

export const HolidaysCalendarAvailableDays = styled.span`
  background-color: ${colors.purple90};
  min-width: 115px;
  padding: 12px 20px;
  border-radius: 15px;
  height: 24px;
`;

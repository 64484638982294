import colors from 'modules/shared/styles/colors';
import { font14 } from 'modules/shared/styles/mixins';
import styled from 'styled-components';

export const EditSingleProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 336px;
  height: 100vh;
  border-right: 2px solid ${colors.gray10};
  @media all and (max-width: 1200px) {
    min-width: 180px;
  }
  @media all and (max-width: 1000px) {
    display: none;
  }
`;

export const EditSingleProjectHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  background: ${colors.gray20};
  justify-content: center;
  align-items: center;
  font-weight: 600;
  ${font14()}
  text-align: center;
  color: ${colors.white};
  border-bottom: 2px solid ${colors.gray10};
`;

export const EditSingleProjectFormContainter = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100vh;
  background: ${colors.gray20};
`;

export const EditSingleProjectFormWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 40px 32px;

  @media all and (max-width: 850px) {
    margin: 30px 16px;
  }
`;

export const EditSingleProjectButton = styled.button<{ formDisabled: boolean }>`
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  background: ${(p) => (p.formDisabled ? 'rgba(255, 255, 255, 0.15)' : colors.purple)};
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 8px;
  transition: background 0.5s ease;
  > svg {
    transition: transform 0.5s ease;
  }

  &:hover {
    > svg {
      transform: scale(1.1);
    }
  }
`;

export const EditSingleProjectIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

import React, { FunctionComponent } from 'react';
import { Content, ContentWrapper } from 'modules/App/AuthedLayout/AuthedLayout.components';
import SideMenu from 'modules/App/AuthedLayout/SideMenu';
import { ProjectProvider } from 'modules/App/AuthedLayout/ProjectContext/ProjectProvider';
import { ModalProvider } from 'modules/shared/components/Modal';
import { UserProvider } from 'modules/App/AuthedLayout/UserContext/UserProvider';
import { TimesheetProvider } from 'modules/App/AuthedLayout/TimesheetContext/TimesheetProvider';

const AuthedLayout: FunctionComponent = ({ children }) => {
  return (
    <UserProvider>
      <ProjectProvider>
        <TimesheetProvider>
          <ModalProvider>
            <Content>
              <SideMenu />
              <ContentWrapper>{children}</ContentWrapper>
            </Content>
          </ModalProvider>
        </TimesheetProvider>
      </ProjectProvider>
    </UserProvider>
  );
};

export default AuthedLayout;

import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import PublicLayout from 'modules/App/PublicLayout';
import routesPaths from 'modules/Routing/routesPaths';
import useAppContext from 'modules/App/useAppContext';

type Props = RouteProps & Required<Pick<RouteProps, 'component'>>;

export const PublicRoute: FunctionComponent<Props> = ({ component: Component, ...routeProps }) => {
  const appContext = useAppContext();

  if (appContext?.user?.uid && appContext?.user?.emailVerified) {
    return <Redirect to={routesPaths.HOME} />;
  }

  return (
    <Route
      {...routeProps}
      render={(routeComponentProps) => (
        <PublicLayout>
          <Component {...routeComponentProps} />
        </PublicLayout>
      )}
    />
  );
};

export default PublicRoute;

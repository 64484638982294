import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  DropzoneContainer,
  DropzoneInput,
  DropzoneWrapper,
  Placeholder,
  Description,
  PreviewImage,
  PreviewImageWrapper,
  UpdateImageText,
  DropzoneErrorWrapper,
} from 'modules/shared/components/Form/Dropzone/Dropzone.components';
import { imageProcessor, resize, sharpen } from 'ts-image-processor';

type Props = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  disabled?: boolean;
  placeholderBase64?: string;
  smallerSize?: boolean;
};

const Dropzone: FunctionComponent<Props> = ({
  setFieldValue,
  disabled,
  placeholderBase64 = null,
  smallerSize = false,
}) => {
  const [base64, setBase64] = useState<string | ArrayBuffer | null | undefined>();
  const [dropRejected, setDropRejected] = useState(false);
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const isSvg = file.type === 'image/svg+xml';
      setDropRejected(false);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        if (isSvg) {
          setBase64(event.target?.result?.toString());
          setFieldValue('icon', event.target?.result?.toString());
        } else {
          imageProcessor
            .src(event.target?.result?.toString() || '')
            .pipe(resize({ maxWidth: 300, maxHeight: 300 }), sharpen())
            .then((processedBase64) => {
              setBase64(processedBase64.toString());
              setFieldValue('icon', processedBase64.toString());
            });
        }
      };
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected: (file) => setDropRejected(true),
    accept: 'image/jpeg, image/png, image/svg+xml',
  });

  return (
    <>
      <DropzoneContainer {...getRootProps()} style={{ marginTop: 10 }}>
        <DropzoneInput disabled={disabled} {...getInputProps()} />
        <DropzoneWrapper
          dragActive={isDragActive}
          dropRejected={dropRejected}
          smallerSize={smallerSize}
          isDisabled={disabled}
        >
          {base64 || placeholderBase64 ? (
            <PreviewImage src={`${placeholderBase64 || base64}`} isDisabled={disabled}>
              {!disabled && (
                <PreviewImageWrapper smallerSize={smallerSize}>
                  <UpdateImageText>Zmień</UpdateImageText>
                </PreviewImageWrapper>
              )}
            </PreviewImage>
          ) : (
            <>
              <Placeholder />
              <Description>{isDragActive ? <>Upuść plik...</> : <>Wgraj lub przerzuć</>}</Description>
            </>
          )}
        </DropzoneWrapper>
        {dropRejected && <DropzoneErrorWrapper>Invalid file format.</DropzoneErrorWrapper>}
      </DropzoneContainer>
    </>
  );
};

export default Dropzone;

import React, { FunctionComponent } from 'react';
import {
  CheckboxContent,
  FakeInput,
  InvisibleCheckbox,
} from 'modules/shared/components/Form/Checkbox/Checkbox.components';
import { ReactComponent as Checkmark } from 'modules/shared/components/Form/Checkbox/check.svg';

interface Props {
  name: string;
  value: string;
  checked?: boolean;
  visible?: boolean;
}

const Checkbox: FunctionComponent<Props> = ({ name, value, checked, children, ...props }) => {
  return (
    <CheckboxContent>
      <InvisibleCheckbox type="checkbox" name={name} value={value} checked={checked} onChange={() => null} />
      <FakeInput>
        <Checkmark />
      </FakeInput>
      {children}
    </CheckboxContent>
  );
};
export default Checkbox;

import { TransitionStatus } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';
import colors from 'modules/shared/styles/colors';
import { font16 } from 'modules/shared/styles/mixins';

type ContainerProps = {
  status: TransitionStatus;
};
export const NoProjectsContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 400px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px dashed ${colors.gray50};
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 66px;
  align-items: center;

  transition: transform 300ms ease, opacity 200ms ease;
  opacity: ${(props) => (props.status === 'entered' ? '1' : '0')};
  transform: scale(${(props) => (props.status === 'entered' ? '1' : '0')});
`;

export const NoProjectsDescription = styled.span`
  display: flex;
  font-weight: 500;
  ${font16()}
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  max-width: 296px;
  margin-top: 40px;
  justify-content: center;
`;

const pointerAnimation = keyframes`
 0% { top: 0px;
    transform: rotate(180deg)
  }
 50% {top: 20px;
    transform: rotate(0deg)
 }
  100% { top:0px;
    transform: rotate(180deg) } 
`;

export const TapIconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  height: 54px;

  > svg {
    position: absolute;
    animation: ${pointerAnimation} 2.55s ease infinite;
  }
`;

const buttonAnimation = keyframes`
 0% { 
    transform: scale(1) rotate(0deg)
  }
  45% {
    transform: scale(1) rotate(0deg)
  }
  48% {
    transform: scale(1.1) rotate(5deg)
 }
 50% {
    transform: scale(1.1) rotate(0deg)
 }
 52% {
    transform: scale(1.1) rotate(-5deg)
 }
 55% {
    transform: scale(1) rotate(0deg)
  }
  100% { 
    transform: scale(1) } 
`;

export const AddProjectsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 157px;
  height: 32px;
  border: none;
  background: ${colors.purple90};
  border-radius: 5px;
  font-weight: 500;
  ${font16()}
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
  margin-bottom: 40px;
  animation: ${buttonAnimation} 2.5s ease infinite;
  cursor: pointer;
`;

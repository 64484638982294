import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState, useCallback, Dispatch, SetStateAction } from 'react';
import {
  AddSingleProjectPhaseFormValues,
  addSingleProjectPhaseInitialValues,
  addSingleProjectPhaseValidationSchema,
} from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhase/form';
import AddSingleProjectPhaseFormContent from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhase/AddSingleProjectPhaseFormContent';
import addProjectPhase from 'firebase/projects/addProjectPhase';
import { useParams } from 'react-router-dom';
import { IProjectPhase } from 'firebase/types/ProjectPhase';
import editProjectPhase from 'firebase/projects/editProjectPhase';
import { toast } from 'react-toastify';

type Props = {
  finishAddOrEdit: Dispatch<SetStateAction<boolean>>;
  phase?: IProjectPhase;
  availableHours: string;
  usedHours: string;
};

const AddSingleProjectPhaseForm: FunctionComponent<Props> = ({ phase, availableHours, usedHours, finishAddOrEdit }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const [message, setMessage] = useState<string>();

  const handleSubmit = useCallback(
    async (values: AddSingleProjectPhaseFormValues, { setFieldError }: FormikHelpers<AddSingleProjectPhaseFormValues>) => {
      try {
        const { phaseName, startDate, endDate, originalEstimate } = values;
        if (!phase) await addProjectPhase(projectId, phaseName, startDate, endDate, Number(originalEstimate));
        else await editProjectPhase(phase.phaseId!, phaseName, startDate, endDate, Number(originalEstimate));

        finishAddOrEdit!(false);
      } catch (e) {
        console.log(e);
        toast.error('Wystąpił błąd :(');
        setMessage('There was a problem. Please try again.');
      }
    },
    [projectId, phase, finishAddOrEdit],
  );

  return (
    <Formik
      initialValues={
        phase
          ? {
              phaseName: phase?.name,
              startDate: phase?.startDate ? new Date(phase.startDate.seconds * 1000) : new Date(),
              endDate: phase?.endDate ? new Date(phase.endDate.seconds * 1000) : null,
              originalEstimate: phase?.originalEstimate.toString(),
            }
          : addSingleProjectPhaseInitialValues
      }
      validationSchema={addSingleProjectPhaseValidationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formProps => (
        <AddSingleProjectPhaseFormContent availableHours={availableHours} usedHours={usedHours} {...formProps} message={message} />
      )}
    </Formik>
  );
};

export default AddSingleProjectPhaseForm;

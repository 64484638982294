import React, { FunctionComponent, useEffect } from 'react';
import createUser from 'firebase/user/createUser';
import CalendarSection from 'modules/Home/CalendarSection/CalendarSection';
import { HomeContainer } from 'modules/Home/Home.components';
import HoursSection from 'modules/Home/HoursSection/HoursSection';

const Home: FunctionComponent = () => {
  useEffect(() => {
    createUser();
  }, []);

  return (
    <HomeContainer>
      <CalendarSection />
      <HoursSection />
    </HomeContainer>
  );
};

export default Home;

import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import {
  CalendarNavbarContainer,
  CalendarNavbarItem,
} from 'modules/Home/CalendarSection/CalendarNavbar/CalendarNavbar.components';

export enum NavbarElement {
  CALENDAR = 'Kalendarz',
  NOTES = 'Zgłoszenia',
  PROJECTS = 'Projekty',
}
const navbarElements = [NavbarElement.CALENDAR, NavbarElement.NOTES];

type Props = {
  currentSection: NavbarElement;
  setCurrentSection: Dispatch<SetStateAction<NavbarElement>>;
};

const CalendarNavbar: FunctionComponent<Props> = ({ currentSection, setCurrentSection }) => {
  return (
    <CalendarNavbarContainer>
      {navbarElements.map((el, index) => (
        <CalendarNavbarItem key={index} active={el === currentSection} onClick={() => setCurrentSection(el)}>
          {el}
        </CalendarNavbarItem>
      ))}
    </CalendarNavbarContainer>
  );
};

export default CalendarNavbar;

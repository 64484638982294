import styled, { css } from 'styled-components';
import colors from 'modules/shared/styles/colors';
import UploadIcon from 'modules/shared/components/Form/Dropzone/upload.svg';
import { font12, font14 } from 'modules/shared/styles/mixins';

export const DropzoneContainer = styled.div`
  &:active,
  :focus {
    outline: none;
  }
`;
export const DropzoneInput = styled.input``;

type Props = { dragActive: boolean; dropRejected?: boolean; smallerSize: boolean; isDisabled?: boolean };

export const DropzoneWrapper = styled.div<Props>`
  display: flex;
  width: 144px;
  height: 144px;
  background: ${colors.white};
  background: ${colors.gray30};
  border: 1px solid ${colors.gray50};
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;

  &:hover {
    cursor: ${(p) => (p.isDisabled ? 'auto' : 'pointer')};
  }
  ${(p) =>
    p.dragActive &&
    css`
      border-style: dashed;
    `}

  ${(p) =>
    p.smallerSize &&
    css`
      width: 120px;
      height: 120px;
    `}
`;

export const Placeholder = styled.div`
  width: 24px;
  height: 24px;
  background: url(${UploadIcon});
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;
`;

export const Description = styled.span`
  font-weight: normal;
  width: 60%;
  ${font12()}
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
`;

type ImageProps = {
  src: string;
  isDisabled?: boolean;
};
export const PreviewImageWrapper = styled.div<{ smallerSize?: boolean }>`
  position: absolute;
  bottom: ${(p) => (p.smallerSize ? 4 : 16)}px;
  left: 50%;
  width: 112px;
  transform: translateX(-50%);
  height: 32px;
  display: none;
  background: ${colors.gray10};
  opacity: 0.8;
  border-radius: 10px;
  transition: display 0.1s ease-in;
  align-items: center;
  justify-content: center;
`;

export const PreviewImage = styled.div<ImageProps>`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${(p) => p.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  &:hover {
    ${PreviewImageWrapper} {
      display: flex;
      cursor: ${(p) => (p.isDisabled ? 'auto' : 'pointer')};
    }
  }
`;

export const UpdateImageText = styled.span`
  font-weight: 500;
  ${font14()}
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
`;

export const DropzoneErrorWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 8px;
  width: 100%;
  ${font12()}
  & > * {
    ${font12()}
  }
`;

import React, { FunctionComponent, SetStateAction, Dispatch } from 'react';
import { FormikProps } from 'formik';
import { NoteFormValues } from 'modules/Home/HoursSection/HoursList/ProjectBar/Note/form';
import {
  ButtonsWrapper,
  FormWrapper,
  NoteButton,
  StyledTextarea,
} from 'modules/Home/HoursSection/HoursList/ProjectBar/Note/Note.components';

type Props = FormikProps<NoteFormValues> & {
  setInputOpened: Dispatch<SetStateAction<boolean>>;
  setIsSaveOnEnter: Dispatch<SetStateAction<boolean>>;
};

const NoteFormContent: FunctionComponent<Props> = ({
  isSubmitting,
  isValid,
  submitCount,
  submitForm,
  handleChange,
  setFieldValue,
  values,
  setInputOpened,
  setIsSaveOnEnter,
  ...props
}) => {
  return (
    <FormWrapper
      onKeyDown={async (e) => {
        if (e.key === 'Enter' && e.shiftKey === false) {
          e.preventDefault();
          setIsSaveOnEnter(true);
          await submitForm();
        }
      }}
    >
      <StyledTextarea
        data-enable-grammarly="false."
        onChange={(e) => setFieldValue('note', e.target.value)}
        autoFocus
        value={values.note}
        maxLength={1000}
      />
      <ButtonsWrapper>
        <NoteButton type="button" onClick={() => setInputOpened(false)}>
          Anuluj
        </NoteButton>
        <NoteButton greenBg type="button" onClick={submitForm}>
          Dodaj
        </NoteButton>
      </ButtonsWrapper>
    </FormWrapper>
  );
};
export default NoteFormContent;

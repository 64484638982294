const stringValueToMinutes = (value: string) => {
  const hasSeparator = value.includes(':');
  let summaryTime = 0;
  if (hasSeparator) {
    // Indicates that input format was HH:MM
    const splittedValue = value.split(':');
    const hours = Number(splittedValue[0]);
    const minutes = Number(splittedValue[1]);
    if (hours === 0) {
      summaryTime = minutes;
    }
    summaryTime = hours * 60 + minutes;
  } else {
    // Indicates that input format was HH
    summaryTime = Number(value) * 60;
  }

  return summaryTime;
};

export default stringValueToMinutes;

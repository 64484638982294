import React, { FunctionComponent } from 'react';
import skipNotification from 'firebase/user/skipNotification';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import {
  SkipButton,
  WariningText,
  WarningBadgeWrapper,
} from 'modules/shared/components/WarningBadge/WarningBadge.components';
import { Transition } from 'react-transition-group';

type Props = {
  content: string;
  showBadge: boolean;
};

const WarningBadge: FunctionComponent<Props> = ({ content, showBadge }) => {
  const { selectedDate } = useUserContext();

  return (
    <Transition timeout={300} mountOnEnter={true} unmountOnExit={true} appear={true} in={showBadge}>
      {(status) => (
        <WarningBadgeWrapper status={status}>
          <WariningText> {content}</WariningText>
          <SkipButton
            onClick={async () => {
              await skipNotification(selectedDate);
            }}
          >
            SKIP
          </SkipButton>
        </WarningBadgeWrapper>
      )}
    </Transition>
  );
};

export default WarningBadge;

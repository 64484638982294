import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import useModal from 'modules/shared/components/Modal/useModal';
import { ButtonsWrapper, ModalButton, StyledForm } from 'modules/shared/components/Modals/AddProjectModal/AddProjectModal.components';
import Checkbox from 'modules/shared/components/Form/Checkbox';
import { CheckboxLabel } from 'modules/shared/components/Form/Checkbox/Checkbox.components';
import { SelectPhasesModalFormValues } from 'modules/shared/components/Modals/SelectPhasesModal/form';
import {
  FilterInput,
  PhasesContainer,
  PhasesListWrapper,
  // SelectedPhase,
  // SelectedPhasesWrapper,
  WariningWrapper,
} from 'modules/shared/components/Modals/SelectPhasesModal/SelectPhasesModal.components';
// import { ReactComponent as CrossIcon } from 'modules/shared/components/Modals/SelectPhasesModal/crossIcon.svg';
import { IProjectPhase } from 'firebase/types/ProjectPhase';
import { Transition } from 'react-transition-group';
import useTimesheetContext from 'modules/App/AuthedLayout/TimesheetContext/useTimesheetContext';
import CustomTooltip from '../../Tooltip/Tooltip';
import { IProject } from 'firebase/types/Project';

type Props = FormikProps<SelectPhasesModalFormValues> & {
  phases: IProjectPhase[];
  projects: IProject[];
  message?: string;
};

const SelectPhasesModalFormContent: FunctionComponent<Props> = ({
  isSubmitting,
  isValid,
  submitCount,
  values,
  message,
  projects,
  setFieldValue,
  setFieldTouched,
  phases,
  submitForm,
  ...props
}) => {
  const { closeModal } = useModal();
  const [showWarning, setShowWarning] = useState(false);
  const { currentTimesheetData } = useTimesheetContext();
  const [searchPhrase, setSearchPhrase] = useState('');

  useEffect(() => {
    const summaryLength = currentTimesheetData?.filter(t => t.value !== 0).length || 0;
    let correct = 0;
    if (currentTimesheetData) {
      for (let currentTimesheet of currentTimesheetData.filter(t => t.value !== 0)) {
        if (values.projects.includes(currentTimesheet.projectId)) {
          correct++;
        }
      }
    }
    if (correct === summaryLength) setShowWarning(false);
    else setShowWarning(true);
  }, [values, currentTimesheetData]);

  return (
    <StyledForm
      onKeyDown={e => {
        if (e.key === 'Enter' && e.shiftKey === false) {
          e.preventDefault();
          submitForm();
        }
      }}
    >
      <FilterInput
        autoFocus
        onChange={e => {
          setSearchPhrase(e.target.value);
        }}
      />
      <PhasesContainer
        onChange={e => {
          const target = e.target as HTMLInputElement;
          const targetValue = target.value;
          const nextValue = values.projects.includes(targetValue)
            ? values.projects.filter(value => value !== target.value)
            : values.projects.concat(targetValue);

          setFieldValue(target.name, nextValue);
          setTimeout(() => setFieldTouched('phases', true));
        }}
      >
        <PhasesListWrapper>
          {projects
            .filter(p => p.name?.toUpperCase().includes(searchPhrase.toUpperCase()))
            .map(project => (
              <Checkbox
                key={project.projectId}
                name="projects"
                value={project.projectId!}
                checked={values.projects ? values.projects.includes(project.projectId!) : false}
              >
                <CheckboxLabel>
                  <CustomTooltip title={project.name} />
                </CheckboxLabel>
              </Checkbox>
            ))}
        </PhasesListWrapper>
        {/* SAFE TO UNCOMMENT - HIDDEN TEMPORARILY - ASK SSD */}
        {/* {values.phases.length > 0 && (
          <SelectedPhasesWrapper>
            {values.phases.map((v, index) => (
              <Transition key={index} timeout={200} mountOnEnter={true} unmountOnExit={true} appear={true} in={true}>
                {(status) => (
                  <SelectedPhase status={status}>
                    {phases.find((p) => p.phaseId === v)!.fullName}
                    <CrossIcon
                      onClick={() =>
                        setFieldValue(
                          'phases',
                          values.phases.filter((val) => val !== v)
                        )
                      }
                    />
                  </SelectedPhase>
                )}
              </Transition>
            ))}
          </SelectedPhasesWrapper>
        )} */}
      </PhasesContainer>
      <Transition timeout={100} mountOnEnter={true} unmountOnExit={true} appear={true} in={showWarning}>
        {status => (
          <WariningWrapper status={status}>
            Usuwasz projekt, który ma wpisane godziny. Jesteś pewien, bo je całkiem wywalimy?
          </WariningWrapper>
        )}
      </Transition>
      <ButtonsWrapper status="entered">
        <ModalButton onClick={closeModal}>Anuluj</ModalButton>
        <ModalButton onClick={submitForm} isConfirm>
          Zapisz
        </ModalButton>
      </ButtonsWrapper>
    </StyledForm>
  );
};
export default SelectPhasesModalFormContent;

import colors from 'modules/shared/styles/colors';
import styled from 'styled-components';

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40px;
  background: ${colors.grayLighter};
  border-radius: 15px;
  margin-top: 32px;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
`;

export const NavigationButton = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
  z-index: 20;
  margin: 5px 0px;

  :first-child {
    margin-top: 0px;
  }

  :last-child {
    margin-bottom: 0px;
  }

  > svg {
    transition: transform 0.4s ease;
  }
  &:hover {
    > svg {
      transform: scale(1.2);
    }
  }
`;

export const LogoutButton = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 15px;
  margin-top: auto;
  margin-bottom: 18px;
  cursor: pointer;
  background: ${colors.grayLighter};
  transition: background 0.2s ease-in;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.006);
  &:hover {
    background: ${colors.purple80};
  }
`;

export const AnimatedBackground = styled.div<{ position: number }>`
  position: absolute;
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  background: ${colors.purple80};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.006);
  top: ${p => p.position}px;
  transition: top 0.5s ease;
`;

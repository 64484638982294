import colors from 'modules/shared/styles/colors';
import { styledScrollbar } from 'modules/shared/styles/mixins';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
		margin: 0;
		background-color: ${colors.gray10};
    ${styledScrollbar()}
  }

  .MuiPickersModal-dialogRoot {
      background:none !important;
    }
  .MuiDialogContent-root {
    .MuiPickersToolbar-toolbar {
      background-color: ${colors.purple90} !important;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  .MuiPickersDay-current, .MuiButton-label {
    color: ${colors.purple90} !important;
  }
  .MuiPickersDay-daySelected {
    color: white !important;
  }
  .MuiIconButton-root:hover:not(.MuiPickersDay-daySelected) {
    background-color: rgba(255, 38, 140, 0.1) !important;
  }
  .MuiPickersDay-daySelected {
    background-color: ${colors.purple90} !important;
  }

  .MuiPickersYearSelection-container {
    ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .MuiPickersYear-root {
    color: white;
  }

  .MuiPickersYearSelection-container .MuiPickersYear-root {
    color:${colors.purple90} !important;
  }
  ${styledScrollbar()}
  }

  .MuiIconButton-root:hover:not(.MuiPickersDay-daySelected) {
    background-color:${colors.purple60} !important
  }

  .MuiTypography-colorInherit, .MuiPickersCalendarHeader-dayLabel {
    color: rgba(255, 255, 255, 0.95) !important;
  }

  .MuiPickersSlideTransition-transitionContainer > * {
    color: rgba(255, 255, 255, 0.95) !important;
  }
  .MuiDialogActions-root {
    background:${colors.gray20};
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .MuiPickersBasePicker-pickerView {
    background:${colors.gray20};
  }

  .MuiPickersCalendarHeader-iconButton {
    background:none !important;
  }
  .MuiPickersCalendarHeader-iconButton > span { 
   color: ${colors.purple90};
  }

  .MuiPickersMonth-root {
     color:${colors.white}
  }

 .MuiPickersMonth-monthSelected {
   color:  ${colors.purple90} !important;
 }

 .MuiPickersYear-yearSelected {
   color:  ${colors.purple90} !important;
 }
`;

export default GlobalStyle;

import { format } from 'date-fns';

export const getShortDayName = (dayIndex: number) => {
  switch (dayIndex) {
    case 1:
      return 'ND';
    case 2:
      return 'PN';
    case 3:
      return 'WT';
    case 4:
      return 'ŚR';
    case 5:
      return 'CZ';
    case 6:
      return 'PT';
    case 7:
      return 'SB';
    default:
      return '??';
  }
};

export const isSameDay = (firstDate: Date, secondDate: Date) => {
  const formatPattern = 'dd-MM-yyyy';
  return format(firstDate, formatPattern) === format(secondDate, formatPattern);
};

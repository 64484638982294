import React, { createContext, SetStateAction, Dispatch } from 'react';
import { ITimesheet } from 'firebase/types/Timesheets';

export interface TimesheetContextType {
  timesheetsData: ITimesheet[] | null | undefined;
  currentTimesheetData: ITimesheet[] | null | undefined;
  setIsDuringSave: Dispatch<SetStateAction<boolean>>;
}

const TimesheetContext: React.Context<TimesheetContextType> = createContext<TimesheetContextType>(
  {} as TimesheetContextType
);

export default TimesheetContext;

import colors from 'modules/shared/styles/colors';
import styled from 'styled-components';

export const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-bottom: 38px;
`;

export const ProjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: flex-start;

  @media all and (max-width: 950px) {
    width: 95%;
  }
`;

export const ProjectHeaderWrapper = styled.div`
  display: flex;
  margin-top: 38px;
  align-items: center;

  > svg {
    margin-left: 8px;
    cursor: pointer;
  }
`;

export const ProjectsHeader = styled.h3`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.2px;
  color: ${colors.white};
  align-self: flex-start;
`;

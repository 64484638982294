import React, { FunctionComponent } from 'react';
import { Transition } from 'react-transition-group';
import {
  Container,
  MODAL_TRANSITION_TIME_MS,
  ContainerProps,
  Background,
} from 'modules/shared/components/Modal/ModalContainer.components';
import { ModalContentType } from 'modules/shared/components/Modal/ModalProvider';

interface ModalContainerProps {
  isOpen: boolean;
  onBackgroundClick: (event: React.MouseEvent) => void;
  children?: ModalContentType;
  wider?: boolean;
  position: ContainerProps['position'];
}
const forceReflow = (node: HTMLElement) => node.offsetHeight;

const ModalContainer: FunctionComponent<ModalContainerProps> = ({
  children,
  isOpen,
  position,
  onBackgroundClick,
  wider,
}) => {
  return (
    <Transition
      timeout={MODAL_TRANSITION_TIME_MS}
      mountOnEnter={true}
      unmountOnExit={true}
      appear={true}
      in={isOpen}
      onEnter={forceReflow}
    >
      {(status) => (
        <>
          <Background status={status} onClick={onBackgroundClick} />
          <Container status={status} onClick={onBackgroundClick} position={position} wider={wider}>
            {children && <children.type {...children.props} status={status} />}
          </Container>
        </>
      )}
    </Transition>
  );
};

export default ModalContainer;

import React, { FunctionComponent, useState, useEffect } from 'react';
import useProjectContext from 'modules/App/AuthedLayout/ProjectContext/useProjectContext';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import {
  Filter,
  FiltersWrapper,
  NoteContent,
  NoteDetailsWrapper,
  NoteWrapper,
  Separator,
  SingleProjectNotesContainer,
  SingleProjectPhaseContainer,
  SingleProjectPhaseWrapper,
} from 'modules/SingleProject/SingleProjectNotes/SingleProjectNotes.components';
import { ProjectPhasesHeaderWrapper } from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhases.components';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { Transition } from 'react-transition-group';
import { minutesToHourString } from 'common/helpers/minutesToHourString';

type ProjectPhase = {
  phaseId: string;
  name: string;
};

type Notes = {
  phaseId: string;
  phaseName: string;
  date: Date;
  user: string;
  note: {
    text: string;
    minutes: number | undefined;
  };
};

const SingleProjectNotes: FunctionComponent = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { users } = useUserContext();
  const { allPhases } = useProjectContext();

  const [phases, setPhases] = useState<ProjectPhase[]>([]);

  const [notes, setNotes] = useState<Notes[]>([]);
  const [filter, setFilter] = useState<Notes[]>();
  const [currentPhase, setCurrentPhase] = useState<string>();

  useEffect(() => {
    const currentProjectPhases = allPhases?.filter(p => p.projectId === projectId) ?? [];

    const projectPhases = currentProjectPhases
      ?.sort((a, b) => b.startDate - a.startDate)
      .map(phase => ({ phaseId: phase.phaseId!, name: phase.name }));
    setPhases(projectPhases);

    const projectNotes = currentProjectPhases
      .flatMap(phase =>
        Object.keys(phase.notes ?? [])
          .map(key => ({ key, notes: phase.notes![key] }))
          .flatMap(phaseNotes => {
            const { key, notes } = phaseNotes;
            const [year, month] = key.split('-');
            return notes.map(note => ({
              phaseId: phase.phaseId!,
              phaseName: phase.name,
              date: new Date(`${year}-${Number(month) + 1}-${note.day}`),
              user: users?.find(u => u.uid === note.userId)?.displayName.split(' ')[0]!,
              note: {
                text: note.note,
                minutes: note.value,
              },
            }));
          })
          .filter(n => n.note.text),
      )
      .sort((a, b) => b.date.getTime() - a.date.getTime());

    setNotes(projectNotes);
    setFilter(projectNotes);
  }, [allPhases, projectId, users]);

  return (
    <SingleProjectNotesContainer>
      <ProjectPhasesHeaderWrapper style={{ marginBottom: 28 }}>Zgłoszenia w projekcie</ProjectPhasesHeaderWrapper>
      <FiltersWrapper>
        <Filter
          onClick={() => {
            setCurrentPhase(undefined);
            setFilter(notes);
          }}
          active={currentPhase === undefined}
        >
          All
        </Filter>
        {phases.map(({ name, phaseId }) => (
          <Filter
            key={phaseId}
            onClick={() => {
              const filter = notes.filter(note => note.phaseId === phaseId);
              setFilter(filter);
              setCurrentPhase(phaseId);
            }}
            active={currentPhase === phaseId}
          >
            {name}
          </Filter>
        ))}
      </FiltersWrapper>
      <SingleProjectPhaseContainer>
        {filter?.map((n, index) => (
          <Transition
            key={`${n.phaseId}-${index}`}
            timeout={200}
            mountOnEnter={true}
            unmountOnExit={true}
            appear={true}
            in={currentPhase ? currentPhase === n.phaseId : true}
          >
            {status => (
              <SingleProjectPhaseWrapper key={n.phaseName} status={status}>
                <NoteWrapper key={`${n.phaseId}-${index}`} status={status} timeout={index}>
                  <NoteDetailsWrapper>
                    <span>{n.user}</span>
                    <Separator />
                    <span>
                      {n.date.getDate()} {format(n.date, 'MMM', { locale: pl })} {n.date.getFullYear()}
                    </span>
                    <Separator />
                    {n.note.minutes && n.note.minutes > 0 ? <span>{minutesToHourString(n.note.minutes)}</span> : '00:00'}
                  </NoteDetailsWrapper>
                  <NoteContent>{n.note.text}</NoteContent>
                </NoteWrapper>
              </SingleProjectPhaseWrapper>
            )}
          </Transition>
        ))}
      </SingleProjectPhaseContainer>
    </SingleProjectNotesContainer>
  );
};

export default SingleProjectNotes;

import colors from 'modules/shared/styles/colors';
import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

type TransitionProps = {
  status: TransitionStatus;
};

export const NoteContainer = styled.div<TransitionProps>`
  position: absolute;
  right: -23px;
  transition: transform 300ms ease, opacity 200ms ease;
  opacity: ${props => (props.status === 'entered' ? '1' : '0')};
  transform: scale(${props => (props.status === 'entered' ? '1' : '0')});
  cursor: pointer;
  z-index: 1;
  &:hover {
    transform: scale(1.05);
  }
`;

type IconWrapperProps = {
  inputOpened: boolean;
};

export const NoteIconWrapper = styled.div<IconWrapperProps>`
  position: absolute;
  transition: top 0.5s ease;
  top: ${p => (p.inputOpened ? '-50px' : '-32px')};
  right: 0px;
  > svg {
    z-index: 100;
  }
`;

export const NoteButtonsWrapper = styled.div`
  position: absolute;
`;

export const StyledTextarea = styled(TextareaAutosize)`
  width: 264px;
  margin-top: 1px;
  min-height: 40px;
  background: rgba(149, 208, 206, 0.2);
  border: 1px solid #95d0ce;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(50, 50, 71, 0.05);
  border-radius: 10px;
  color: ${colors.white};
  padding: 8px 24px 8px 16px;
  resize: none;
  z-index: 200;
  :focus {
    outline: none;
  }
`;

export const FormWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  > :last-child {
    margin-left: 8px;
  }
`;

export const NoteButton = styled.button<{ greenBg?: boolean }>`
  left: 0px;
  top: 0px;
  background: #2c2c2e;
  border-radius: 5px;
  border: none;
  padding: 6px 16px;
  color: ${colors.white};
  cursor: pointer;
  background: ${p => (p.greenBg ? '#3C8683' : colors.gray20)};
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const FormikWrapper = styled.div<TransitionProps>`
  position: absolute;
  top: 0px;
  left: 405px;
  display: flex;
  flex-direction: column;
  transition: transform 300ms ease, opacity 200ms ease;
  opacity: ${props => (props.status === 'entered' ? '1' : '0')};
  transform: scale(${props => (props.status === 'entered' ? '1' : '0')});
  @media all and (max-width: 800px) {
    left: 325px;
  }
`;

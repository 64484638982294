import * as Yup from 'yup';

export interface AddProjectModalFormValues {
  name: string;
  client: string;
  icon: string;
}

export const addProjectModalInitialValues: AddProjectModalFormValues = {
  name: '',
  client: '',
  icon: '',
};

export const addProjectModalValidationSchema = Yup.object().shape({
  name: Yup.string().required('Nazwa projektu jest wymagana'),
  client: Yup.string().required('Nazwa klienta jest wymagana'),
  icon: Yup.string(),
});

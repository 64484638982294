import styled from 'styled-components';
import { TransitionStatus } from 'react-transition-group';
import colors from 'modules/shared/styles/colors';
import { font12, styledScrollbar } from 'modules/shared/styles/mixins';

type TransitionProps = {
  status: TransitionStatus;
};

export const SelectPhasesModalFormWrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 32px 48px 40px 48px;
`;

export const PhasesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${colors.gray10};
  border: 1px solid ${colors.gray40};
  border-radius: 10px;
  padding-bottom: 8px;
  padding-top: 8px;
  height: 62vh;
`;

export const SelectedPhasesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  width: 90%;
  border-top: 1px solid ${colors.gray20};
  padding-top: 8px;
  margin-top: auto;
`;

export const SelectedPhase = styled.div<TransitionProps>`
  background: ${colors.purple90};
  border-radius: 5px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  ${font12()}
  color: rgba(255, 255, 255, 0.8);
  margin-left: 4px;
  margin-bottom: 8px;

  transition: transform 300ms ease, opacity 200ms ease;
  opacity: ${(props) => (props.status === 'entered' ? '1' : '0')};
  transform: scale(${(props) => (props.status === 'entered' ? '1' : '0')});

  > svg {
    margin-left: 2px;
    cursor: pointer;
  }
`;

export const PhasesListWrapper = styled.div`
  overflow-y: scroll;
  ${styledScrollbar()}
`;

export const FilterInput = styled.input`
  background: ${colors.gray10};
  padding: 10px 16px;
  border-radius: 10px;
  margin-bottom: 8px;
  border: 1px solid ${colors.gray40};
  transition: border 0.5s ease;

  :focus {
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }

  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.002em;
  color: rgba(255, 255, 255, 0.8);
`;

export const WariningWrapper = styled.div<{ status: TransitionStatus }>`
  background: linear-gradient(90.08deg, #f46457 0.03%, #e64d69 100%);
  border-radius: 10px;
  margin-top: 8px;
  font-size: 13px;
  line-height: 160%;
  letter-spacing: -0.2px;
  color: rgba(255, 255, 255, 0.95);
  padding: 10px 11px 10px 16px;

  transition: transform 0.5s ease;
  transform: scale(${(props) => (props.status === 'entered' ? '1' : '0')});
`;

import { getDayOfYear } from 'date-fns';
import firebase from 'firebase/app';
import { getWorkingDaysBetween } from 'helpers/getWorkingDaysBetween';

export const addHolidays = async (
  userId: string,
  year: number,
  start: Date,
  end: Date,
  batch: firebase.firestore.WriteBatch,
  defaultTotal?: number,
) => {
  const ref = firebase.firestore().collection('users').doc(userId).collection('holidays').doc(year.toString());

  const yearDoc = await ref.get();

  const holidayCount = getWorkingDaysBetween(start, end);
  const newHolidayDoc = ref.collection('days').doc();

  batch.set(newHolidayDoc, {
    count: holidayCount,
    end: getDayOfYear(end),
    id: newHolidayDoc.id,
    start: getDayOfYear(start),
  });

  if (yearDoc.exists) {
    batch.set(ref, { taken: firebase.firestore.FieldValue.increment(holidayCount) }, { merge: true });
  } else {
    batch.set(ref, { taken: firebase.firestore.FieldValue.increment(holidayCount), total: defaultTotal }, { merge: true });
  }
};

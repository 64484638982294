import colors from 'modules/shared/styles/colors';
import { font12, font14, styledScrollbar } from 'modules/shared/styles/mixins';
import styled from 'styled-components';

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

export const NotesWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  padding-top: 26px;
  padding-left: 6px;
  padding-right: 4px;
  height: calc(100vh - 175px);
  overflow-y: scroll;
  ${styledScrollbar(colors.gray20)}
`;

export const PhaseNotesContainer = styled.div`
  margin-bottom: 14px;

  > h4 {
    max-width: 268px;
    margin: 0;
    padding: 0;
    ${font14()}
    color: rgba(255, 255, 255, 0.95);
    margin-left: 38px;
  }
`;

export const PhaseSingleNote = styled.div`
  display: flex;
  margin-top: 8px;
  margin: 8px 12px 0px 12px;
`;

export const PhaseNoteIndicator = styled.div`
  min-width: 4px;
  margin-right: 12px;
  border-radius: 2px;
  background-color: #8ccbd9;
`;

export const PhaseNotesWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media all and (max-width: 950px) {
    flex-direction: column;
  }

  > svg {
    transition: transform 0.4s ease;
    margin-left: 6px;
    cursor: pointer;

    :hover {
      transform: scale(1.2);
    }
  }
`;

export const PhaseNote = styled.div`
  margin-right: 12px;
  width: 100%;
  background: ${colors.gray30};
  border-radius: 10px;
  padding: 8px 12px;
  ${font12()};
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  transition: transform 0.2s ease;

  :hover {
    transform: scale(1.02);
  }

  p {
    margin: 0px;
    :first-child {
      text-decoration: underline;
    }
  }

  @media all and (max-width: 950px) {
    align-self: flex-start;
    margin: 0px;
    box-sizing: border-box;
  }
`;

import colors from 'modules/shared/styles/colors';
import styled from 'styled-components';

export const SidebarContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  min-width: 80px;
  max-width: 80px;
  height: 100vh;
  background-color: ${colors.gray20};
  z-index: 200;
  border-right: 2px solid ${colors.gray10};
  align-items: center;
  @media all and (max-width: 800px) {
    min-width: 50px;
  }
`;

export const SidebarLogoWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  background: ${colors.purple90};
  border-bottom: 2px solid ${colors.gray10};
  justify-content: center;
  align-items: center;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

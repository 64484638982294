import React, { FunctionComponent, useState, useEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { minutesToHourString } from 'common/helpers/minutesToHourString';
import firebase from 'firebase/app';
import { ITimesheets } from 'firebase/types/Timesheets';
import useProjectContext from 'modules/App/AuthedLayout/ProjectContext/useProjectContext';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import { ProjectBarImage } from 'modules/Home/HoursSection/HoursList/ProjectBar/ProjectBar.components';
import {
  SummaryContainer,
  SummaryWrapper,
  SummaryHeader,
  Year,
  HourSummary,
  PhaseSummaryContainer,
  SummaryProjectBarContainer,
  SummaryProjectBarWrapper,
  SelectedDateWrapper,
  Hours,
  StyledCalendar,
  NoResultsInfo,
  NoResultsWrapper,
  SummaryWrapperContent,
} from 'modules/Users/Summary/Summary.components';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Transition } from 'react-transition-group';
import DateFnsUtils from '@date-io/date-fns';
import { pl } from 'date-fns/locale';
import { format } from 'date-fns';
import Lottie from 'react-lottie';
import Garbage from 'modules/Users/Summary/garbage.json';
import { ReactComponent as CalendarBig } from 'modules/Users/Summary/calendarBig.svg';
import CustomTooltip from 'modules/shared/components/Tooltip/Tooltip';

type Props = {
  selectedUserId: string;
};

interface ISummary {
  phaseId: string;
  fullName: string;
  icon: string;
  value: number;
}

const Summary: FunctionComponent<Props> = ({ selectedUserId }) => {
  const { selectedDate, setSelectedDate } = useUserContext();
  const { projects, allPhases } = useProjectContext();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [userTimesheets, setUserTimesheets] = useState<ISummary[]>([]);
  const [noResults, setNoResults] = useState(false);
  const [values, loading, error] = useDocumentData<ITimesheets>(
    firebase.firestore().collection(`users/${selectedUserId}/timesheets`).doc(`${selectedDate.getFullYear()}-${selectedDate.getMonth()}`),
  );

  useEffect(() => {
    setUserTimesheets([]);
    setNoResults(false);

    if (!loading && !values) setNoResults(true);
    if (!loading && values) {
      let userTimesheets: ISummary[] = [];
      for (let day of values.days) {
        if (day.value > 0) {
          const elementIndex = userTimesheets.findIndex(timesheet => timesheet.phaseId === day.phaseId);
          if (elementIndex < 0) {
            const phase = allPhases?.find(p => p.phaseId === day.phaseId);
            const project = projects?.find(p => p.projectId === phase?.projectId);
            userTimesheets.push({
              fullName: project?.name ?? '',
              value: day.value,
              icon: project?.icon!,
              phaseId: day.phaseId,
            });
          } else userTimesheets[elementIndex].value += day.value;
        }
      }
      if (userTimesheets.length === 0 || error) setNoResults(true);
      setTimeout(() => setUserTimesheets(userTimesheets.sort((a, b) => a.fullName.localeCompare(b.fullName))));
    }
  }, [values, loading, allPhases, projects, error]);

  return (
    <SummaryContainer>
      <SummaryWrapper>
        <SummaryWrapperContent>
          <SummaryHeader>
            <MuiPickersUtilsProvider locale={pl} utils={DateFnsUtils}>
              <StyledCalendar
                views={['month', 'year']}
                open={calendarOpen}
                onClose={() => setCalendarOpen(false)}
                name="startDate"
                value={selectedDate}
                format="dd.MM.yyyy"
                onChange={val => {
                  setSelectedDate(val!);
                  setCalendarOpen(false);
                }}
              />
            </MuiPickersUtilsProvider>

            <SelectedDateWrapper onClick={() => setCalendarOpen(true)}>
              <CalendarBig />
              {format(selectedDate, 'LLLL', { locale: pl })}
              <Year>{selectedDate.getFullYear()}</Year>
            </SelectedDateWrapper>
            <HourSummary>
              {minutesToHourString(
                userTimesheets.map(u => u.value).reduce((acc, val) => acc + val, 0),
                true,
              )}
              h
            </HourSummary>
          </SummaryHeader>
          <PhaseSummaryContainer>
            {loading ? (
              <></>
            ) : noResults ? (
              <NoResultsWrapper>
                <Lottie options={{ animationData: Garbage, loop: true }} height={300} width={300} />
                <NoResultsInfo>
                  Godzin ni ma, ale za to jest wesoły śmietnik <strong>Krystian</strong>
                </NoResultsInfo>
              </NoResultsWrapper>
            ) : (
              userTimesheets.map((timesheet, index) => {
                return (
                  <Transition
                    key={timesheet.phaseId}
                    timeout={index * 100}
                    mountOnEnter={true}
                    unmountOnExit={true}
                    appear={true}
                    in={true}
                  >
                    {status => (
                      <SummaryProjectBarContainer status={status}>
                        <SummaryProjectBarWrapper>
                          <ProjectBarImage src={timesheet.icon} alt="project-image" />
                          <CustomTooltip title={timesheet.fullName} />
                          <Hours>{minutesToHourString(timesheet.value, true)}h</Hours>
                        </SummaryProjectBarWrapper>
                      </SummaryProjectBarContainer>
                    )}
                  </Transition>
                );
              })
            )}
          </PhaseSummaryContainer>
        </SummaryWrapperContent>
      </SummaryWrapper>
    </SummaryContainer>
  );
};

export default Summary;

import { ITimesheet } from 'firebase/types/Timesheets';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { HourListFormValues } from '../form';
import ProjectBar from '../ProjectBar/ProjectBar';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';

interface Props {
  allTimesheets: ITimesheet[];
  getProjectDetails: (id: string) => any;
  handleChange: any;
  setFieldValue: any;
  values: HourListFormValues;
  save: any;
}

const ProjectBarsDragAndDrop: FunctionComponent<Props> = ({
  allTimesheets,
  getProjectDetails,
  values,
  handleChange,
  setFieldValue,
  save,
}) => {
  const [projectBars, setProjectBars] = useState<ITimesheet[]>(allTimesheets);
  const { selectedDate } = useUserContext();

  useEffect(() => {
    setProjectBars(allTimesheets);
  }, [allTimesheets]);

  const getPhaseId = (id: string) => `${id.split('-').at(0)}-${selectedDate.getFullYear()}-${selectedDate.getMonth()}`;

  return (
    <div>
      {projectBars.map((projectBar, index) => (
        <ProjectBar
          key={`${getPhaseId(projectBar.phaseId)}-${index}`}
          phaseId={getPhaseId(projectBar.phaseId)}
          title={getProjectDetails(projectBar.phaseId)?.name}
          image={getProjectDetails(projectBar.phaseId)?.icon || ''}
          timeout={index}
          value={values[getPhaseId(projectBar.phaseId)]}
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          note={projectBar.note || ''}
        />
      ))}
    </div>
  );
};

export default ProjectBarsDragAndDrop;

import React, { FunctionComponent, useState } from 'react';
import {
  ActionButton,
  ActionButtonsWrapper,
  Estimate,
  HoursAvailable,
  HoursUsed,
  ProjectAvatar,
  ProjectAvatarWrapper,
  ProjectContainer,
  ProjectDetailsContainer,
} from 'modules/Projects/ProjectsTable/Project/Project.components';
import { ReactComponent as Archive } from 'modules/Projects/icons/archive.svg';
import { ReactComponent as Restore } from 'modules/Projects/icons/restore.svg';
import { IProject } from 'firebase/types/Project';
import archiveProject from 'firebase/projects/archiveProject';
import { useHistory } from 'react-router-dom';
import routesPaths from 'modules/Routing/routesPaths';
import useProjectContext from 'modules/App/AuthedLayout/ProjectContext/useProjectContext';
import { minutesToHourString } from 'common/helpers/minutesToHourString';
import CustomTooltip from 'modules/shared/components/Tooltip/Tooltip';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';

type Props = {
  project: IProject;
};

const Project: FunctionComponent<Props> = ({ project }) => {
  const { allPhases } = useProjectContext();
  const [mouseOverArchive, setMouseOverArchive] = useState(false);
  const history = useHistory();
  const { isAdmin } = useUserContext();

  const currentPhases =
    allPhases &&
    allPhases
      .filter(p => p.projectId === project.projectId)
      //archived on bottom, items with index undefined on top
      .sort((a, b) => {
        if (!!a.isArchived === !!b.isArchived) {
          return a.hasOwnProperty('index') && b.hasOwnProperty('index') ? a.index! - b.index! : a.hasOwnProperty('index') ? 1 : -1;
        } else {
          return a.isArchived ? 1 : -1;
        }
      });

  const estimateSum = project.history?.estimate || currentPhases?.reduce((a, b) => a + b.originalEstimate, 0);
  const minutesUsed =
    project.history?.used ||
    currentPhases?.reduce((acc, val) => {
      if (val.details && val.details.usedMinutes) return acc + val!.details?.usedMinutes;
      else return acc + 0;
    }, 0);

  return (
    <ProjectContainer
      isClosed={project.isClosed}
      isArchived={project.isArchived || project.isClosed === true}
      onClick={() => {
        if (project.isClosed) return;
        if (!mouseOverArchive) history.push(`${routesPaths.PROJECTS}/${project.projectId}`);
      }}
    >
      <ProjectDetailsContainer>
        <ProjectAvatarWrapper>
          <ProjectAvatar src={project.icon} />
          <CustomTooltip title={`${project.name}${project.isClosed ? ' (zamknięty)' : ''}`} />
        </ProjectAvatarWrapper>
        <HoursUsed>{minutesToHourString(minutesUsed || 0, true) || 0}</HoursUsed>
        <HoursAvailable>
          {(estimateSum && minutesUsed && minutesToHourString(estimateSum * 60 - minutesUsed, true)) || estimateSum}
        </HoursAvailable>
        <Estimate>{estimateSum}</Estimate>
        <ActionButtonsWrapper onMouseOver={() => setMouseOverArchive(true)} onMouseLeave={() => setMouseOverArchive(false)}>
          {isAdmin && !project.isClosed && (
            <ActionButton onClick={async () => await archiveProject(project.projectId!, !project.isArchived)}>
              {project.isArchived ? <Restore /> : <Archive />}
            </ActionButton>
          )}
        </ActionButtonsWrapper>
      </ProjectDetailsContainer>
    </ProjectContainer>
  );
};

export default Project;

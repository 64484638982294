import colors from 'modules/shared/styles/colors';
import styled from 'styled-components';

export const CalendarSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  min-width: 364px;
  max-width: 364px;
  width: 364px;
  background: ${colors.gray20};
  border-right: 2px solid ${colors.gray10};

  @media all and (max-width: 950px) {
    min-width: 244px;
    max-width: 244px;
  }
`;

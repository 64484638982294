import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-left: 80px;
  box-sizing: border-box;

  @media all and (max-width: 800px) {
    padding-left: 50px;
  }
`;

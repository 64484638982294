import React, { FunctionComponent } from 'react';
import {
  ActiveDayButton,
  DayWrapper,
  ReminderIconWrapper,
  WeekContainer,
  WeekHeader,
} from 'modules/Home/CalendarSection/Calendar/Week/Week.components';
import { ReactComponent as ChevronRight } from 'modules/Home/CalendarSection/icons/chevronWhiteRight.svg';
import { ReactComponent as ReminderIcon } from 'modules/Home/CalendarSection/icons/reminderIcon.svg';
import { Element } from 'react-scroll';
import { format, getISOWeek, getDayOfYear } from 'date-fns';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import { isSameDay } from 'modules/Home/CalendarSection/Calendar/Calendar.helpers';
import useTimesheetContext from 'modules/App/AuthedLayout/TimesheetContext/useTimesheetContext';
import { minutesToHourString } from 'common/helpers/minutesToHourString';
import showNotification from 'common/helpers/showNotification';
import AppEventTarget from 'common/events/AppEventTarget';
import CalendarTabChangeEvent from 'common/events/CalendarTabChangeEvent';
import { ReactComponent as HolidaysIcon } from 'modules/App/AuthedLayout/SideMenu/icons/holidays.svg';
import { Holidays } from 'firebase/types/Holidays';
import { isFreeDay } from 'helpers/isFreeDay';

type WeekProps = {
  weekIndex: number;
  days: { shortName: string; date: Date; dirtyDate: Date }[];
};

const isHolidayDay = (day: number, holidays?: Holidays) => {
  return holidays?.days?.find(days => day >= days.start && day <= days.end);
};

const showHolidayIcon = (date: Date, holidays?: Holidays) => {
  const isVacationDay = isHolidayDay(getDayOfYear(date), holidays);
  return isVacationDay ? !isFreeDay(date) : false;
};

const Week: FunctionComponent<WeekProps> = ({ weekIndex, days }) => {
  const { selectedDate, setSelectedDate, skippedNotifications, holidaysData } = useUserContext();
  const { timesheetsData } = useTimesheetContext();

  const getHoursInDay = (day: Date) =>
    timesheetsData?.filter(timesheet => timesheet.day === day.getDate()).reduce((acc, val) => acc + val.value, 0);

  return (
    <WeekContainer>
      <WeekHeader isFirst={weekIndex === 1}>Tydzień {getISOWeek(days[0].dirtyDate)}</WeekHeader>
      {days.map((day, index) => (
        <Element
          key={index}
          name={format(day.dirtyDate, 'dd-MM-yyyy')}
          onClick={() => {
            AppEventTarget.dispatchEvent(new CalendarTabChangeEvent());
            setTimeout(() => setSelectedDate(day.dirtyDate), 100);
          }}
        >
          <DayWrapper
            isActive={isSameDay(selectedDate, day.dirtyDate)}
            isToday={isSameDay(new Date(), day.dirtyDate)}
            isDayOff={isFreeDay(day.dirtyDate)}
          >
            <ReminderIconWrapper>
              {showHolidayIcon(day.dirtyDate, holidaysData) ? (
                <HolidaysIcon />
              ) : (
                showNotification(day.dirtyDate, skippedNotifications, timesheetsData || []) && <ReminderIcon />
              )}
            </ReminderIconWrapper>
            <span>{day.shortName}</span>
            <span>{day.date.toString()}</span>
            <div>
              <span>{minutesToHourString(getHoursInDay(day.dirtyDate), true)}h</span>
              <ActiveDayButton>
                <ChevronRight />
              </ActiveDayButton>
            </div>
          </DayWrapper>
        </Element>
      ))}
    </WeekContainer>
  );
};

export default Week;

import React, { FunctionComponent } from 'react';

import { SidebarContainer, SidebarLogoWrapper } from 'modules/shared/components/Sidebar/Sidebar.components';
import { ReactComponent as Logo } from 'modules/shared/components/Sidebar/LogoSingle.svg';
const Sidebar: FunctionComponent = ({ children }) => {
  return (
    <SidebarContainer>
      <SidebarLogoWrapper>
        <Logo />
      </SidebarLogoWrapper>
      {children}
    </SidebarContainer>
  );
};

export default Sidebar;

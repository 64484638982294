import React, { useState, useEffect, FunctionComponent } from 'react';
import AppEventTarget from 'common/events/AppEventTarget';
import CalendarTabChangeEvent from 'common/events/CalendarTabChangeEvent';
import { startOfMonth, subMonths, addMonths, format } from 'date-fns';
import { pl } from 'date-fns/locale';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import {
  CalendarHeaderContainer,
  MonthPickerWrapper,
  SelectedMonthWrapper,
  CurrentDayButton,
} from 'modules/Home/CalendarSection/Calendar/Calendar.components';
import {
  NotesContainer,
  NotesWrapper,
  PhaseNote,
  PhaseNoteIndicator,
  PhaseNotesContainer,
  PhaseNotesWrapper,
  PhaseSingleNote,
} from 'modules/Home/CalendarSection/Notes/Notes.components';
import { ReactComponent as CalendarIcon } from 'modules/Home/CalendarSection/icons/calendar.svg';
import { ReactComponent as ChevronLeft } from 'modules/Home/CalendarSection/icons/chevronLeft.svg';
import { ReactComponent as ChevronRight } from 'modules/Home/CalendarSection/icons/chevronRight.svg';
// import { ReactComponent as CrossIcon } from 'modules/Home/HoursSection/HoursList/icons/crossIcon.svg';
import useTimesheetContext from 'modules/App/AuthedLayout/TimesheetContext/useTimesheetContext';
import useProjectContext from 'modules/App/AuthedLayout/ProjectContext/useProjectContext';
import { minutesToHourString } from 'common/helpers/minutesToHourString';
// import removeNote from 'firebase/timesheet/removeNote';

interface ICalendarNotes {
  notes: {
    phaseId: string;
    fullName: string;
    data: {
      day: number;
      note: string;
      minutes: number;
    }[];
  }[];
}

const Notes: FunctionComponent = () => {
  const { selectedDate, setSelectedDate } = useUserContext();
  const { timesheetsData } = useTimesheetContext();
  const { projects, allPhases } = useProjectContext();
  const [notes, setNotes] = useState<ICalendarNotes>();

  useEffect(() => {
    let notesObj: ICalendarNotes = { notes: [] };

    if (timesheetsData) {
      for (let t of timesheetsData.filter(t => t.note !== '' && t.note !== undefined)) {
        if (!notesObj.notes.find(n => n.phaseId === t.phaseId)) {
          const phase = allPhases?.find(p => p.phaseId === t.phaseId);
          const project = projects?.find(p => p.projectId === phase?.projectId);

          notesObj.notes.push({
            fullName: `${project?.name} ${phase?.name}`,
            phaseId: t.phaseId,
            data: [
              {
                day: t.day,
                note: t.note!,
                minutes: t.value,
              },
            ],
          });
        } else {
          const existingNotes = notesObj.notes.find(n => n.phaseId === t.phaseId);
          existingNotes?.data.push({
            day: t.day,
            note: t.note!,
            minutes: t.value,
          });
        }
      }

      notesObj.notes = notesObj.notes.sort((a, b) => a.fullName.localeCompare(b.fullName));
      setNotes(notesObj);
    } else {
      setNotes({ notes: [] });
    }
  }, [timesheetsData, setNotes, allPhases, projects]);

  const goToNote = (day: number) => {
    setSelectedDate(new Date(`${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${day}`));
  };

  return (
    <NotesContainer>
      <CalendarHeaderContainer>
        <MonthPickerWrapper>
          <ChevronLeft
            onClick={() => {
              AppEventTarget.dispatchEvent(new CalendarTabChangeEvent());
              setTimeout(() => setSelectedDate(startOfMonth(subMonths(selectedDate, 1))), 100);
            }}
          />
          <ChevronRight
            onClick={() => {
              AppEventTarget.dispatchEvent(new CalendarTabChangeEvent());
              setTimeout(() => setSelectedDate(startOfMonth(addMonths(selectedDate, 1))), 100);
            }}
          />
        </MonthPickerWrapper>
        <SelectedMonthWrapper>
          <span>{format(selectedDate, 'LLLL', { locale: pl })}</span>
          <CurrentDayButton
            onClick={() => {
              AppEventTarget.dispatchEvent(new CalendarTabChangeEvent());
              setTimeout(() => setSelectedDate(new Date()), 100);
            }}
          >
            <CalendarIcon />
            Dzisiaj
          </CurrentDayButton>
        </SelectedMonthWrapper>
      </CalendarHeaderContainer>
      <NotesWrapper>
        {notes?.notes.map(n => (
          <PhaseNotesContainer key={n.phaseId}>
            <h4>{n.fullName}</h4>
            {n.data
              .sort((a, b) => a.day - b.day)
              .map(d => (
                <PhaseSingleNote>
                  <PhaseNoteIndicator />
                  <PhaseNotesWrapper key={`${n.phaseId}-${d.day}`}>
                    <PhaseNote onClick={() => goToNote(d.day)}>
                      <p>{minutesToHourString(d.minutes)}</p>
                      <p>{d.note}</p>
                    </PhaseNote>
                  </PhaseNotesWrapper>
                </PhaseSingleNote>
              ))}
          </PhaseNotesContainer>
        ))}
      </NotesWrapper>
    </NotesContainer>
  );
};

export default Notes;

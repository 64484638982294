import firebase from 'firebase/app';

const addProject = async (name: string, client: string, icon: string) => {
  await firebase.firestore().collection('projects').add({
    name,
    client,
    icon,
    isArchived: false,
  });
};

export default addProject;

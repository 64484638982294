import React, { FunctionComponent, useEffect, useState } from 'react';
import SingleProjectPhase from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhase/SingleProjectPhase';
import { ProjectPhasesHeaderWrapper } from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhases.components';
import {
  SingleProjectPhasesTableContainer,
  SingleProjectPhasesTableHeaderWrapper,
  SingleProjectPhasesTitle,
} from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhasesTable/SingleProjectPhasesTable.components';
import { useParams } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { AddSinglePhaseWrapper } from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhase/SingleProjectPhase.components';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import useProjectContext from 'modules/App/AuthedLayout/ProjectContext/useProjectContext';
import SingleProjectPhasesDragAndDrop from '../SingleProjectPhasesDragAndDrop/SingleProjectPhasesDragAndDrop';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { IProject } from 'firebase/types/Project';
import firebase from 'firebase/app';

const headerColumns = ['Miesiąc', 'Spalone', 'Dostępne', 'Wycena'];

interface SingleProjectPhasesTableProps {
  archived?: boolean;
}

const SingleProjectPhasesTable: FunctionComponent<SingleProjectPhasesTableProps> = ({ archived }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { allPhases } = useProjectContext();
  const { isAdmin } = useUserContext();
  const [value] = useDocumentData<IProject>(firebase.firestore().collection('projects').doc(projectId));

  const [addPhaseEnabled, setAddPhaseEnabled] = useState(false);

  useEffect(() => {
    if (!allPhases?.filter(p => p.projectId === projectId)) setAddPhaseEnabled(true);
    else setAddPhaseEnabled(false);
  }, [allPhases, projectId]);

  return (
    <>
      <ProjectPhasesHeaderWrapper>
        {value?.name && <SingleProjectPhasesTitle>{value?.name + ' '}</SingleProjectPhasesTitle>}
        {archived ? 'Archiwum' : 'Fazy Projektowe'}
      </ProjectPhasesHeaderWrapper>
      <SingleProjectPhasesTableContainer id="phasesTable">
        <SingleProjectPhasesTableHeaderWrapper>
          {headerColumns.map((column, index) => (
            <span key={index}>{column}</span>
          ))}
        </SingleProjectPhasesTableHeaderWrapper>
        <Transition timeout={50} mountOnEnter={false} unmountOnExit={false} in={addPhaseEnabled}>
          {status => (
            <AddSinglePhaseWrapper status={status}>
              <SingleProjectPhase addSinglePhase setAddPhaseEnabled={setAddPhaseEnabled} />
            </AddSinglePhaseWrapper>
          )}
        </Transition>
        {allPhases &&
          //display Drag And Drop only for admins
          (isAdmin ? (
            <SingleProjectPhasesDragAndDrop
              allPhases={allPhases.filter(phase => phase.projectId === projectId && (archived ? phase.isArchived : !phase.isArchived))}
              projectId={projectId}
            />
          ) : (
            allPhases
              .filter(phase => phase.projectId === projectId && (archived ? phase.isArchived : !phase.isArchived))
              .map((el, i) => ({ ...el, index: el.index !== undefined ? el.index : i }))
              .sort((a, b) => b.startDate - a.startDate)
              .map(phase => <SingleProjectPhase key={phase.phaseId} phase={phase} />)
          ))}
      </SingleProjectPhasesTableContainer>
    </>
  );
};

export default SingleProjectPhasesTable;

import { auth } from 'config/firebase';
import firebase from 'firebase/app';

const createUser = async () => {
  const displayName = auth.currentUser?.displayName;
  const existingUser = await firebase.firestore().collection('users').where('displayName', '==', displayName).get();

  if (!existingUser.docs.length) {
    const uid = auth.currentUser!.uid;
    await firebase
      .firestore()
      .collection(`users`)
      .doc(uid)
      .set({ displayName, email: auth.currentUser?.email, role: 'STANDARD' });
  }
};

export default createUser;

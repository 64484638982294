import React, { Dispatch, FunctionComponent, SetStateAction, useEffect } from 'react';
import { FormikProps } from 'formik';
import { AddProjectModalFormValues } from 'modules/shared/components/Modals/AddProjectModal/form';
import { FormGroup } from 'modules/shared/components/Form/FormGroup';
import Label from 'modules/shared/components/Form/Input/Label';
import InputField from 'modules/shared/components/Form/Input/InputField';
import { ButtonsWrapper, ModalButton, StyledForm } from 'modules/shared/components/Modals/AddProjectModal/AddProjectModal.components';
import Dropzone from 'modules/shared/components/Form/Dropzone/Dropzone';
import { EditSingleProjectIconWrapper } from 'modules/SingleProject/EditSingleProject/EditSingleProject.components';
import { Transition } from 'react-transition-group';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';

type Props = FormikProps<AddProjectModalFormValues> & {
  formDisabled: boolean;
  setFormDisabled: Dispatch<SetStateAction<boolean>>;
  message?: string;
};

const EditSingleProjectFormContent: FunctionComponent<Props> = ({
  isSubmitting,
  isValid,
  submitCount,
  values,
  message,
  setFieldValue,
  setFieldTouched,
  submitForm,
  formDisabled,
  resetForm,
  setFormDisabled,
  ...props
}) => {
  const { isAdmin } = useUserContext();

  useEffect(() => {
    if (formDisabled) resetForm();
  }, [formDisabled, resetForm]);

  const unlockFormWithFocus = (name: string) => {
    if (isAdmin) {
      setFormDisabled(false);
      setTimeout(() => document.getElementById(name)?.focus(), 100);
    }
  };

  return (
    <StyledForm>
      <EditSingleProjectIconWrapper onClick={() => unlockFormWithFocus('name')}>
        <Dropzone setFieldValue={setFieldValue} placeholderBase64={values.icon} smallerSize disabled={formDisabled} />
      </EditSingleProjectIconWrapper>
      <FormGroup style={{ marginTop: 40 }} onClick={() => unlockFormWithFocus('name')}>
        <Label>Nazwa projektu</Label>
        <InputField name="name" sizeType="unset" isDisabled={formDisabled} />
      </FormGroup>
      <FormGroup onClick={() => unlockFormWithFocus('client')}>
        <Label>Klient</Label>
        <InputField name="client" sizeType="unset" isDisabled={formDisabled} />
      </FormGroup>
      <Transition timeout={200} mountOnEnter={true} unmountOnExit={true} appear={true} in={!formDisabled}>
        {status => (
          <ButtonsWrapper status={status} style={{ alignItems: 'flex-start', marginTop: '32px' }}>
            <ModalButton
              type="button"
              onClick={e => {
                setFormDisabled(true);
              }}
            >
              Anuluj
            </ModalButton>
            <ModalButton type="submit" isConfirm>
              Zapisz
            </ModalButton>
          </ButtonsWrapper>
        )}
      </Transition>
    </StyledForm>
  );
};
export default EditSingleProjectFormContent;

import React from 'react';
import Project from 'modules/Projects/ProjectsTable/Project/Project';
import { ProjectsTableContainer, ProjectsTableHeader, TableHeaderItem } from 'modules/Projects/ProjectsTable/ProjectsTable.components';
import useProjectContext from 'modules/App/AuthedLayout/ProjectContext/useProjectContext';

const tableHeaders = ['PROJEKT', 'SPALONE', 'DOSTĘPNE', 'WYCENA', 'AKCJE'];

const ProjectsTable = () => {
  const { projects } = useProjectContext();

  return (
    <ProjectsTableContainer>
      <ProjectsTableHeader>
        {tableHeaders.map((header, index) => (
          <TableHeaderItem key={index}>{header}</TableHeaderItem>
        ))}
      </ProjectsTableHeader>
      {projects
        ?.sort((a, b) => (a.isArchived === b.isArchived ? 0 : a.isArchived ? 1 : -1))
        ?.sort((a, b) => (a.isClosed === b.isClosed ? 0 : a.isClosed ? 1 : -1))
        .map(project => (
          <Project key={project.projectId} project={project} />
        ))}
    </ProjectsTableContainer>
  );
};

export default ProjectsTable;

import React, { FunctionComponent } from 'react';
import { BaseModalWrapperProps } from 'modules/shared/components/Modal/BaseModalWrapper';
import { BaseModalContainer, BaseModalHeader } from 'modules/shared/components/Modal/DefaultModalWrapper';
import SelectPhasesModalForm from 'modules/shared/components/Modals/SelectPhasesModal/SelectPhasesModalForm';
import { SelectPhasesModalFormWrapper } from 'modules/shared/components/Modals/SelectPhasesModal/SelectPhasesModal.components';
import useProjectContext from 'modules/App/AuthedLayout/ProjectContext/useProjectContext';
// import { isBefore, isAfter, startOfDay, endOfDay, addHours } from 'date-fns';
// import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
type Props = BaseModalWrapperProps;

const SelectPhasesModal: FunctionComponent<Props> = ({ status }) => {
  const { projects, allPhases } = useProjectContext();
  // const { selectedDate } = useUserContext();

  return (
    <BaseModalContainer wider status={status}>
      <BaseModalHeader>Edytuj listę projektów</BaseModalHeader>
      <SelectPhasesModalFormWrapper>
        <SelectPhasesModalForm
          projects={projects?.filter(p => !p.isArchived && !p.isClosed) ?? []}
          phases={allPhases!
            .map(v => {
              const project = projects?.find(project => project.projectId === v.projectId);
              return {
                fullName: `${project?.name} ${v.name}`,
                isArchived: project?.isArchived,
                ...v,
              };
            })
            .filter(p => !p.isArchived)
            .sort((a, b) => a.fullName.localeCompare(b.fullName))}

          // .filter((p) => {
          //   const startDate = startOfDay(new Date(p.startDate.seconds * 1000));
          //   const endDate = p.endDate ? endOfDay(new Date(p.endDate.seconds * 1000)) : null;
          //   // Add 2 hours to ensure that current date is always after startOfDay 00:00
          //   const currentDate = addHours(selectedDate, 2);
          //   return endDate
          //     ? isAfter(currentDate, startDate) && isBefore(currentDate, endDate)
          //     : isAfter(currentDate, startDate);
          // })
        />
      </SelectPhasesModalFormWrapper>
    </BaseModalContainer>
  );
};

export default SelectPhasesModal;

import firebase from 'firebase/app';

const addProjectPhase = async (
  projectId: string,
  name: string,
  startDate: Date,
  endDate: Date | null,
  originalEstimate: number
) => {
  await firebase.firestore().collection('stages').add({
    projectId,
    name,
    startDate,
    endDate,
    originalEstimate,
  });
};

export default addProjectPhase;

import { RouteComponentProps } from 'react-router-dom';
import { Require } from 'common/types/require';
import { flatNestedRoutes } from 'modules/Routing/helpers';
import routesPaths from 'modules/Routing/routesPaths';
import React from 'react';
import Projects from 'modules/Projects/Projects';
import Home from 'modules/Home/Home';
import SingleProject from 'modules/SingleProject/SingleProject';
import Users from 'modules/Users/Users';
import Holidays from 'modules/Holidays/Holidays';

export interface BaseRouteConfig {
  path: string;
  title: string;
  exact?: boolean;
  importComponent?: () => Promise<{
    default: React.ComponentType<RouteComponentProps<undefined>> | React.ComponentType<unknown>;
  }>;
  component?: any;
}

type RouteType = { type: 'authed' } | { type: 'unique' } | { type: 'public' };

export type RouteConfig = BaseRouteConfig & RouteType;

export type RouteConfigWithComponent = Require<BaseRouteConfig, 'importComponent'> & RouteType;

export type NestedRouteConfig = RouteConfig & {
  children?: NestedRouteConfig[];
};

const uniqueRoutes: RouteConfig[] = [
  {
    path: routesPaths.ERROR404,
    exact: true,
    title: '404',
    type: 'unique',
    importComponent: () => import('modules/Error404' /* webpackChunkName: "Error404" */),
  },
];

const publicRoutes: RouteConfig[] = [
  {
    path: routesPaths.LOGIN,
    exact: true,
    title: 'Login',
    type: 'public',
    importComponent: () => import('modules/Login' /* webpackChunkName: "Login" */),
  },
];

const authedRoutes: NestedRouteConfig[] = [
  {
    path: routesPaths.MAIN,
    title: 'Main',
    type: 'authed',
    importComponent: () => import('modules/Routing/MainAuthedRoutes' /* webpackChunkName: "Home" */),
  },
];

export const mainAuthedRoutes: RouteConfig[] = [
  {
    path: routesPaths.HOME,
    title: 'Home',
    exact: true,
    type: 'authed',
    component: Home,
  },
  {
    path: routesPaths.PROJECTS,
    title: 'Projects',
    exact: true,
    type: 'authed',
    component: Projects,
  },
  {
    path: routesPaths.PROJECT,
    title: 'Project',
    exact: true,
    type: 'authed',
    component: SingleProject,
  },
  {
    path: routesPaths.USERS,
    title: 'Users',
    exact: true,
    type: 'authed',
    component: Users,
  },
  {
    path: routesPaths.HOLIDAYS,
    title: 'Wakacje',
    exact: true,
    type: 'authed',
    component: Holidays,
  },
];

export const nestedRoutes: RouteConfig[] = [...uniqueRoutes, ...publicRoutes, ...authedRoutes];
const routes: RouteConfigWithComponent[] = flatNestedRoutes(nestedRoutes);

export default routes;

import colors from 'modules/shared/styles/colors';
import { font12, font14, font16 } from 'modules/shared/styles/mixins';
import styled, { css } from 'styled-components';

export const WeekContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type WeekHeaderProps = {
  isFirst: boolean;
};

export const WeekHeader = styled.span<WeekHeaderProps>`
  color: ${colors.white};
  opacity: 0.6;
  ${font12()}
  margin-left: 36px;
  text-transform: uppercase;
  width: 80%;

  ${p =>
    !p.isFirst &&
    css`
      border-top: 1px solid ${colors.gray30};
      padding-top: 20px;
    `}
`;

export const ActiveDayButton = styled.div`
  display: none;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  background: ${colors.purple80};
  justify-content: center;
  align-items: center;

  transition: display 0.2s ease-in;

  @media all and (max-width: 950px) {
    display: none !important;
  }
`;

type Props = {
  isActive: boolean;
  isToday: boolean;
  isDayOff: boolean;
};

export const DayWrapper = styled.div<Props>`
  position: relative;
  display: flex;
  padding: 8px 8px 8px 36px;
  border-radius: 15px;
  ${font14()}
  color: white;
  opacity: 0.8;
  margin-bottom: 12px;
  cursor: pointer;
  align-items: center;
  transition: background 0.1s ease-in;

  ${p =>
    p.isToday &&
    css`
      > span {
        font-weight: bold;
      }

      > div > span {
        font-weight: bold;
      }
    `}

  ${p =>
    p.isDayOff &&
    css`
      > span {
        opacity: 0.5;
      }

      > div > span {
        opacity: 0.5;
      }
    `}

  

  &:hover {
    background: ${colors.grayLighter};
    & ${ActiveDayButton} {
      display: flex;
    }
    > div {
      > span {
        margin-right: 20px;

        @media all and (max-width: 950px) {
          margin-right: 10px;
        }
      }
    }
  }
  > :nth-child(2) {
    width: 20px;
  }

  > :nth-child(3) {
    margin-left: 12px;
  }

  > div {
    display: flex;
    align-items: center;
    margin-left: auto;

    > span {
      font-weight: 500;
      ${font16()}
      margin-right: 40px;

      @media all and (max-width: 950px) {
        margin-right: 10px;
      }
    }
  }

  ${p =>
    p.isActive &&
    css`
      background: ${colors.grayLighter};
      & ${ActiveDayButton} {
        display: flex;
        background: rgba(255, 255, 255, 0.15);
      }
      > div {
        > span {
          margin-right: 20px;
          @media all and (max-width: 950px) {
            margin-right: 10px;
          }
        }
      }
    `}
`;

export const ReminderIconWrapper = styled.div`
  position: absolute;
  left: 8px;

  > svg {
    width: 16px;
    height: 16px;
  }
`;

import React, { FunctionComponent } from 'react';
import { FormikProps } from 'formik';
import useModal from 'modules/shared/components/Modal/useModal';
import { AddProjectModalFormValues } from 'modules/shared/components/Modals/AddProjectModal/form';
import { FormGroup } from 'modules/shared/components/Form/FormGroup';
import Label from 'modules/shared/components/Form/Input/Label';
import InputField from 'modules/shared/components/Form/Input/InputField';
import {
  AvatarInputContainer,
  AvatarInputWrapper,
  ButtonsWrapper,
  ModalButton,
  RequirementsList,
  StyledForm,
} from 'modules/shared/components/Modals/AddProjectModal/AddProjectModal.components';
import Dropzone from 'modules/shared/components/Form/Dropzone/Dropzone';

type Props = FormikProps<AddProjectModalFormValues> & {
  message?: string;
};

const AddProjectModalFormContent: FunctionComponent<Props> = ({
  isSubmitting,
  isValid,
  submitCount,
  values,
  message,
  setFieldValue,
  setFieldTouched,
  submitForm,
  ...props
}) => {
  const { closeModal } = useModal();

  return (
    <StyledForm>
      <FormGroup>
        <Label>Nazwa projektu</Label>
        <InputField name="name" sizeType="unset" />
      </FormGroup>
      <FormGroup>
        <Label>Klient</Label>
        <InputField name="client" sizeType="unset" />
      </FormGroup>
      <AvatarInputContainer>
        <AvatarInputWrapper>
          <Label>Avatar</Label>
          <Dropzone setFieldValue={setFieldValue} />
        </AvatarInputWrapper>
        <RequirementsList>
          <Label>Wymagania</Label>
          <span>grafika kwadratowa</span>
          <span>.jpg, .png, .gif</span>
        </RequirementsList>
      </AvatarInputContainer>

      <ButtonsWrapper status="entered">
        <ModalButton onClick={closeModal}>Anuluj</ModalButton>
        <ModalButton onClick={submitForm} isConfirm>
          Dodaj
        </ModalButton>
      </ButtonsWrapper>
    </StyledForm>
  );
};
export default AddProjectModalFormContent;

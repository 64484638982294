import React, { FunctionComponent, ReactElement, useCallback, useMemo, useState } from 'react';

import { ModalContext } from 'modules/shared/components/Modal';
import ModalContainer from 'modules/shared/components/Modal/ModalContainer';
import { ContainerProps } from 'modules/shared/components/Modal/ModalContainer.components';
import { ModalContextType } from 'modules/shared/components/Modal/ModalContext';

export type ModalContentType = ReactElement<ContainerProps>;

export interface ModalState {
  content?: ModalContentType;
  isOpen: boolean;
  position: 'absolute' | 'fixed';
  /**
   * onBeforeClose handler
   *
   * return true to capture event and keep modal open
   */
  wider?: boolean;
  onBeforeClose?: () => boolean | void;
}

const ModalProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useState<ModalState>({
    isOpen: false,
    position: 'absolute',
    wider: false,
  });

  const contextValue = useMemo<ModalContextType>(
    () => ({
      openModal(content, onBeforeClose) {
        setState({
          content,
          onBeforeClose,
          isOpen: true,
          position: 'absolute',
        });
      },
      openFixedModal(content, wider, onBeforeClose) {
        setState({
          content,
          wider,
          onBeforeClose,
          isOpen: true,
          position: 'fixed',
        });
      },
      closeModal() {
        setState((prevState) => ({
          ...prevState,
          isOpen: false,
        }));
      },
    }),
    []
  );

  const handleClose = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (event.target !== event.currentTarget) {
        return;
      }

      const shouldCancel = state.onBeforeClose?.();
      if (!shouldCancel) {
        contextValue.closeModal();
      }
    },
    [contextValue, state]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      <ModalContainer
        isOpen={state.isOpen}
        onBackgroundClick={handleClose}
        position={state.position}
        wider={state.wider}
      >
        {state.content}
      </ModalContainer>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

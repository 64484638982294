import styled from 'styled-components';
import { MODAL_TRANSITION_TIME_MS, ContainerProps } from 'modules/shared/components/Modal/ModalContainer.components';

export type BaseModalWrapperProps = Partial<ContainerProps>;

const BaseModalWrapper = styled.div<BaseModalWrapperProps>`
  transition: transform ${MODAL_TRANSITION_TIME_MS}ms ease-in-out;
`;

export default BaseModalWrapper;

import * as Yup from 'yup';

export interface AddSingleProjectPhaseFormValues {
  phaseName: string;
  startDate: Date;
  endDate: Date | null;
  originalEstimate: string;
}

export const addSingleProjectPhaseInitialValues: AddSingleProjectPhaseFormValues = {
  phaseName: '',
  startDate: new Date(),
  endDate: null,
  originalEstimate: '0',
};

export const addSingleProjectPhaseValidationSchema = Yup.object().shape({
  phaseName: Yup.string(),
  startDate: Yup.date(),
});

export const minutesToHourString = (value?: number, ignoreHourPrefix?: boolean) => {
  if (value) {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    let parsedHours = '';
    parsedHours = hours.toString();
    if (hours === 0) parsedHours = '00';
    if (hours.toString().length === 1 && !ignoreHourPrefix) parsedHours = `0${hours}`;

    let parsedMinutes = '';
    parsedMinutes = minutes.toString();
    if (minutes === 0) parsedMinutes = '00';
    if (minutes.toString().length === 1) parsedMinutes = `0${minutes}`;

    if (value < 0)
      return parsedMinutes !== '00'
        ? '-' + `${parsedHours}:${parsedMinutes}`.split('-').join('')
        : '-' + `${parsedHours}`.split('-').join('');

    if (parsedMinutes === '00' && ignoreHourPrefix) return `${parsedHours}`;

    return `${parsedHours}:${parsedMinutes}`;
  } else {
    return ignoreHourPrefix ? '0' : '00:00';
  }
};

import React, { useState } from 'react';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import {
  EditSingleProjectContainer,
  EditSingleProjectHeaderWrapper,
  EditSingleProjectFormContainter,
  EditSingleProjectFormWrapper,
  EditSingleProjectButton,
} from 'modules/SingleProject/EditSingleProject/EditSingleProject.components';
import EditSingleProjectForm from 'modules/SingleProject/EditSingleProject/EditSingleProjectForm';
import { ReactComponent as EditIcon } from 'modules/SingleProject/icons/edit.svg';

const EditSingleProject = () => {
  const [formDisabled, setFormDisabled] = useState(true);
  const { isAdmin } = useUserContext();

  return (
    <EditSingleProjectContainer>
      <EditSingleProjectHeaderWrapper>
        Szczegóły projektu
        {isAdmin && (
          <EditSingleProjectButton formDisabled={formDisabled} onClick={() => setFormDisabled(!formDisabled)}>
            <EditIcon />
          </EditSingleProjectButton>
        )}
      </EditSingleProjectHeaderWrapper>
      <EditSingleProjectFormContainter>
        <EditSingleProjectFormWrapper>
          <EditSingleProjectForm formDisabled={formDisabled} setFormDisabled={setFormDisabled} />
        </EditSingleProjectFormWrapper>
      </EditSingleProjectFormContainter>
    </EditSingleProjectContainer>
  );
};

export default EditSingleProject;

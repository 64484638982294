import React, { FunctionComponent, useState } from 'react';
import Calendar from 'modules/Home/CalendarSection/Calendar/Calendar';
import CalendarNavbar, { NavbarElement } from 'modules/Home/CalendarSection/CalendarNavbar/CalendarNavbar';
import { CalendarSectionContainer } from 'modules/Home/CalendarSection/CalendarSection.components';
import Notes from 'modules/Home/CalendarSection/Notes/Notes';

const CalendarSection: FunctionComponent = () => {
  const [currentSection, setCurrentSection] = useState<NavbarElement>(NavbarElement.CALENDAR);

  const handleCurrentSection = () => {
    switch (currentSection) {
      case NavbarElement.CALENDAR:
        return <Calendar />;
      case NavbarElement.NOTES:
        return <Notes />;
      case NavbarElement.PROJECTS:
        return <div>projects</div>;
      default:
        return <Calendar />;
    }
  };

  return (
    <CalendarSectionContainer>
      <CalendarNavbar currentSection={currentSection} setCurrentSection={setCurrentSection} />
      {handleCurrentSection()}
    </CalendarSectionContainer>
  );
};

export default CalendarSection;

export enum Role {
  STANDARD = 'STANDARD',
  ADMIN = 'ADMIN',
}

export interface IUser {
  uid?: string;
  displayName: string;
  email: string;
  role: Role;
  isRemoved?: boolean;
}

import colors from 'modules/shared/styles/colors';
import { font14, styledScrollbar } from 'modules/shared/styles/mixins';
import styled, { css } from 'styled-components';

export const UsersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 336px;
  height: 100vh;
  border-right: 2px solid ${colors.gray10};

  @media all and (max-width: 850px) {
    min-width: 246px;
  }
`;

export const UsersListHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  background: ${colors.gray20};
  justify-content: center;
  align-items: center;
  font-weight: 600;
  ${font14()}
  text-align: center;
  color: ${colors.white};
  border-bottom: 2px solid ${colors.gray10};
`;

export const UsersListWrapper = styled.div`
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  background: ${colors.gray20};
  overflow-y: scroll;
  ${styledScrollbar(colors.gray20)}
`;

export const SelectedUserButton = styled.div`
  display: none;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  background: ${colors.purple80};
  justify-content: center;
  align-items: center;
  margin-left: auto;
  transition: display 0.2s ease-in;
`;

export const UserWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  position: relative;
  display: flex;
  padding: 8px 16px 8px 16px;
  border-radius: 15px;
  ${font14()}
  color: white;
  opacity: 0.8;
  cursor: pointer;
  align-items: center;
  transition: background 0.1s ease-in;
  margin-top: 12px;
  &:hover {
    background: ${colors.grayLighter};
    & ${SelectedUserButton} {
      display: flex;
    }
  }

  ${p =>
    p.isSelected &&
    css`
      background: ${colors.grayLighter};
      & ${SelectedUserButton} {
        display: flex;
        background: rgba(255, 255, 255, 0.15);
      }
    `}
`;

export const UserAvatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 8px;
`;

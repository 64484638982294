import colors from 'modules/shared/styles/colors';
import { font12, font16, styledScrollbar } from 'modules/shared/styles/mixins';
import styled from 'styled-components';

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

export const CalendarHeaderContainer = styled.div`
  display: flex;
  padding: 16px 40px 16px 8px;
  border-bottom: 1px solid ${colors.grayLighter};
  align-items: flex-end;
`;

export const MonthPickerWrapper = styled.div`
  display: flex;
  height: auto;
  align-self: flex-start;
  margin-top: 6px;
  > svg {
    cursor: pointer;
  }
`;

export const SelectedMonthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  > span {
    font-weight: 500;
    ${font16()}
    color: ${colors.white};
    opacity: 0.8;
    text-transform: capitalize;
  }
`;

export const HourSummary = styled.div`
  display: flex;
  padding: 12px 16px;
  border-radius: 15px;
  background: ${colors.grayLighter};
  align-items: center;
  justify-content: center;
  margin-left: auto;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.2px;
  color: ${colors.white};

  @media all and (max-width: 950px) {
    font-size: 18px;
    margin-left: 20px;
  }
`;

export const CurrentDayButton = styled.button`
  display: flex;
  border: none;
  padding: 2px 8px;
  background: ${colors.grayLighter};
  border-radius: 5px;
  font-weight: 500;
  ${font12()}
  color: #a4a2a9;
  align-items: center;
  justify-content: center;
  width: 70px;
  cursor: pointer;
  margin-top: 4px;
  transition: transform 0.4s ease;

  > svg {
    margin-right: 4px;
  }
  &:hover {
    transform: scale(1.1);
  }
`;

export const CalendarWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  padding-top: 26px;
  padding-left: 6px;
  padding-right: 4px;
  height: calc(100vh - 175px);
  overflow-y: scroll;
  ${styledScrollbar(colors.gray20)}
`;

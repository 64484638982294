import React, { FunctionComponent, useMemo, useState } from 'react';
import normalizeTimeInput from 'common/helpers/normalizeTimeInput';
import {
  ProjectBarContainer,
  ProjectBarImage,
  ProjectBarTitleContainer,
  ProjectBarWrapper,
  TimeInput,
  TimeInputWrapper,
  WarningBox,
} from 'modules/Home/HoursSection/HoursList/ProjectBar/ProjectBar.components';
import { ReactComponent as CrossIcon } from 'modules/Home/HoursSection/HoursList/icons/crossIcon.svg';
import { Transition } from 'react-transition-group';
import setTimesheets from 'firebase/timesheet/setTimesheets';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import useTimesheetContext from 'modules/App/AuthedLayout/TimesheetContext/useTimesheetContext';
import { toast } from 'react-toastify';
import { HourListFormValues } from 'modules/Home/HoursSection/HoursList/form';
import stringValueToMinutes from 'common/helpers/stringValueToMinutes';
import Note from 'modules/Home/HoursSection/HoursList/ProjectBar/Note/Note';
import useOnClickOutside from 'common/helpers/useOnClickOutside';
import { useRef } from 'react';
import CustomTooltip from 'modules/shared/components/Tooltip/Tooltip';

type ProjectBarProps = {
  phaseId: string;
  title: string;
  image?: string;
  timeout: number;
  value: string;
  values: HourListFormValues;
  handleChange: any;
  setFieldValue: any;
  note: string;
};

const validationMessages = {
  invalidMinutes: 'Możesz wpisać max 59 minut Jeleniu',
  invalidHours: 'Ehh.. Wpisz godziny z przedziału 0-99h',
  invalidSyntax: 'Godziny wpisuje się z dwukropkiem :',
};

const ProjectBar: FunctionComponent<ProjectBarProps> = ({
  phaseId,
  title,
  image,
  timeout,
  value,
  values,
  handleChange,
  setFieldValue,
  note,
}) => {
  const [isSelected, setIsSelected] = useState(note !== '');
  const [validationMessage, setValidationMessage] = useState('');
  const noteRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { selectedDate } = useUserContext();
  const { currentTimesheetData } = useTimesheetContext();

  const currentPhaseId = useMemo(() => {
    const projectId = phaseId.split('-')[0];
    return `${projectId}-${selectedDate.getFullYear()}-${selectedDate.getMonth()}`;
  }, [phaseId, selectedDate]);

  useOnClickOutside(noteRef, () => {
    if (note === '') setIsSelected(false);
  });

  const validateUserInput = () => {
    if (value === '') setFieldValue(currentPhaseId, '00:00');

    if (value) {
      const hasSeparator = value.includes(':');
      const splittedInput = value.split(':');
      const hours = Number(splittedInput[0]);
      const minutes = Number(splittedInput[1]);

      if (isNaN(hours) || hours < 0 || hours > 99) {
        setValidationMessage(validationMessages.invalidHours);
        return;
      }

      if (hasSeparator) {
        if (isNaN(minutes) || minutes < 0 || minutes > 59) {
          setValidationMessage(validationMessages.invalidMinutes);
          return;
        }
        if (splittedInput[0].length === 1) splittedInput[0] = `0${splittedInput[0]}`;
        if (splittedInput[1].length === 1) splittedInput[1] = `0${splittedInput[1]}`;
        setFieldValue(currentPhaseId, `${splittedInput[0]}:${splittedInput[1]}`);
      } else {
        if (splittedInput[0].length === 1) {
          setFieldValue(currentPhaseId, `0${splittedInput[0]}:00`);
        }
        if (splittedInput[0].length === 2) {
          setFieldValue(currentPhaseId, `${splittedInput[0]}:00`);
        }
      }

      if (Number(value) === 0) setFieldValue(currentPhaseId, '00:00');
    }
  };

  return (
    <Transition timeout={timeout * 70} mountOnEnter={true} unmountOnExit={true} appear={true} in={currentPhaseId !== null}>
      {status => (
        <ProjectBarContainer status={status}>
          <CrossIcon
            onClick={async () => {
              try {
                let timesheetData = (currentTimesheetData?.filter(t => t.phaseId !== currentPhaseId) || []).map(t => {
                  return {
                    ...t,
                    value: stringValueToMinutes(values[t.phaseId]),
                    note: t.note || '',
                  };
                });

                await setTimesheets(selectedDate, timesheetData, true);
                toast.success('Faza usunięta!');
              } catch (e) {
                toast.error('Wystąpił błąd :/');
              }
            }}
          />
          <ProjectBarWrapper>
            <ProjectBarImage src={image} alt="project-image" />
            <ProjectBarTitleContainer>
              <CustomTooltip title={title} />
            </ProjectBarTitleContainer>
            <TimeInputWrapper validationError={validationMessage.length > 0}>
              <TimeInput
                draggable
                onDragStart={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                hightlight={value !== '00:00'}
                name={currentPhaseId}
                value={value}
                onFocus={() => {
                  setValidationMessage('');
                  setIsSelected(true);
                  if (value === '00:00') setFieldValue(currentPhaseId, '');
                }}
                onChange={e => {
                  normalizeTimeInput(e);
                  handleChange(e);
                }}
                onBlur={validateUserInput}
              />
              <Transition timeout={timeout * 100} mountOnEnter={true} unmountOnExit={true} appear={true} in={validationMessage.length > 0}>
                {status => <WarningBox status={status}>{validationMessage}</WarningBox>}
              </Transition>
            </TimeInputWrapper>
          </ProjectBarWrapper>
          <div ref={noteRef!}>
            <Note isSelected={isSelected} phaseId={currentPhaseId} note={note || ''} noteRef={noteRef} />
          </div>
        </ProjectBarContainer>
      )}
    </Transition>
  );
};

export default ProjectBar;

// Avatars
import Lukasz from 'modules/Users/UsersList/avatars/Łukasz.svg';
import Sylwia from 'modules/Users/UsersList/avatars/Sylwia.svg';
import Marta from 'modules/Users/UsersList/avatars/Marta.svg';
import Dawid from 'modules/Users/UsersList/avatars/Dawid.svg';
import Tomek from 'modules/Users/UsersList/avatars/Tomek.svg';
import SylwiaW from 'modules/Users/UsersList/avatars/SylwiaW.svg';
import Magda from 'modules/Users/UsersList/avatars/Magda.svg';
import Paulina from 'modules/Users/UsersList/avatars/Paulina.svg';
import Matek from 'modules/Users/UsersList/avatars/Matek.svg';
import MartaM from 'modules/Users/UsersList/avatars/MartaM.svg';

export const getUserAvatar = (email: string) => {
  switch (email) {
    case 'lukasz@purpledeer.studio':
      return Lukasz;
    case 'sylwia@purpledeer.studio':
      return Sylwia;
    case 'marta@purpledeer.studio':
      return Marta;
    case 'dawid@purpledeer.studio':
      return Dawid;
    case 'tomek@purpledeer.studio':
      return Tomek;
    case 'sylwia.w@purpledeer.studio':
      return SylwiaW;
    case 'magdalena@purpledeer.studio':
      return Magda;
    case 'paulina@purpledeer.studio':
      return Paulina;
    case 'mateusz@purpledeer.studio':
      return Matek;
    case 'marta.m@purpledeer.studio':
      return MartaM;
    default:
      return Matek;
  }
};

import colors from 'modules/shared/styles/colors';
import { font14, styledScrollbar } from 'modules/shared/styles/mixins';
import styled from 'styled-components';

export const HoursListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  ${styledScrollbar()}
`;

export const HourListHeader = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${colors.white};
  margin-top: 64px;
`;

export const HourIndicator = styled.span`
  display: flex;
  padding: 12px 20px;
  background: ${colors.purple90};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.006);
  border-radius: 15px;
  max-width: 200px;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.2px;
  color: ${colors.white};
  margin-top: 16px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;

  > :last-child {
    margin-left: 8px;
  }
`;

type ActionButtonProps = {
  highlight?: boolean;
};

export const ActionButton = styled.button<ActionButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  height: 32px;
  background: ${colors.gray20};
  border-radius: 10px;
  border: none;
  font-weight: 500;
  ${font14()}
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
  cursor: pointer;
  transition: background 0.5s ease;
  ${p => p.highlight && `background: ${colors.purple90};`}

  > svg {
    margin-right: 10px;
  }
`;

export const ProjectsWrapper = styled.div`
  margin-top: 24px;
`;

import { auth } from 'config/firebase';
import firebase from 'firebase/app';
import { ITimesheet } from 'firebase/types/Timesheets';

const setNote = async (selectedDate: Date, selectedDayPhases: ITimesheet[], selectedPhaseId: string, note: string) => {
  const userId = auth.currentUser?.uid;

  const timesheetSnapshot = await firebase
    .firestore()
    .collection(`/users/${userId}/timesheets`)
    .doc(`${selectedDate.getFullYear()}-${selectedDate.getMonth()}`)
    .get();

  const timesheetData = timesheetSnapshot.data();
  const selectedDays = selectedDayPhases.map((p) => ({
    day: selectedDate.getDate(),
    phaseId: p.phaseId,
    value: p.value,
  }));

  if (!timesheetData) {
    firebase
      .firestore()
      .collection(`/users/${userId}/timesheets`)
      .doc(`${selectedDate.getFullYear()}-${selectedDate.getMonth()}`)
      .set({
        days: [
          ...selectedDays.filter((d: ITimesheet) => d.phaseId !== selectedPhaseId),
          {
            day: selectedDate.getDate(),
            phaseId: selectedPhaseId,
            value: 0,
            note,
          },
        ],
      });
  } else {
    // Get all records except one which we want add note to
    let existingData = timesheetData.days;

    let recordFound = false;
    for (let i = 0; i < existingData.length; i++) {
      if (existingData[i].day === selectedDate.getDate() && existingData[i].phaseId === selectedPhaseId) {
        existingData[i].note = note;
        recordFound = true;
      }
    }

    if (!recordFound) {
      existingData.push(...selectedDays.filter((d: ITimesheet) => d.phaseId !== selectedPhaseId), {
        day: selectedDate.getDate(),
        phaseId: selectedPhaseId,
        value: 0,
        note,
      });
    }

    firebase
      .firestore()
      .collection(`/users/${userId}/timesheets`)
      .doc(`${selectedDate.getFullYear()}-${selectedDate.getMonth()}`)
      .set({
        days: [...existingData],
      });
  }
};

export default setNote;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { LoginPageContainer, Meme, MemeContainer } from 'modules/App/PublicLayout/PublicLayout.components';

const PublicLayout: FunctionComponent = ({ children }) => {
  const [meme, setMeme] = useState();

  useEffect(() => {
    const getMeme = async () => {
      const res = await fetch('https://meme-api.com/gimme');
      const memes = await res.json();

      setMeme(memes.url);
    };
    getMeme();
  }, []);

  return (
    <LoginPageContainer>
      <MemeContainer>
        <Meme src={meme} alt="meme" />
      </MemeContainer>
      {children}
    </LoginPageContainer>
  );
};

export default PublicLayout;

import React, { FunctionComponent, Dispatch } from 'react';
import {
  SingleProjectsNavbarContainer,
  SingleProjectsNavbarElement,
} from 'modules/SingleProject/SingleProjectNavbar/SingleProjectNavbar.components';
import { NavbarElement } from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhases';

type Props = {
  pages: NavbarElement[];
  currentPage: NavbarElement;
  setCurrentPage: Dispatch<React.SetStateAction<NavbarElement>>;
};

const SingleProjectNavbar: FunctionComponent<Props> = ({ pages, currentPage, setCurrentPage }) => {
  return (
    <SingleProjectsNavbarContainer>
      {pages.map((page, index) => (
        <SingleProjectsNavbarElement
          isActive={currentPage === page}
          key={`${page}-${index}`}
          onClick={() => setCurrentPage(page)}
        >
          {page.toLowerCase()}
        </SingleProjectsNavbarElement>
      ))}
    </SingleProjectsNavbarContainer>
  );
};

export default SingleProjectNavbar;

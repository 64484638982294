import { getDayOfYear } from 'date-fns';
import { getUserHolidays } from './getUserHolidays';
import firebase from 'firebase/app';

export const removeOverlappedHolidays = async (
  userId: string,
  year: number,
  start: Date,
  end: Date,
  batch: firebase.firestore.WriteBatch,
) => {
  const startDay = getDayOfYear(start);
  const endDay = getDayOfYear(end);
  const holidays = await getUserHolidays(year, userId);

  const overlappedHolidays = holidays?.days.filter(day => startDay <= day.start && endDay >= day.end) ?? [];

  const ref = firebase.firestore().collection('users').doc(userId).collection('holidays').doc(year.toString());

  for (let holiday of overlappedHolidays) {
    const deleteRef = ref.collection('days').doc(holiday.id);
    batch.delete(deleteRef);
    batch.set(
      ref,
      {
        taken: firebase.firestore.FieldValue.increment(-holiday.count),
      },
      { merge: true },
    );
  }
};

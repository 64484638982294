import React from 'react';
import EditSingleProject from 'modules/SingleProject/EditSingleProject/EditSingleProject';
import SingleProjectPhases from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhases';
import { SingleProjectContainer } from 'modules/SingleProject/SingleProject.components';

const SingleProject = () => {
  return (
    <SingleProjectContainer>
      <EditSingleProject />
      <SingleProjectPhases />
    </SingleProjectContainer>
  );
};

export default SingleProject;

import { HoursNavbarContainer, HoursNavbarElement } from 'modules/Home/HoursSection/HoursNavbar/HoursNavbar.components';
import { NavbarElement } from 'modules/Home/HoursSection/HoursSection';
import React, { FunctionComponent, Dispatch } from 'react';

type Props = {
  pages: NavbarElement[];
  currentPage: NavbarElement;
  setCurrentPage: Dispatch<React.SetStateAction<NavbarElement>>;
};

const HoursNavbar: FunctionComponent<Props> = ({ pages, currentPage, setCurrentPage }) => {
  return (
    <HoursNavbarContainer>
      {pages.map((page, index) => (
        <HoursNavbarElement
          isActive={currentPage === page}
          key={`${page}-${index}`}
          onClick={() => setCurrentPage(page)}
        >
          {page.toLowerCase()}
        </HoursNavbarElement>
      ))}
    </HoursNavbarContainer>
  );
};

export default HoursNavbar;

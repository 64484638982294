const rootPaths = {
  LOGIN: '/login',
  ERROR404: '/404',
};

const authedPaths = {
  MAIN: '/',
  HOME: '/home',
  PROJECTS: '/projects',
  PROJECT: '/projects/:projectId',
  USERS: '/users',
  HOLIDAYS: '/holidays',
};

const routesPaths = {
  ...authedPaths,
  ...rootPaths,
};

export default routesPaths;

import { Tooltip } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';

interface CustomTooltipProps {
  title: string;
}

const CustomTooltip: FunctionComponent<CustomTooltipProps> = ({ title }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (ref && ref.current) setShowTooltip(ref.current.offsetWidth < ref?.current?.scrollWidth);
  }, [windowWidth]);

  return (
    <Tooltip title={<span style={{ fontSize: 14 }}>{title}</span>} disableHoverListener={!showTooltip}>
      <span ref={ref}>{title}</span>
    </Tooltip>
  );
};

export default CustomTooltip;

import { Formik } from 'formik';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import { HourListFormValues } from 'modules/Home/HoursSection/HoursList/form';
import HourListFormContent from 'modules/Home/HoursSection/HoursList/HourListFormContent';
import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useState } from 'react';
import useTimesheetContext from 'modules/App/AuthedLayout/TimesheetContext/useTimesheetContext';
import { minutesToHourString } from 'common/helpers/minutesToHourString';
import stringValuesToMinutes from 'common/helpers/stringValuesToMinutes';
import { toast } from 'react-toastify';
import setTimesheets from 'firebase/timesheet/setTimesheets';
import { ITimesheet } from 'firebase/types/Timesheets';
import { isOffFriday } from 'helpers/isOffFriday';

type Props = {
  localHours: string;
  setLocalHours: Dispatch<SetStateAction<string>>;
};

const HourListForm: FunctionComponent<Props> = ({ localHours, setLocalHours }) => {
  const { selectedDate } = useUserContext();
  const { currentTimesheetData, setIsDuringSave } = useTimesheetContext();

  const [currentOrder, setCurrentOrder] = useState<ITimesheet[]>([]);

  useEffect(() => {
    setCurrentOrder(currentTimesheetData || []);
  }, [currentTimesheetData]);

  const convertInitialValues = () => {
    let finalObj: { [key: string]: string } = {};
    if (currentTimesheetData && currentTimesheetData.length > 0) {
      for (let timesheet of currentTimesheetData) {
        const projectId = timesheet.phaseId.split('-')[0];
        const phaseId = `${projectId}-${selectedDate.getFullYear()}-${selectedDate.getMonth()}`;
        finalObj[phaseId] = minutesToHourString(timesheet.value);
      }
    }
    return finalObj;
  };

  const changeValuesOrder = async (valuesInNewOrder: ITimesheet[], values: HourListFormValues) => {
    try {
      setCurrentOrder(valuesInNewOrder);
      await saveTimesheets(values, valuesInNewOrder);
    } catch (e) {
      console.log(e);
      toast.error('Wystąpił problem');
    }
  };

  const handleSubmit = async (values: HourListFormValues) => {
    try {
      const payload = await saveTimesheets(values, currentOrder);
      const toastMessage = isOffFriday(selectedDate)
        ? 'Ej, jest wolny piątek 🌴'
        : payload.reduce((acc, val) => acc + val.value, 0) > 480
        ? 'Spokojnie, nie szalej z nadgodzinami 😱'
        : 'Super, tak trzymaj 👌';
      toast.success(toastMessage);
    } catch (e) {
      console.log(e);
      toast.error('Wystąpił problem');
    }
  };

  const saveTimesheets = useCallback(
    async (values: HourListFormValues, order: ITimesheet[]) => {
      setIsDuringSave(true);
      let payload = stringValuesToMinutes(values, selectedDate);

      for (let p of payload) {
        const phase = currentTimesheetData?.find(t => t.phaseId === p.phaseId);
        // @ts-ignore
        p.note = phase?.note || '';
      }

      const sortedPayload = order
        ? payload.sort(
            (a: any, b: any) => order.findIndex(el => el.phaseId === a.phaseId) - order.findIndex(el => el.phaseId === b.phaseId),
          )
        : payload;

      await setTimesheets(selectedDate, sortedPayload || []);

      return payload;
    },
    [currentTimesheetData, selectedDate, setIsDuringSave],
  );

  return (
    <Formik initialValues={convertInitialValues()} onSubmit={handleSubmit} enableReinitialize={true}>
      {formProps => (
        <HourListFormContent localHours={localHours} changeValuesOrder={changeValuesOrder} setLocalHours={setLocalHours} {...formProps} />
      )}
    </Formik>
  );
};

export default HourListForm;

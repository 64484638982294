import React, { FunctionComponent, useEffect } from 'react';
import { FormikProps } from 'formik';
import InputField from 'modules/shared/components/Form/Input/InputField';
import { AddSingleProjectPhaseFormValues } from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhase/form';
import {
  AddPhaseForm,
  AddPhaseSpan,
  SavePhaseButton,
} from 'modules/SingleProject/SingleProjectPhases/SingleProjectPhase/SingleProjectPhase.components';
import { ReactComponent as SaveIcon } from 'modules/SingleProject/icons/save.svg';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { pl } from 'date-fns/locale';

type Props = FormikProps<AddSingleProjectPhaseFormValues> & {
  message?: string;
  availableHours: string;
  usedHours: string;
};

const AddSingleProjectPhaseFormContent: FunctionComponent<Props> = ({
  isSubmitting,
  isValid,
  submitCount,
  values,
  availableHours,
  usedHours,
  message,
  setFieldValue,
  setFieldTouched,
  submitForm,
  resetForm,
  ...props
}) => {
  const saveAllowed = values.phaseName !== '' && values.startDate;

  useEffect(() => {
    setFieldValue('originalEstimate', values.originalEstimate.replace(/[^0-9]/g, ''));
  }, [setFieldValue, values]);

  return (
    <MuiPickersUtilsProvider locale={pl} utils={DateFnsUtils}>
      <AddPhaseForm
        onKeyDown={e => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            if (saveAllowed) {
              submitForm();
              setTimeout(() => resetForm(), 200);
            }
          }
        }}
      >
        <AddPhaseSpan>{values.phaseName}</AddPhaseSpan>

        <AddPhaseSpan>{usedHours}</AddPhaseSpan>
        <AddPhaseSpan>{availableHours}</AddPhaseSpan>

        <InputField
          name="originalEstimate"
          sizeType="small"
          underlineOnly
          onChange={(e: any) => {
            const isAllowed = /^([0-9])$/.test(e.target.value);
            if (!isAllowed) e.target.value = e.target.value.split('').join('');
            setFieldTouched('originalEstimate', e.target.value);
          }}
        />

        {saveAllowed && (
          <SavePhaseButton
            onClick={async () => {
              await submitForm();
              resetForm();
            }}
          >
            <SaveIcon />
          </SavePhaseButton>
        )}
      </AddPhaseForm>
    </MuiPickersUtilsProvider>
  );
};
export default AddSingleProjectPhaseFormContent;

import styled, { css } from 'styled-components';
import colors from 'modules/shared/styles/colors';
import { font14 } from 'modules/shared/styles/mixins';

export const SingleProjectsNavbarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  background: ${colors.gray20};

  button {
    margin-left: 25px;
  }
`;

type Props = {
  isActive: boolean;
};

export const SingleProjectsNavbarElement = styled.button<Props>`
  position: relative;
  background: none;
  border: none;
  font-weight: ${(p) => (p.isActive ? '600' : '500')};
  ${font14()}
  color: ${(p) => (p.isActive ? colors.white : 'rgba(255, 255, 255, 0.6);')};
  text-transform: capitalize;
  padding: 0 10px;
  cursor: pointer;
  ${(p) =>
    p.isActive &&
    css`
      &::after {
        position: absolute;
        top: 56px;
        left: 0px;
        content: '';
        width: 100%;
        height: 2px;
        background: ${colors.purple80};
        border-radius: 18px;
      }
    `}
`;

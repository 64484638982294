import React, { FunctionComponent, useEffect, useState } from 'react';
import Sidebar from 'modules/shared/components/Sidebar';
import {
  AnimatedBackground,
  LogoutButton,
  NavigationButton,
  NavigationContainer,
} from 'modules/App/AuthedLayout/SideMenu/SideMenu.components';
import { auth } from 'config/firebase';
import { useHistory, useLocation } from 'react-router-dom';
import routesPaths from 'modules/Routing/routesPaths';
import AppEventTarget from 'common/events/AppEventTarget';
import CalendarTabChangeEvent from 'common/events/CalendarTabChangeEvent';

// Icons
import { ReactComponent as Clock } from 'modules/App/AuthedLayout/SideMenu/icons/clock.svg';
import { ReactComponent as ClockFaded } from 'modules/App/AuthedLayout/SideMenu/icons/clockFaded.svg';
import { ReactComponent as Projects } from 'modules/App/AuthedLayout/SideMenu/icons/projects.svg';
import { ReactComponent as ProjectsFaded } from 'modules/App/AuthedLayout/SideMenu/icons/projectsFaded.svg';
import { ReactComponent as Users } from 'modules/App/AuthedLayout/SideMenu/icons/users.svg';
import { ReactComponent as UsersFaded } from 'modules/App/AuthedLayout/SideMenu/icons/usersFaded.svg';
import { ReactComponent as Holidays } from 'modules/App/AuthedLayout/SideMenu/icons/holidays.svg';
import { ReactComponent as HolidaysFaded } from 'modules/App/AuthedLayout/SideMenu/icons/holidaysFaded.svg';
import { ReactComponent as Logout } from 'modules/App/AuthedLayout/SideMenu/icons/logoutIcon.svg';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';

type SidebarItem = {
  path: string;
  bgPosition: number;
  iconActive: React.ReactNode;
  iconInactive: React.ReactNode;
  alternativePaths?: string[];
};

const MenuItems: SidebarItem[] = [
  {
    path: routesPaths.HOME,
    bgPosition: 92,
    iconActive: <Clock />,
    iconInactive: <ClockFaded />,
    alternativePaths: [routesPaths.MAIN],
  },
  {
    path: routesPaths.PROJECTS,
    bgPosition: 142,
    iconActive: <Projects />,
    iconInactive: <ProjectsFaded />,
  },
  {
    path: routesPaths.USERS,
    bgPosition: 192,
    iconActive: <Users />,
    iconInactive: <UsersFaded />,
  },
  {
    path: routesPaths.HOLIDAYS,
    bgPosition: 242,
    iconActive: <Holidays />,
    iconInactive: <HolidaysFaded />,
  },
];

const isPathActive = (location: string, item: SidebarItem) => location.includes(item.path) || item.alternativePaths?.includes(location);

const SideMenu: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const { setSelectedDate } = useUserContext();

  const [animatedBgPosition, setAnimatedBgPosition] = useState(92);

  useEffect(() => {
    const item = MenuItems.find(item => isPathActive(location.pathname, item)) ?? MenuItems[0];
    setAnimatedBgPosition(item.bgPosition);
  }, [location.pathname]);

  return (
    <Sidebar>
      <AnimatedBackground position={animatedBgPosition} />
      <NavigationContainer>
        {MenuItems.map(item => (
          <NavigationButton
            key={item.path}
            onClick={() => {
              setAnimatedBgPosition(item.bgPosition);
              AppEventTarget.dispatchEvent(new CalendarTabChangeEvent());
              setSelectedDate(new Date());
              history.push(item.path);
            }}
          >
            {isPathActive(location.pathname, item) ? item.iconActive : item.iconInactive}
          </NavigationButton>
        ))}
      </NavigationContainer>
      <LogoutButton onClick={() => auth.signOut()}>
        <Logout />
      </LogoutButton>
    </Sidebar>
  );
};

export default SideMenu;

import colors from 'modules/shared/styles/colors';
import { font12 } from 'modules/shared/styles/mixins';
import styled from 'styled-components';

export const SingleProjectPhasesTableContainer = styled.div`
  width: 100%;
  margin-top: 24px;

  :not(:last-of-type) {
    margin-bottom: 60px;
  }

  @media all and (max-width: 1200px) {
    min-width: fit-content;
  }
`;

export const SingleProjectPhasesTableHeaderWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 1fr 1fr 0.6fr;
  width: 100%;
  height: 32px;
  border-radius: 10px;
  margin-bottom: -8px;
  transition: margin-top 0.1s ease;
  background: ${colors.gray20};

  > span {
    :not(:first-child) {
      text-align: center;
    }
    text-transform: uppercase;
    ${font12()}
    color: rgba(255, 255, 255, 0.8);
    opacity: 0.5;
  }

  > :first-child {
    padding-left: 56px;
  }

  > :nth-child(n + 4) {
    justify-self: center;
  }
`;
export const SingleProjectPhasesTitle = styled.span`
  display: none;
  @media all and (max-width: 1000px) {
    display: inline-block;
    margin-right: 6px;
    &:after {
      content: '-';
    }
  }
`;

import React, { FunctionComponent, useState, useEffect } from 'react';
import { ReactComponent as NoteIcon } from 'modules/Home/HoursSection/HoursList/ProjectBar/Note/noteIcon.svg';
import { NoteContainer, NoteIconWrapper } from 'modules/Home/HoursSection/HoursList/ProjectBar/Note/Note.components';
import { Transition } from 'react-transition-group';
import NoteForm from 'modules/Home/HoursSection/HoursList/ProjectBar/Note/NoteForm';
import useOnClickOutside from 'common/helpers/useOnClickOutside';

type Props = {
  isSelected: boolean;
  phaseId: string;
  note: string;
  noteRef: React.MutableRefObject<HTMLDivElement>;
};

const Note: FunctionComponent<Props> = ({ isSelected, phaseId, note, noteRef }) => {
  const [inputOpened, setInputOpened] = useState(false);

  useEffect(() => {
    if (!isSelected) setInputOpened(false);
  }, [isSelected]);

  useOnClickOutside(noteRef, () => {
    if (note !== '') setInputOpened(false);
  });

  return (
    <>
      <Transition timeout={100} mountOnEnter={true} unmountOnExit={true} appear={true} in={isSelected}>
        {(status) => (
          <NoteContainer
            status={status}
            onClick={() => {
              setInputOpened(!inputOpened);
            }}
          >
            <NoteIconWrapper inputOpened={inputOpened}>
              <NoteIcon />
            </NoteIconWrapper>
          </NoteContainer>
        )}
      </Transition>
      <Transition timeout={100} mountOnEnter={true} unmountOnExit={true} appear={true} in={isSelected && inputOpened}>
        {(status) => <NoteForm status={status} setInputOpened={setInputOpened} phaseId={phaseId} note={note} />}
      </Transition>
    </>
  );
};

export default Note;

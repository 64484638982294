import styled from 'styled-components';
import { Form } from 'formik';
import { TransitionStatus } from 'react-transition-group';
import colors from 'modules/shared/styles/colors';
import { font12, font16 } from 'modules/shared/styles/mixins';

export const AddProjectModalWrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 40px 48px;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AvatarInputContainer = styled.div`
  display: flex;
`;

export const AvatarInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RequirementsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 15px;
  font-weight: normal;
  ${font12()}
  color: rgba(255, 255, 255, 0.8);
  > span {
    margin-bottom: 4px;
    margin-left: 9px;
    position: relative;
    ::before {
      position: absolute;
      content: '';
      width: 4px;
      height: 4px;
      left: -8px;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
      background: ${colors.mint};
    }
  }
`;

export const ButtonsWrapper = styled.div<{ status?: TransitionStatus }>`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: auto;
  align-items: flex-end;

  > :last-child {
    margin-left: 8px;
  }
  transition: transform 300ms ease, opacity 200ms ease;
  opacity: ${(props) => (props.status === 'entered' ? '1' : '0')};
  transform: translateY(${(props) => (props.status === 'entered' ? '0px' : '-50px')});
`;

type ModalButtonProps = {
  isConfirm?: boolean;
};

export const ModalButton = styled.button<ModalButtonProps>`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 32px;
  font-weight: 500;
  ${font16()}
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  border: none;
  outline: none;
  background: ${(p) => (p.isConfirm ? colors.purple90 : colors.gray10)};
  cursor: pointer;
`;

import colors from 'modules/shared/styles/colors';
import { font14 } from 'modules/shared/styles/mixins';
import styled, { css } from 'styled-components';

export type InputSize = 'small' | 'normal' | 'medium' | 'unset' | 'responsive';

interface StyledInputProps {
  isError?: boolean;
  isIcon?: boolean;
  iconPosition?: IconPosition;
  underlineOnly?: boolean;
}
interface WrapperProps {
  isError?: boolean;
  sizeType?: InputSize;
}
export const SMALL_INPUT_WIDTH = 52;
export const MEDIUM_INPUT_WIDTH = 96;
export const NORMAL_INPUT_WIDTH = 321;
export const RESPONSIVE_INPUT_WIDTH = 220;
export const INPUT_SHOW_ERROR_MARGIN = 5;

export type IconPosition = 'left' | 'right';

interface IconProps {
  position?: IconPosition;
  clickable?: boolean;
}

export const Icon = styled.img<IconProps>`
  position: absolute;
  ${(p) => css`
    ${p.position === 'right' ? 'right:0' : 'left:0'};
    ${p.position === 'left' && 'margin-left: 16px'};
  `}
  width:24px;
  height: 24px;
  top: 50%;
  ${(p) => p.position === 'left' && `padding-right: 8px;`}
  transform: translateY(-50%);
  ${(p) =>
    p.clickable &&
    css`
      pointer-events: initial;
      cursor: pointer;
    `}
`;

export const StyledInput = styled.input<StyledInputProps>`
  ::-webkit-credentials-auto-fill-button {
    position: absolute;
    right: 50px;
  }
  margin-top: 0px;
  margin-bottom: 0px;
  width: calc(100% - 24px);
  cursor: pointer;
  line-height: 1.43;
  letter-spacing: normal;
  padding: 12px;
  background: ${colors.gray20};
  border: 1px solid ${colors.gray40};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  ${font14()}
  color: rgba(255, 255, 255, 0.95);
  &::placeholder {
    color: white;
  }
  :hover:enabled {
  }
  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.8);
  }

  &:disabled {
    border: 1px solid ${colors.gray40};
    cursor: auto;
  }

  ${(p) =>
    p.underlineOnly &&
    css`
      justify-self: center;
      border: none;
      box-shadow: none;
      background: none;
      border-bottom: 1px solid ${colors.gray60};
      border-radius: 0px;
      padding: 0px;
      &:focus,
      &:active {
        outline: none;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
      }
    `}
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputComponentWrapper = styled.div<WrapperProps>`
  margin-bottom: ${(p) => (p.isError ? `16px; ` : `16px;`)};
  width: ${NORMAL_INPUT_WIDTH}px;
  ${(p) => css`
    width: ${p.sizeType === 'small' ? SMALL_INPUT_WIDTH : NORMAL_INPUT_WIDTH}px;
  `}

  ${(p) => p.sizeType === 'unset' && `width: 100%;`}
  ${(p) => p.sizeType === 'medium' && `width: ${MEDIUM_INPUT_WIDTH}px`};
  ${(p) =>
    p.sizeType === 'responsive' &&
    css`
      width: 100%;
      max-width: ${RESPONSIVE_INPUT_WIDTH}px;
    `};
`;

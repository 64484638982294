import styled, { css } from 'styled-components';
import ChartDown from 'modules/Projects/icons/chartDown.svg';
import ChartUp from 'modules/Projects/icons/chartUp.svg';
import colors from 'modules/shared/styles/colors';

type Props = {
  isArchived: boolean;
  isClosed?: boolean;
};
export const ProjectContainer = styled.div<Props>`
  border-bottom: 1px solid ${colors.gray20};
  margin-top: 8px;
  background: ${colors.gray20};
  padding-top: 16px;
  border-radius: 15px;
  transition: opacity 1s ease;
  opacity: ${p => (p.isArchived ? 0.6 : 1)};
  cursor: ${p => (p.isClosed ? 'not-allowed' : 'pointer')};
`;

export const ProjectDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 0.6fr;
  align-items: center;
  color: ${colors.white};
  margin-bottom: 12px;
  > span {
    display: flex;
    justify-content: center;
  }

  > :last-child {
    justify-content: flex-end;
    margin-right: 40px;
    @media all and (max-width: 1024px) {
      margin-right: 30px;
    }
  }
`;

export const ProjectAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  > span {
    margin-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 280px;
  }
`;
export const ProjectAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 10px;
  margin-left: 16px;
`;

export const ActionButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const ActionButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  z-index: 2001;
`;

export const PhaseActionButton = styled(ActionButton)`
  position: static;
  transform: scale(0.75);
`;

export const PhaseActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 40px;
  @media all and (max-width: 1024px) {
    margin-right: 30px;
  }
`;

export const ProjectPhaseContainer = styled.div<Props>`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 0.6fr;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 16px;
  opacity: ${p => (p.isArchived ? 0.6 : 1)};
  > span {
    display: flex;
    justify-content: center;
  }

  > span:first-of-type {
    display: block;
    justify-content: flex-start !important;
    margin-left: 64px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
`;

export const HoursUsed = styled.span``;

type HoursAvailableProps = {
  showAlerts?: boolean;
  showDanger?: boolean;
};

export const HoursAvailable = styled.span<HoursAvailableProps>`
  position: relative;
  ${p =>
    p.showAlerts &&
    css`
      ::after {
        position: absolute;
        content: '';
        top: 0px;
        right: 32px;
        width: 12px;
        height: 12px;
        margin-left: 5px;
        margin-top: 4px;
        @media all and (max-width: 1200px) {
          right: 12px;
        }
        @media all and (max-width: 900px) {
          right: 0;
        }
      }
    `}
  ${p =>
    p.showAlerts && p.showDanger
      ? css`
          color: ${colors.red};
          ::after {
            background-image: url(${ChartDown});
          }
        `
      : css`
          ::after {
            background-image: url(${ChartUp});
          }
        `};
`;

export const Estimate = styled.span``;

import colors from 'modules/shared/styles/colors';
import { font14, font16, styledScrollbar } from 'modules/shared/styles/mixins';
import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';
import { DatePicker } from '@material-ui/pickers';

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SummaryWrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  ${styledScrollbar()}
`;

export const SummaryWrapperContent = styled.div`
  width: 40%;
  margin: 92px auto 32px;
  max-width: 450px;

  @media all and (max-width: 1200px) {
    width: 60%;
  }

  @media all and (max-width: 900px) {
    width: 280px;
  }
`;

export const SummaryHeader = styled.div`
  display: flex;
  color: ${colors.white};
  text-transform: uppercase;
  ${font16()}
  font-size: 24px;
  align-items: center;

  @media all and (max-width: 850px) {
    font-size: 18px;
  }
`;

export const SelectedDateWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  cursor: pointer;
  > svg {
    position: absolute;
    left: -28px;
    top: -2px;
  }
`;

export const Year = styled.span`
  font-weight: normal;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 4px;
`;

export const HourSummary = styled.span`
  display: flex;
  padding: 12px 20px;
  border-radius: 15px;
  background: ${colors.purple90};
  align-items: center;
  justify-content: center;
  margin-left: auto;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.2px;
  color: ${colors.white};
  text-transform: lowercase !important;

  @media all and (max-width: 850px) {
    font-size: 18px;
  }
`;

export const PhaseSummaryContainer = styled.div`
  margin-top: 40px;
`;

type TransitionProps = {
  status: TransitionStatus;
};

export const SummaryProjectBarContainer = styled.div<TransitionProps>`
  display: flex;
  align-items: center;
  transition: transform 300ms ease, opacity 200ms ease;
  opacity: ${props => (props.status === 'entered' ? '1' : '0')};
  transform: scale(${props => (props.status === 'entered' ? '1' : '0')});
`;

export const SummaryProjectBarWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  background: ${colors.gray20};
  border-radius: 15px;
  align-items: center;
  margin-bottom: 8px;

  > span:first-of-type {
    margin-left: 8px;
    font-weight: 500;
    ${font14()}
    color: rgba(255, 255, 255, 0.8);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
  }
`;

export const SummaryProjectBarImage = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 12px;
  border-radius: 10px;
`;

export const Hours = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.2px;
  margin-left: auto;
  margin-right: 24px;
  padding-left: 5px;
  color: rgba(255, 255, 255, 0.95);
`;

export const StyledCalendar = styled(DatePicker)`
  display: none !important;
`;

export const NoResultsWrapper = styled.div`
  text-align: center;
`;

export const NoResultsInfo = styled.span`
  ${font16()}
  color:${colors.white};
  text-align: center;
  justify-content: center;
  > strong {
    color: ${colors.purple90};
    text-align: center;
  }
`;

import styled from 'styled-components';

export const LoginPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  align-items: stretch;
`;

export const MemeContainer = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  background: #19191a;
`;

export const Meme = styled.img`
  width: 500px;
  height: 580px;
  border-radius: 16px;
`;

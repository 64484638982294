import React, { FunctionComponent, useCallback, useEffect } from 'react';
import {
  CalendarContainer,
  CalendarHeaderContainer,
  CalendarWrapper,
  CurrentDayButton,
  HourSummary,
  MonthPickerWrapper,
  SelectedMonthWrapper,
} from 'modules/Home/CalendarSection/Calendar/Calendar.components';
import { ReactComponent as CalendarIcon } from 'modules/Home/CalendarSection/icons/calendar.svg';
import { ReactComponent as ChevronLeft } from 'modules/Home/CalendarSection/icons/chevronLeft.svg';
import { ReactComponent as ChevronRight } from 'modules/Home/CalendarSection/icons/chevronRight.svg';
import Week from 'modules/Home/CalendarSection/Calendar/Week/Week';
import { startOfMonth, endOfMonth, eachDayOfInterval, isMonday, format, subMonths, addMonths } from 'date-fns';
import { pl } from 'date-fns/locale';
import { scroller } from 'react-scroll';
import { getShortDayName } from 'modules/Home/CalendarSection/Calendar/Calendar.helpers';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import useTimesheetContext from 'modules/App/AuthedLayout/TimesheetContext/useTimesheetContext';
import { minutesToHourString } from 'common/helpers/minutesToHourString';
import AppEventTarget from 'common/events/AppEventTarget';
import CalendarTabChangeEvent from 'common/events/CalendarTabChangeEvent';

const Calendar: FunctionComponent = () => {
  const { selectedDate, setSelectedDate } = useUserContext();
  const { timesheetsData } = useTimesheetContext();

  const getDaysInMonth = () => {
    const interval = eachDayOfInterval({ start: startOfMonth(selectedDate), end: endOfMonth(selectedDate) });
    const weeks: any[][] = [];
    let weekIndex = 1;
    for (const day of interval) {
      if (isMonday(day) && weeks.length > 0) weekIndex++;
      if (!weeks[weekIndex]) weeks[weekIndex] = [];
      weeks[weekIndex].push({
        shortName: getShortDayName(parseInt(format(day, 'c'))),
        date: format(day, 'dd.MM.yyyy'),
        dirtyDate: day,
      });
    }
    return weeks;
  };

  const scrollToSelectedDate = useCallback(() => {
    scroller.scrollTo(format(selectedDate, 'dd-MM-yyyy'), {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: 'calendarWrapper',
      offset: -250,
    });
  }, [selectedDate]);

  useEffect(() => {
    scrollToSelectedDate();
  }, [scrollToSelectedDate]);

  return (
    <CalendarContainer>
      <CalendarHeaderContainer>
        <MonthPickerWrapper>
          <ChevronLeft
            onClick={() => {
              AppEventTarget.dispatchEvent(new CalendarTabChangeEvent());
              setTimeout(() => setSelectedDate(startOfMonth(subMonths(selectedDate, 1))), 100);
            }}
          />
          <ChevronRight
            onClick={() => {
              AppEventTarget.dispatchEvent(new CalendarTabChangeEvent());
              setTimeout(() => setSelectedDate(startOfMonth(addMonths(selectedDate, 1))), 100);
            }}
          />
        </MonthPickerWrapper>
        <SelectedMonthWrapper>
          <span>{format(selectedDate, 'LLLL', { locale: pl })}</span>
          <CurrentDayButton
            onClick={() => {
              AppEventTarget.dispatchEvent(new CalendarTabChangeEvent());
              setTimeout(() => setSelectedDate(new Date()), 100);
              scrollToSelectedDate();
            }}
          >
            <CalendarIcon />
            Dzisiaj
          </CurrentDayButton>
        </SelectedMonthWrapper>
        <HourSummary>{minutesToHourString(timesheetsData?.reduce((acc, val) => acc + val.value, 0) || 0, true) || 0}h</HourSummary>
      </CalendarHeaderContainer>
      <CalendarWrapper id="calendarWrapper">
        {getDaysInMonth().map((week, index) => (
          <Week key={index} weekIndex={index} days={week} />
        ))}
      </CalendarWrapper>
    </CalendarContainer>
  );
};

export default Calendar;

import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import {
  SelectedUserButton,
  UserAvatar,
  UsersListContainer,
  UsersListHeaderWrapper,
  UsersListWrapper,
  UserWrapper,
} from 'modules/Users/UsersList/UsersList.components';
import { ReactComponent as ChevronRight } from 'modules/Home/CalendarSection/icons/chevronWhiteRight.svg';
import { getUserAvatar } from 'modules/Users/UsersList/getUserAvatar';

type Props = {
  selectedUserId: string;
  setSelectedUserId: Dispatch<SetStateAction<string>>;
};

const UsersList: FunctionComponent<Props> = ({ selectedUserId, setSelectedUserId }) => {
  const { users } = useUserContext();

  return (
    <UsersListContainer>
      <UsersListHeaderWrapper>Stado jelonków 🦌</UsersListHeaderWrapper>
      <UsersListWrapper>
        {users
          ?.filter(u => !u.isRemoved)
          .map(u => (
            <UserWrapper key={u.uid} isSelected={u.uid === selectedUserId} onClick={() => setSelectedUserId(u.uid!)}>
              <UserAvatar src={getUserAvatar(u.email)} />
              {u.displayName}
              <SelectedUserButton>
                <ChevronRight />
              </SelectedUserButton>
            </UserWrapper>
          ))}
      </UsersListWrapper>
    </UsersListContainer>
  );
};

export default UsersList;

import { auth } from 'config/firebase';
import firebase from 'firebase/app';
import { HolidayData } from 'firebase/types/HolidayData';
import { HolidayDays } from 'firebase/types/HolidayDays';
import { Holidays } from 'firebase/types/Holidays';
import { useEffect, useState } from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';

const getHolidaysDocRef = (userId: string, year: number) =>
  firebase.firestore().collection('users').doc(userId).collection('holidays').doc(year.toString());

export const useUserHolidays = (year: number, userId?: string): { holidays: Holidays | undefined; loading: boolean } => {
  const [loading, setLoading] = useState(true);
  const [holidays, setHolidays] = useState<Holidays | undefined>(undefined);
  const userUid = userId ?? auth.currentUser!.uid;
  const [holidaysData, holidaysLoading] = useDocumentData<HolidayData>(getHolidaysDocRef(userUid, year));
  const [daysData, daysLoading] = useCollectionData<HolidayDays>(getHolidaysDocRef(userUid, year).collection('days'));

  useEffect(() => {
    const isLoading = holidaysLoading || daysLoading;
    setLoading(isLoading);
  }, [holidaysLoading, daysLoading]);

  useEffect(() => {
    if (loading) return;

    if (!holidaysData || !daysData) {
      setHolidays(undefined);
    } else {
      const total = holidaysData.total;
      const taken = holidaysData.taken;
      const days = daysData;
      setHolidays({ total, taken, days });
    }
  }, [loading, holidaysData, daysData]);

  return { holidays, loading };
};

import firebase from 'firebase/app';

const editProject = async (projectId: string, name: string, client: string, icon: string) => {
  await firebase.firestore().collection('projects').doc(projectId).update({
    name,
    client,
    icon,
  });
};

export default editProject;

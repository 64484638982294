import styled, { css } from 'styled-components';
import { TransitionStatus } from 'react-transition-group';
import { Form } from 'formik';
import { InputWrapper, StyledInput } from 'modules/shared/components/Form/Input/Input.components';
import ChartDown from 'modules/Projects/icons/chartDown.svg';
import ChartUp from 'modules/Projects/icons/chartUp.svg';
import { DatePicker } from '@material-ui/pickers';
import colors from 'modules/shared/styles/colors';
import { font14, font16 } from 'modules/shared/styles/mixins';

type Props = {
  phaseOpened?: boolean;
  gridDisabled?: boolean;
  hide?: boolean;
};

export const SingleProjectPhaseContainer = styled.div<Props>`
  ${p =>
    p.gridDisabled
      ? css`
          display: flex;
        `
      : css`
          display: grid;
          grid-template-columns: 2fr 1fr 1fr 1fr 0.6fr;
        `}

  align-items: center;
  width: 100%;
  background: ${colors.gray20};
  border-radius: 15px;
  align-items: center;
  height: 72px;
  margin-top: 8px;

  > :nth-child(n + 4) {
    justify-self: center;
  }

  > :last-child {
    justify-self: flex-end;
  }

  transition: height 0.5s ease, border-radius 0.5s ease, background 0.5s ease;
  > span {
    ${font14()}
    color: rgba(255, 255, 255, 0.8);
  }

  ${p =>
    p.phaseOpened &&
    css`
      background: ${colors.gray30};
      border-bottom: 1px solid ${colors.gray10};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
`;

export const FirstColumnWrapper = styled.div`
  display: flex;
  span {
    font-weight: 500;
    ${font14()}
    color: rgba(255, 255, 255, 0.95);
    margin-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;

    @media all and (max-width: 1500px) {
      max-width: 160px;
    }
    @media all and (max-width: 1366px) {
      max-width: 130px;
    }
    @media all and (max-width: 900px) {
      max-width: 110px;
    }
    @media all and (max-width: 700px) {
      max-width: 85px;
    }
  }
`;

export const AccordionButton = styled.button<Props>`
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: 16px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > svg {
    transition: transform 0.8s ease;
  }

  :hover {
    background: ${colors.purple};
  }

  ${p =>
    p.phaseOpened &&
    css`
      background: ${colors.purple};
      > svg {
        transform: rotate(-180deg);
      }
    `}

  ${p =>
    p.hide &&
    css`
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    `}
`;

export const PhaseName = styled.span`
  font-weight: 500;
  ${font14()}
  color: rgba(255, 255, 255, 0.95);
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;

  @media all and (max-width: 1500px) {
    max-width: 160px;
  }
  @media all and (max-width: 1366px) {
    max-width: 130px;
  }
  @media all and (max-width: 900px) {
    max-width: 110px;
  }
  @media all and (max-width: 700px) {
    max-width: 90px;
  }
`;

export const OriginalEstimate = styled.span`
  text-align: center;
`;
export const HoursUsed = styled.span`
  text-align: center;
`;

type HoursAvailableProps = {
  showDanger: boolean;
  hideAlerts?: boolean;
};

export const HoursAvailableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HoursAvailable = styled.span<HoursAvailableProps>`
  position: relative;
  text-align: center;
  width: fit-content;
  ::after {
    position: absolute;
    content: '';
    top: 1px;
    right: -20px;
    width: 12px;
    height: 12px;
    margin-left: 5px;
    margin-top: 4px;
  }
  ${p =>
    p.showDanger
      ? css`
          color: ${colors.red} !important;
          ::after {
            background-image: url(${ChartDown});
          }
        `
      : css`
          color: ${colors.mint} !important;
          ::after {
            background-image: url(${ChartUp});
          }
        `};
  ${p =>
    p.hideAlerts &&
    css`
      color: white !important;
      ::after {
        background-image: none;
      }
    `}
`;

export const EditStageButton = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

type PhaseProps = {
  status: TransitionStatus;
};

export const PhaseDetailsContainer = styled.div<PhaseProps>`
  display: flex;
  width: 100%;
  background: ${colors.gray20};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  opacity: 0;
  /* height: ; */
  transition: height 400ms ease, opacity 100ms ease;
  opacity: ${props => (props.status === 'entered' ? '1' : '0')};
  height: ${props => (props.status === 'entered' ? 'auto' : '0px')};
  display: ${props => props.status !== 'entered' && ' none'};
  color: white;
  align-items: center;
  justify-content: center;
`;

export const AddPhaseForm = styled(Form)`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 2fr 1fr 1fr 1fr 0.6fr;
  align-items: center;

  > * {
    margin-top: auto;
    margin-bottom: auto;
  }
  > :first-child {
    margin-left: 56px;
  }

  > :nth-child(n + 4) {
    width: 100%;
    display: flex;
    justify-content: center;
    > ${InputWrapper} {
      width: 56px;
      display: flex;
      justify-self: center;
      justify-content: center;

      > ${StyledInput} {
        text-align: center;
      }
    }
  }
`;

export const SavePhaseButton = styled.div`
  display: flex;
  width: 24px !important;
  height: 24px;
  background: ${colors.purple};
  border-radius: 5px;
  justify-self: flex-end;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
`;

export const AddSinglePhaseWrapper = styled.div<{ status: TransitionStatus }>`
  transition: height 200ms ease-in, opacity 250ms ease-in, padding-top 150ms ease-in, padding-bottom 150ms ease-in;
  opacity: ${props => (props.status === 'entered' ? '1' : '0')};
  height: ${props => (props.status === 'entered' ? '72px' : '0')};
  padding-top: ${props => (props.status === 'entered' ? '8px' : '0px')};
  padding-bottom: ${props => (props.status === 'entered' ? '8px' : '0px')};
  ${props => props.status === 'exited' && 'pointer-events: none;'}
`;

export const AddPhaseSpan = styled.span`
  ${font14()};
  color: white;
  :not(:first-child) {
    text-align: center;
  }
`;

export const ColumnPlaceholder = styled.div``;

// Details table

export const PhaseDetailsTable = styled.table`
  width: 88%;
  margin-top: 5px;
  margin-bottom: 20px;
  overflow-x: scroll;
  white-space: nowrap;

  @media all and (max-width: 1200px) {
    padding-left: 8px;
  }
`;

export const PhaseDetailsTableRow = styled.tr`
  display: flex;
  align-items: center;
  height: 44px;
  > td,
  th {
    width: 110px;
  }
  border-bottom: 1px dashed ${colors.gray30};

  :last-of-type {
    border-bottom: none;
  }
`;

export const PhaseDetailsTableHeader = styled.th<{ alignLeft?: boolean }>`
  ${font14()}
  font-weight: normal;
  color: rgba(255, 255, 255, 0.6);
  text-align: ${p => (p.alignLeft ? 'left' : 'center')};
  width: 100px;
  text-transform: capitalize;
`;

export const PhaseDetailsTableElement = styled.td`
  text-align: center;
  font-weight: 500;
  ${font16()}
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
  width: 100px;
`;

export const StyledDatepicker = styled(DatePicker)`
  width: 80px;
  > div > input {
    margin-top: -5px;
    font-weight: 500;
    ${font14()}
    color: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid ${colors.gray60};
    width: 80px;

    .underline {
      border: 1px solid red;
    }

    :active {
      border-bottom: 1px solid rgba(255, 255, 255, 0.8);

      ::after {
        border-bottom: none !important;
        display: none;
      }

      ::before {
        display: none;
        border: none;
      }
    }
  }

  > div {
    ::after {
      border-bottom: none !important;
      display: none;
    }

    ::before {
      display: none;
      border: none;
    }
  }
`;

import React, { FunctionComponent, lazy, Suspense, useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import routes from 'modules/Routing/routes';
import PublicRoute from 'modules/Routing/PublicRoute';
import AuthedRoute from 'modules/Routing/AuthedRoute';
import AppContext, { AppContextType } from 'modules/App/AppContext';
import GlobalStyles from 'modules/shared/styles/global';
import { auth } from 'config/firebase';
import ToastContainer from 'modules/shared/components/Toast';

const routeComponents = routes.map((routeConfig, index) => {
  let RouteComponent;

  const component = lazy(routeConfig.importComponent);

  switch (routeConfig.type) {
    case 'authed':
      RouteComponent = AuthedRoute;
      break;
    case 'public':
      RouteComponent = PublicRoute;
      break;
    case 'unique':
      RouteComponent = Route;
      break;
  }

  return <RouteComponent key={index} component={component} {...routeConfig} />;
});

const App: FunctionComponent = () => {
  const [context, setContext] = useState<AppContextType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((authedUser) => {
      if (!authedUser) setContext({ user: authedUser });
      // Set user context only if mail is within @purpledeer.studio
      if (authedUser?.email?.endsWith('@purpledeer.studio')) {
        setContext({ user: authedUser });
      }
      setLoading(false);
    });
  }, []);

  if (loading) return null;
  return (
    <AppContext.Provider value={context!}>
      <DndProvider backend={HTML5Backend}>
        <GlobalStyles />
        <ToastContainer />
        <Suspense fallback={<div />}>
          <Switch>
            {routeComponents}
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </DndProvider>
    </AppContext.Provider>
  );
};

export default App;

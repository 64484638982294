import React, { FunctionComponent } from 'react';
import { Route, RouteProps, Redirect } from 'react-router';
import AuthedLayout from 'modules/App/AuthedLayout';
import routesPaths from 'modules/Routing/routesPaths';
import useAppContext from 'modules/App/useAppContext';

type Props = RouteProps & Required<Pick<RouteProps, 'component'>>;

export const AuthedRoute: FunctionComponent<Props> = ({ component: Component, ...routeProps }) => {
  const user = useAppContext();

  if (!user || (user && !user.user?.emailVerified)) return <Redirect to={{ pathname: routesPaths.LOGIN }} />;

  return (
    <Route
      {...routeProps}
      render={(routeComponentProps) => (
        <AuthedLayout>
          <Component {...routeComponentProps} />
        </AuthedLayout>
      )}
    />
  );
};

export default AuthedRoute;

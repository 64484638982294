import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';

export interface ContainerProps {
  status: TransitionStatus;
  position: 'absolute' | 'fixed';
}

const MODAL_BACKGROUND_Z_INDEX = 5000;
const MODAL_Z_INDEX = 5001;
const SET_INITIAL_POSITION = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

export const MODAL_TRANSITION_TIME_MS = 330;

type ModalContainerProps = {
  wider?: boolean;
};

export const Container = styled.div.attrs({
  'data-cy': 'modal-container',
})<ContainerProps & ModalContainerProps>`
  position: ${(p) => p.position};
  display: flex;
  right: 0;
  top: 0;
  width: 400px;
  height: 100%;
  pointer-events: none;
  z-index: ${MODAL_Z_INDEX};
  pointer-events: all;
  transition: transform ${MODAL_TRANSITION_TIME_MS}ms ease;
  ${(p) => p.wider && `width: 440px;`};

  transform: translateX(${(p) => (p.status === 'entered' ? '0' : p.wider ? '440px' : '400px')});
`;

export const Background = styled.div<{
  status: TransitionStatus;
}>`
  position: fixed;
  ${SET_INITIAL_POSITION};
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: ${MODAL_BACKGROUND_Z_INDEX};
  transition: opacity ${MODAL_TRANSITION_TIME_MS}ms ease-in;
  ${(p) =>
    (p.status === 'entering' || p.status === 'entered') &&
    css`
      opacity: 1;
    `};
`;

import React, { useMemo, useState } from 'react';
import { useUserHolidays } from 'firebase/holidays/useUserHolidays';
import {
  HolidaysCalendarAvailableDays,
  HolidaysCalendarContainer,
  HolidaysCalendarCurrentYear,
  HolidaysCalendarHeader,
  HolidaysCalendarPrevYearButton,
  HolidaysContainer,
} from './Holidays.components';
import UsersList from 'modules/Users/UsersList/UsersList';
import { auth } from 'config/firebase';
import useUserContext from 'modules/App/AuthedLayout/UserContext/useUserContext';
import { format, subYears, addYears } from 'date-fns';
import { ReactComponent as IconArrowLeft } from './icon_arrow_left.svg';
import { ReactComponent as IconArrowRight } from './icon_arrow_right.svg';
import HolidaysCalendar from './components/HolidaysCalendar';

const Holidays = () => {
  const { isAdmin, users, selectedDate, config, setSelectedDate } = useUserContext();
  const [selectedUserId, setSelectedUserId] = useState<string>(auth.currentUser?.uid!);
  const { loading, holidays } = useUserHolidays(selectedDate.getFullYear(), selectedUserId);

  const changeYear = (action: 'prev' | 'next') => {
    if (action === 'prev') {
      setSelectedDate(subYears(selectedDate, 1));
    } else if (action === 'next') {
      setSelectedDate(addYears(selectedDate, 1));
    }
  };

  const userName = useMemo(() => {
    return users?.find(user => user.uid === selectedUserId)?.displayName ?? '';
  }, [selectedUserId, users]);

  return (
    <HolidaysContainer>
      <UsersList selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} />
      <HolidaysCalendarContainer>
        <HolidaysCalendarHeader>
          <HolidaysCalendarPrevYearButton onClick={() => changeYear('prev')}>
            <IconArrowLeft />
          </HolidaysCalendarPrevYearButton>
          <HolidaysCalendarPrevYearButton onClick={() => changeYear('next')}>
            <IconArrowRight />
          </HolidaysCalendarPrevYearButton>
          <HolidaysCalendarCurrentYear>{format(selectedDate, 'yyyy')} Urlop</HolidaysCalendarCurrentYear>
          <HolidaysCalendarAvailableDays>
            {loading ? '' : `${holidays?.taken ?? '0'} / ${holidays?.total ?? config.holidays?.defaultTotal ?? '0'}`}
          </HolidaysCalendarAvailableDays>
        </HolidaysCalendarHeader>
        <HolidaysCalendar
          defaultTotal={config.holidays?.defaultTotal}
          userName={userName}
          isAdmin={isAdmin}
          selectedDate={selectedDate}
          selectedUserId={selectedUserId}
        />
      </HolidaysCalendarContainer>
    </HolidaysContainer>
  );
};

export default Holidays;

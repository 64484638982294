import { IProjectPhase } from 'firebase/types/ProjectPhase';
import React, { FunctionComponent, useEffect, useState } from 'react';
import SingleProjectPhase from '../SingleProjectPhase/SingleProjectPhase';

interface Props {
  allPhases: IProjectPhase[];
  projectId: string;
}

export interface ProjectPhaseWithIndex extends IProjectPhase {
  index: number;
}

const SingleProjectPhasesDragAndDrop: FunctionComponent<Props> = ({ allPhases }) => {
  const [phases, setPhases] = useState<IProjectPhase[]>([]);

  useEffect(() => {
    setPhases(allPhases.sort((a, b) => b.startDate - a.startDate));
  }, [allPhases]);

  return (
    <div>
      {phases.map(phase => (
        <SingleProjectPhase key={phase.phaseId} phase={phase} />
      ))}
    </div>
  );
};

export default SingleProjectPhasesDragAndDrop;
